import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Container,
  Paper,
  Box,
  Alert,
  Grid,
  Avatar,
  Stack,
  IconButton,
  Typography,
  Input,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import {
  FirebaseStorage,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { organizationFormErrors } from "../../../../utils/constants";

import "./index.css";
import { makeStyles } from "@mui/styles";
import { OrganizationForm } from "../OrganizationForm/OrganizationForm";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStorage";

const initialOrgData = {
  name: "",
  description: "",
  accountType: "",
  acceptTerms: false,
  access: [],
  accounts: [],
  admins: [],
  organizationCode: "",
  imageUrl: "",
};

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 130,
    height: 130,
    marginBottom: 24,
  },
  uploadInput: {
    display: "none",
  },
  textField: {
    margin: "12px 0",
  },
}));

const NewOrganization = () => {
  const title = "Create New Organization";
  const classes = useStyles();
  const mountedRef = useRef(true);
  const history = useNavigate();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { authUser } = useContext(AuthContext);
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);

  const [organizationData, setOrganizationData] = useState(initialOrgData);
  const [inSubmit, setInSubmit] = useState(false);
  const [errors, setErrors] = useState();

  const handleChange = useCallback(({ target: { name, value } }) => {
    if (name === "name" && value.length > 100) return;
    if (name === "description" && value.length > 200) return;
    if (name === "organizationCode" && value.length > 4) return;
    setOrganizationData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  // const handleUpload = async (image) => {
  //   try {
  //     const downloadUrl = await FirebaseStorage.ref(
  //       `organizations/${image.name}`
  //     ).put(image);
  //     return downloadUrl.ref.getDownloadURL();
  //   } catch (ex) {
  //     console.log("ex", ex);
  //   }
  // };

  const handleUpload = async (image) => {
    try {
      const storageRef = ref(FirebaseStorage, `organizations/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      await uploadTask;
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      return downloadUrl;
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleChangeImage = useCallback(async ({ target: { files } }) => {
    if (!!files[0]) {
      const uploadUrl = await handleUpload(files[0]);
      setOrganizationData((prevOrg) => ({ ...prevOrg, imageUrl: uploadUrl }));
    }
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleCreate = useCallback(
    async (ev) => {
      ev.preventDefault();
      setInSubmit(true);
      if (!organizationData.name) {
        setErrors(organizationFormErrors.name.message);
        return;
      }
      if (!organizationData.description) {
        setErrors(organizationFormErrors.description.message);
        return;
      }
      if (!organizationData.accountType) {
        setErrors(organizationFormErrors.accountType.message);
        return;
      }
      if (!organizationData.organizationCode) {
        setErrors(organizationFormErrors.organizationCode.message);
        return;
      }
      try {
        // const organizationRef = Firestore.collection("organizations").doc();
        // await organizationRef.set({
        //   ...organizationData,
        //   userId: authUser.user.uid,
        //   admins: [authUser.user.uid],
        //   access: [authUser.user.uid],
        // });

        // const refUser = Firestore.collection("users").doc(authUser.user.uid);
        // const userData = await refUser.get();
        // await refUser.update({
        //   role: [
        //     ...new Set([...(userData.data().role || []), "admin", "orgAdmin"]),
        //   ],
        // });
        const organizationRef = doc(Firestore, "organizations",authUser.user.uid);
        await setDoc(organizationRef, {
          ...organizationData,
          userId: authUser.user.uid,
          admins: [authUser.user.uid],
          access: [authUser.user.uid],
        });

        // Get the user document
        const refUser = doc(Firestore, "users", authUser.user.uid);
        const userDataSnapshot = await getDoc(refUser);
        if (userDataSnapshot.exists()) {
          const userData = userDataSnapshot.data();
          // Update the user's roles
          await updateDoc(refUser, {
            role: [
              ...new Set([...(userData.role || []), "admin", "orgAdmin"]),
            ],
          });
        }
        setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, {
          ...organizationData,
          id: organizationRef.id,
          userId: authUser.user.uid,
          admins: [authUser.user.uid],
          access: [authUser.user.uid],
        });
        setInSubmit(false);
        history("/");
        return;
      } catch (ex) {
        setInSubmit(false);
        console.log("____ ex", ex);
      }
    },
    [organizationData, history, authUser.user.uid]
  );

  return (
    <Container>
      <Paper>
        <Grid container>
          <Grid item xs={12} md={3} className={classes.imageContainer}>
            <Avatar
              alt={organizationData.imageUrl}
              src={organizationData.imageUrl}
              className={classes.image}
            />
            <Stack>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  name="image"
                  type="file"
                  onChange={handleChangeImage}
                  className={classes.uploadInput}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon />
                  <Box>
                    <Typography ml={1}>Update Image</Typography>
                  </Box>
                </IconButton>
              </label>
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box p={2}>
              {errors && (
                <Alert
                  severity="error"
                  dismissible={true}
                  onDismiss={() => setErrors(null)}
                >
                  {errors}
                </Alert>
              )}
              <div className="card-body">
                <OrganizationForm
                  handleSubmit={handleCreate}
                  organizationData={organizationData}
                  inSubmit={inSubmit}
                  handleChange={handleChange}
                  userData={currentUser}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default NewOrganization;
