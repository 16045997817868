import React, { useCallback, useEffect, useState } from "react";
import { FormControl, Paper, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Firestore } from "../../FirebaseAuth/firebase";
import { roles } from "../../../utils/constants";
import { collection, getDocs } from "firebase/firestore";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        padding: 24,
        marginBottom: 16
    },
    textField: {
        margin: "12px 0"
    }
}))

const ApplyForm = ({ plan, setPlan }) => {
    const classes = useStyles();
    const [customerIds, setCustomerIds] = useState([]);
    const [organizationIds, setOrganizationIds] = useState([]);

    // const getCustomerIds = useCallback(async () => {
    //     const snapShots = await FirebaseAuth.firestore().collection("accounts").get();
    //     const ids = [];
    //     snapShots.forEach((doc) => {
    //         ids.push(doc.id);
    //     });
    //     setCustomerIds(ids);
    // }, []);

    // const getOrganizationIds = useCallback(async () => {
    //     const snapShots = await FirebaseAuth.firestore().collection("organizations").get();
    //     const ids = [];
    //     snapShots.forEach((doc) => {
    //         ids.push(doc.id);
    //     });
    //     setOrganizationIds(ids);
    // }, []);

    const getCustomerIds = useCallback(async () => {
        try {
          const snapShots = await getDocs(collection(Firestore, "accounts")); // Fetch documents from "accounts" collection
          const ids = snapShots.docs.map((doc) => doc.id); // Extract document IDs
          setCustomerIds(ids);
        } catch (error) {
          console.error("Error fetching customer IDs:", error);
        }
      }, []);
      
      const getOrganizationIds = useCallback(async () => {
        try {
          const snapShots = await getDocs(collection(Firestore, "organizations")); // Fetch documents from "organizations" collection
          const ids = snapShots.docs.map((doc) => doc.id); // Extract document IDs
          setOrganizationIds(ids);
        } catch (error) {
          console.error("Error fetching organization IDs:", error);
        }
      }, []);

    const handleChange = useCallback(({ target: { name, value } }) => {
        if (!value) return;
        setPlan({ ...plan, [name]: value })
    }, [plan, setPlan]);

    useEffect(() => {
        getCustomerIds();
        getOrganizationIds();
    }, [getCustomerIds, getOrganizationIds, plan]);

    return (
        <Paper className={classes.container}>
            {customerIds.length > 0 && <form>
                <Typography variant='h6' marginBottom={2}>Access Control</Typography>
                <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="customerID">Customer ID</InputLabel>
                    <Select
                        labelId="customerID"
                        name="customerID"
                        multiple
                        onChange={handleChange}
                        value={plan.customerID ? plan.customerID : []}
                        label="Customer ID">
                        {customerIds.map(id =>
                            (<MenuItem key={id} value={id}>{id}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="excludeCustomerID">Exclude Customer ID</InputLabel>
                    <Select
                        labelId="excludeCustomerID"
                        name="excludeCustomerID"
                        onChange={handleChange}
                        value={plan.excludeCustomerID ? plan.excludeCustomerID : []}
                        multiple
                        label="Exclude Customer ID">
                        {customerIds.map(id =>
                            (<MenuItem key={id} value={id}>{id}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="organizationID">Organization ID</InputLabel>
                    <Select
                        labelId="organizationID"
                        name="organizationID"
                        multiple
                        onChange={handleChange}
                        value={plan.organizationID ? plan.organizationID : []}
                        label="Organization ID">
                        {organizationIds.map(id =>
                            (<MenuItem key={id} value={id}>{id}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="excludeOrganizationID">Exclude Organization ID</InputLabel>
                    <Select
                        labelId="excludeOrganizationID"
                        name="excludeOrganizationID"
                        onChange={handleChange}
                        value={plan.excludeOrganizationID ? plan.excludeOrganizationID : []}
                        multiple
                        label="Exclude Organization ID">
                        {organizationIds.map(id =>
                            (<MenuItem key={id} value={id}>{id}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                        labelId="role"
                        name="role"
                        multiple
                        onChange={handleChange}
                        value={plan.role ? plan.role : []}
                        label="Role">
                        {roles.map(role =>
                            (<MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>))}
                    </Select>
                </FormControl>
            </form>}
        </Paper>
    );
}

export default ApplyForm;
