import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Alert,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  IconButton,
  Typography,
  Box,
  Avatar,
  OutlinedInput,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import DataCreate from "../../../components/DataCreate";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
// import { formSchema } from "./template.json";
import { formSchema } from "./template.constants";
import {
  Firestore,
  FirebaseStorage,
} from "../../../components/FirebaseAuth/firebase";
import { defaultTemplateUrl } from "../../../utils/index";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { accountTypesArr } from "../../../utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName === name
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Input = styled("input")({
  display: "none",
  width: "100%",
});

const TemplateCreate = () => {
  const title = "Create Template";
  const siteTypesArr = ["Drupal", "Wordpress"];

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [formFocused, setFormFocused] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [templateIdError, setTemplateIdError] = useState(null);
  const [image, setImage] = useState("");
  const [URL, setURL] = useState("");
  const [accountType, setAccountType] = useState([]);
  const [organizationID, setOrganizationID] = useState([]);
  const [organizationIds, setOrganizationIds] = useState([]);
  const [excludeOrganizationID, setExcludeOrganizationID] = useState([]);
  const [customerID, setCustomerID] = useState([]);
  const [excludeCustomerID, setExcludeCustomerID] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);
  const theme = useTheme();

  const validate = () => {
    return formFocused && !descriptionError && !nameError && !templateIdError;
  };

  // const getOrganizationIds = useCallback(async () => {
  //   const snapShots = await Firestore.collection("organizations").get();
  //   const ids = [];
  //   snapShots.forEach((doc) => {
  //     ids.push(doc.id);
  //   });
  //   setOrganizationIds(ids);
  // }, []);
  const getOrganizationIds = useCallback(async () => {
    const organizationsRef = collection(Firestore, "organizations");
    const snapShots = await getDocs(organizationsRef);
    const ids = [];
    snapShots.forEach((doc) => {
      ids.push(doc.id);
    });
    setOrganizationIds(ids);
  }, []);

  // const getCustomerIds = useCallback(async () => {
  //   const snapShots = await Firestore.collection("accounts").get();
  //   const ids = [];
  //   snapShots.forEach((doc) => {
  //     ids.push(doc.id);
  //   });
  //   setCustomerIds(ids);
  // }, []);
  const getCustomerIds = useCallback(async () => {
    const accountsRef = collection(Firestore, "accounts");
    const snapShots = await getDocs(accountsRef);
    const ids = [];
    snapShots.forEach((doc) => {
      ids.push(doc.id);
    });
    setCustomerIds(ids);
  }, []);

  const handleChangeAccount = (event) => {
    setAccountType(event.target.value);
  };

  // const handleUpload = useCallback(async (image) => {
  //   try {
  //     const downloadUrl = await FirebaseStorage.ref(
  //       `templates/${image.name}`
  //     ).put(image);
  //     return downloadUrl.ref.getDownloadURL();
  //   } catch (ex) {
  //     console.log("ex", ex);
  //   }
  // }, []);

  const handleUpload = useCallback(async (image) => {
    try {
      const storageRef = ref(getStorage(),`templates/${image.name}`);
      await uploadBytes(storageRef, image);
      const downloadUrl = await getDownloadURL(storageRef);
      return downloadUrl;
    } catch (ex) {
      console.log("ex", ex);
    }
  }, []);

  const handleChangeImage = useCallback(
    async (event) => {
      event.preventDefault();
      if (!!event.target.files[0]) {
        const uploadUrl = await handleUpload(event.target.files[0]);
        setImage(uploadUrl);
      }
    },
    [handleUpload]
  );

  const handleSubmit = async (data) => {
    try {
      let uploadedUrl = defaultTemplateUrl;
      if (!!image.file) {
        uploadedUrl = await handleUpload(image.file);
      }
      const finalObj = {
        ...data,
        URL,
        siteCloneId: data.siteId,
        image: uploadedUrl,
        accountType,
        templateType: "Default Template",
        createdAt: Date.now(),
        isGlobalTemplate: true,
        customerID,
        excludeCustomerID,
        organizationID,
        excludeOrganizationID,
      };
      // let tempCollectionRef = Firestore.collection("templates").doc();
      // await tempCollectionRef.set({
      //   ...finalObj,
      // });
      try {
        const templatesRef = collection(Firestore, "templates");
        const tempCollectionRef = doc(templatesRef);  // Creates a new document reference
        await setDoc(tempCollectionRef, { ...finalObj });
      } catch (ex) {
        console.log("Error adding template:", ex);
      }
      return true;
    } catch (ex) {
      return false;
    }
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/global-templates-list",
        text: "Templates",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    getCustomerIds();
    getOrganizationIds();
  }, [getCustomerIds, getOrganizationIds]);

  return (
    <DataCreate
      schema={formSchema}
      validation={validate}
      success={<Alert severity="success">Saved Data Successfully!</Alert>}
      handleCreation={handleSubmit}
    >
      <TextField
        label="Template Name"
        name="name"
        fullWidth
        onFocus={() => setFormFocused(true)}
        onBlur={(e) => {
          if (
            e.target.value.trim().length < 1 ||
            e.target.value.trim().length > 100
          ) {
            setNameError("Template Name must be between 1 to 100 characters.");
          } else {
            setNameError(null);
          }
        }}
        error={nameError ? true : false}
        helperText={nameError}
      />
      <TextField
        label="Template Description"
        name="description"
        fullWidth
        onFocus={() => setFormFocused(true)}
        onBlur={(e) => {
          if (
            e.target.value.trim().length < 1 ||
            e.target.value.trim().length > 100
          ) {
            setDescriptionError(
              "Template Description must be between 1 to 100 characters."
            );
          } else {
            setDescriptionError(null);
          }
        }}
        error={descriptionError ? true : false}
        helperText={descriptionError}
      />
      <TextField
        label="Site ID"
        name="siteId"
        fullWidth
        onFocus={() => setFormFocused(true)}
      />
      <TextField
        label="Template ID"
        name="templateId"
        fullWidth
        onFocus={() => setFormFocused(true)}
        onBlur={(e) => {
          if (
            e.target.value.trim().length < 1 ||
            e.target.value.trim().length > 50
          ) {
            setTemplateIdError(
              "Template ID must be between 1 to 50 characters."
            );
          } else {
            setTemplateIdError(null);
          }
        }}
        error={templateIdError ? true : false}
        helperText={templateIdError}
      />
      <TextField
        label="URL"
        name="URL"
        fullWidth
        onFocus={() => setFormFocused(true)}
        onChange={(e) => setURL(e.target.value)}
      />
      <div>
        <FormControl fullWidth>
          <InputLabel id="accountType">Account Type</InputLabel>
          <Select
            labelId="accountType"
            id="accountType"
            fullWidth
            value={accountType}
            onChange={handleChangeAccount}
            input={<OutlinedInput label="Account Type" />}
            MenuProps={MenuProps}
            multiple
          >
            {accountTypesArr.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, accountType, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControl fullWidth>
        <InputLabel id="siteType">Site Type</InputLabel>
        <Select
          labelId="siteType"
          name="siteType"
          type="text"
          label="Site Type"
        >
          {siteTypesArr.map((siteType) => (
            <MenuItem value={siteType} key={siteType}>
              {siteType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="customerID">Customer ID</InputLabel>
        <Select
          labelId="customerID"
          name="customerID"
          multiple
          onChange={(e) => setCustomerID(e.target.value)}
          value={customerID}
          label="Customer ID"
        >
          {customerIds.map((id) => (
            <MenuItem key={id} value={id}>
              {id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="excludeCustomerID">Exclude Customer ID</InputLabel>
        <Select
          labelId="excludeCustomerID"
          name="excludeCustomerID"
          onChange={(e) => setExcludeCustomerID(e.target.value)}
          value={excludeCustomerID}
          multiple
          label="Exclude Customer ID"
        >
          {customerIds.map((id) => (
            <MenuItem key={id} value={id}>
              {id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="organizationID">Organization ID</InputLabel>
        <Select
          labelId="organizationID"
          name="organizationID"
          multiple
          onChange={(e) => setOrganizationID(e.target.value)}
          value={organizationID}
          label="Organization ID"
        >
          {organizationIds.map((id) => (
            <MenuItem key={id} value={id}>
              {id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="excludeOrganizationID">
          Exclude Organization ID
        </InputLabel>
        <Select
          labelId="excludeOrganizationID"
          name="excludeOrganizationID"
          onChange={(e) => setExcludeOrganizationID(e.target.value)}
          value={excludeOrganizationID}
          multiple
          label="Exclude refOrganization ID"
        >
          {organizationIds.map((id) => (
            <MenuItem key={id} value={id}>
              {id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar alt="Remy Sharp" src={image || ""} />
        <Stack direction="row" alignItems="center">
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              name="image"
              type="file"
              onChange={handleChangeImage}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloudUploadIcon />{" "}
              <Box>
                <Typography ml={1}>Upload Image</Typography>
              </Box>
            </IconButton>
          </label>
        </Stack>
      </Box>
    </DataCreate>
  );
};

export default TemplateCreate;
