import React, { useCallback, useEffect, useState } from "react";
import { FirebaseAuth, Firestore } from "./firebase";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../utils/localStorage";
import { collection, doc, getDocs, query, orderBy, getDoc, setDoc, updateDoc, where } from "firebase/firestore";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({
    user: null,
    checked: false,
  });

  const [userData, setUserData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMyTemplate, setIsMyTemplate] = useState({
    isMenuOpen: false,
    status: false,
  });

  const initializeUserData = useCallback(async (user) => {
    const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
    const userDocRef = doc(Firestore, "users", user.uid);
    const userRef = await getDoc(userDocRef);

    // Fetch organizations the user has access to
    const organizationRef = query(
      collection(Firestore, "organizations"),
      where("access", "array-contains", user.uid)
    );
    const organizationSnapshot = await getDocs(organizationRef);

    const organizations = organizationSnapshot.docs.map((org) => ({
      ...org.data(),
      id: org.id,
    }));
  
    // Store user data in local storage
    setLocalStorage(LOCAL_STORAGE_CURRENT_USER, userRef.data());
  
    // Store the first organization in local storage if none is selected
    if (organizations.length > 0 && !currentOrganization) {
      setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organizations[0]);
    }
  
    // Set user data
    setUserData(userRef.data() || {});
  }, []);

  useEffect(() => {
    FirebaseAuth.onAuthStateChanged((user) => {
      setAuthUser({
        user: user,
        checked: true,
      });
      if (user) {
        initializeUserData(user);
      }
    });
  }, [initializeUserData]);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        isMyTemplate,
        userData,
        isDrawerOpen,
        setUserData,
        setIsMyTemplate,
        setIsDrawerOpen,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
