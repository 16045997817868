import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Box,
  Select,
  TextField,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { ServiceCard } from "../../../../../components/Service/ServiceCard";
import { serviceFilterTypes } from "../../../../../utils/constants";
import { Firestore } from "../../../../../components/FirebaseAuth/firebase";
import { ServiceCardMobile } from "../../../../../components/Service/ServiceCardMobile";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../../../utils/localStorage";
import ServiceCardGrid from "../../../../../components/Service/ServiceCardGrid";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 24,
  },
  header: {
    margin: "8px 0",
  },
  buttonGroup: {
    display: "flex",
    margin: "10px 0",
    justifyContent: "flex-end",
  },
  button: {
    marginRight: "16px",
  },
  buttonIcon: {
    marginRight: "5px",
  },
  tabContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "12px 0 24px",
  },
}));

export const SiteMarketPlace = () => {
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const classes = useStyles();
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [services, setServices] = useState([]);
  const [searchedServices, setSearchedServices] = useState([]);
  const [search, setSearch] = useState("");
  const [displayGrid, setDisplayGrid] = useState(false);
  const [selectedTab, setSelectedTab] = useState("experts");

  // const getServiceData = useCallback(async () => {
  //   const serviceQuery = FirebaseAuth.firestore()
  //     .collection("services")
  //     .orderBy("type", "asc");
  //   const serviceSnapshot = await serviceQuery.get();
  //   let tempServices = [];
  //   serviceSnapshot.forEach((doc) => {
  //     if (!doc.data().showAccounts && !doc.data().showOrganizations) {
  //       tempServices.push({ ...doc.data(), id: doc.id });
  //       return;
  //     }
  //     if (
  //       ((doc.data().showAccounts.length === 0 &&
  //         doc.data().showOrganizations.length === 0) ||
  //         doc.data().showAccounts.includes(currentAccount?.id) ||
  //         doc.data().showOrganizations.includes(currentOrganization?.id)) &&
  //       !doc.data().excludeCustomerID.includes(currentAccount?.id) &&
  //       !doc.data().excludeOrganizationID.includes(currentOrganization?.id)
  //     ) {
  //       tempServices.push({ ...doc.data(), id: doc.id });
  //     }
  //   });
  //   setServices(tempServices);
  //   setSearchedServices(tempServices);
  // }, [currentAccount?.id, currentOrganization?.id]);
  const getServiceData = useCallback(async () => {
    const serviceQuery = query(
      collection(Firestore, "services"),
      orderBy("type", "asc")
    );
  
    const serviceSnapshot = await getDocs(serviceQuery);
    let tempServices = [];
  
    serviceSnapshot.forEach((doc) => {
      const data = doc.data();
      if (!data.showAccounts && !data.showOrganizations) {
        tempServices.push({ ...data, id: doc.id });
        return;
      }
  
      if (
        ((data.showAccounts.length === 0 && data.showOrganizations.length === 0) ||
          data.showAccounts.includes(currentAccount?.id) ||
          data.showOrganizations.includes(currentOrganization?.id)) &&
        !data.excludeCustomerID.includes(currentAccount?.id) &&
        !data.excludeOrganizationID.includes(currentOrganization?.id)
      ) {
        tempServices.push({ ...data, id: doc.id });
      }
    });
  
    setServices(tempServices);
    setSearchedServices(tempServices);
  }, [currentAccount?.id, currentOrganization?.id]);

  useEffect(() => {
    getServiceData();
  }, [getServiceData]);

  useEffect(() => {
    let tempServices = services;
    if (services.length === 0) return;
    if (search) {
      tempServices = tempServices.filter((service) =>
        service.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedServiceType && selectedServiceType !== "All Services") {
      tempServices = tempServices.filter(
        (service) => service.type === selectedServiceType
      );
    }

    tempServices = tempServices.filter(
      (service) =>
        (selectedTab === "experts" && service.isExpert) ||
        (selectedTab !== "experts" && !service.isExpert)
    );
    setSearchedServices(tempServices);
  }, [search, services, selectedServiceType, selectedTab]);

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.header}>
        Marketplace
      </Typography>

      <Box className={classes.tabContainer}>
        <Tabs
          onChange={(event, value) => setSelectedTab(value)}
          value={selectedTab}
        >
          <Tab label="Experts" value="experts" />
          <Tab label="Extensions" value="extensions" />
        </Tabs>
      </Box>

      <Grid
        direction="row"
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={6} xs={12}>
          <Grid
            className={classes.gridContainer}
            container
            item
            spacing={2}
            md={12}
          >
            <Grid item md={6} xs={12}>
              <TextField
                label="Search"
                name="search"
                fullWidth
                style={{ backgroundColor: "#ffffff" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="serviceType">Service Type</InputLabel>
                <Select
                  labelId="serviceType"
                  name="serviceType"
                  fullWidth
                  value={selectedServiceType}
                  onChange={(e) => setSelectedServiceType(e.target.value)}
                  input={<OutlinedInput label="Service Type" />}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  {serviceFilterTypes.map((serviceType) => (
                    <MenuItem key={serviceType.value} value={serviceType.label}>
                      {serviceType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} justifyContent="space-end">
          <div className={classes.buttonGroup}>
            <Button
              variant={displayGrid ? "outlined" : "contained"}
              className={classes.button}
              onClick={() => {
                setDisplayGrid(false);
              }}
            >
              <i className={`${classes.buttonIcon} fa fa-th-large`}></i>
              Grid View
            </Button>
            <Button
              variant={displayGrid ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => {
                setDisplayGrid(true);
              }}
            >
              <i className={`${classes.buttonIcon} fa fa-list-ul`}></i>
              List View
            </Button>
          </div>
        </Grid>
      </Grid>

      {!displayGrid ? (
        <Grid container spacing={2} mt={1}>
          {searchedServices.map((service) => {
            return <ServiceCardGrid service={service} key={service.id} />;
          })}
        </Grid>
      ) : (
        <Grid container spacing={2} mt={1}>
          {searchedServices.map((service) => {
            return (
              <Grid item xs={12} lg={6} key={service.id}>
                <Hidden smUp>
                  <ServiceCardMobile service={service} />
                </Hidden>
                <Hidden smDown>
                  <ServiceCard service={service} />
                </Hidden>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};
