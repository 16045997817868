import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { uniqBy } from "lodash";
import { useParams } from "react-router-dom";
import { memo, useCallback, useMemo, useState } from "react";
import { UpdateExtensionModal } from "./UpdateExtensionModal";
import { httpsCallable } from "firebase/functions";
import { CloudFunctions } from "../../../../../components/FirebaseAuth/firebase";

const useStyles = makeStyles((theme) => ({
  extensionContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    padding: "16px",
    marginTop: "36px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  itemContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "80px",
  },
  titleText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#6A6A6A",
    width: "72px",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const DiscoveredExtensions = memo(({ siteData, fetchSiteData }) => {
  const classes = useStyles();
  const params = useParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const handleEditExtensions = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const handleRecheck = useCallback(async () => {
    setLoading(true);
    const updateWebCheckResult = httpsCallable(
      CloudFunctions,
      "kwallCloud-updateWebCheckResult"
    );
    await updateWebCheckResult({
      accountId: params.accountId,
      siteId: params.siteId,
      env: "dev",
    });
    setLoading(false);
  }, [params.accountId, params.siteId]);

  const handleUpdateExtensions = useCallback(
    async (updatedData) => {
      setFetchLoading(true);
      const data = {
        extensions: updatedData,
        accountId: params.accountId,
        siteId: params.siteId,
      };
      const updateExtensions = httpsCallable(
        CloudFunctions,
        "kwallCloud-updateSiteExtensions"
      );

      await updateExtensions(data);
      await fetchSiteData();
      setFetchLoading(false);
    },
    [fetchSiteData, params.accountId, params.siteId]
  );

  const extensions = useMemo(() => {
    const prevExtensions = siteData.prevExtensions || [];
    const stackPacks =
      siteData?.["web_check_result_dev"]?.stackPacks ||
      siteData?.["web_check_result_external"]?.stackPacks ||
      [];
    const entities =
      siteData?.["web_check_result_dev"]?.entities ||
      siteData?.["web_check_result_external"]?.entities ||
      [];
    const convertedExtensions =
      prevExtensions.length > 0 ? prevExtensions : [...stackPacks, ...entities];
    return uniqBy(convertedExtensions, "title");
  }, [siteData]);

  return (
    <Card className={classes.extensionContainer}>
      <Typography>Discovered Extensions</Typography>
      <Box className={classes.itemContainer}>
        {fetchLoading ? (
          <CircularProgress
            variant="indeterminate"
            sx={{ color: "#9F9F9F" }}
            size={24}
          />
        ) : (
          extensions.map((extension) => (
            <Box className={classes.itemWrapper}>
              <img
                src={extension.image}
                alt={extension.title}
                width="24px"
                height="24px"
              />
              <Typography className={classes.titleText}>
                {extension.title}
              </Typography>
            </Box>
          ))
        )}
      </Box>
      <Box className={classes.buttonContainer}>
        <Button variant="outlined" onClick={handleEditExtensions}>
          Edit
        </Button>
        {loading ? (
          <CircularProgress
            variant="indeterminate"
            sx={{ color: "#9F9F9F" }}
            size={14}
          />
        ) : (
          <Button variant="contained" onClick={handleRecheck}>
            Recheck Extensions
          </Button>
        )}
      </Box>

      <UpdateExtensionModal
        prevExtensions={extensions}
        showModal={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleUpdate={handleUpdateExtensions}
      />
    </Card>
  );
});
