import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Alert,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  IconButton,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useParams } from "react-router";

import DataEdit from "../../../../components/DataEdit";
import Loader from "../../../../components/Loader";
import {
  Firestore,
  FirebaseStorage,
} from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
// import { formSchema } from "./template.json";
import { formSchema } from "./template.constants";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { accountTypesArr } from "../../../../utils/constants";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Input = styled("input")({
  display: "none",
  width: "100%",
});

const TemplateEdit = () => {
  const mountedRef = useRef(true);
  const theme = useTheme();
  const listName = "templates";
  const title = "Edit Template";
  const siteTypesArr = ["Drupal", "Wordpress"];
  const initialImageState = {
    file: null,
    url: null,
  };

  const { tempId } = useParams();
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(initialImageState);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [templateIdError, setTemplateIdError] = useState(null);
  const [isNoted, setIsNoted] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState([]);

  const validate = () => {
    return !nameError && !descriptionError && !templateIdError;
  };

  const titleCase = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  async function fetchSingleTemplate() {
    // const siteRef = Firestore.collection("templates").doc(tempId);
    // const data = await (await siteRef.get()).data();
    const siteRef = doc(Firestore, "templates", tempId);  // Create a reference to the specific document
    const docSnapshot = await getDoc(siteRef); // Fetch the document
    const data = docSnapshot.exists() ? docSnapshot.data() : null;  // Get data from the document

    if (!mountedRef.current) return null;
    const { image: url, isNoted, accountType } = data;
    setData(data);
    setImage({
      ...image,
      url,
    });
    setIsNoted(isNoted);
    setIsLoading(false);
    setSelectedAccountType(accountType);
  }
  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/templates`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/${listName}`,
        text: titleCase(listName),
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    setIsLoading(true);
  }, [
    setBreadcrumb,
    title,
    listName,
    currentAccount.id,
    currentAccount.name,
    tempId,
  ]);

  useEffect(() => {
    fetchSingleTemplate();

    return () => {
      mountedRef.current = false;
    };
    //eslint-disable-next-line
  }, []);

  const handleEditSite = async (id, data) => {
    try {
      let finalObj = {
        ...data,
        siteCloneId: data.siteId,
        isNoted,
        accountType: selectedAccountType,
        createdAt: Date.now(),
      };
      if (!!image.file) {
        const uploadedUrl = await handleUpload(image.file);
        if (!!uploadedUrl) {
          finalObj = { ...finalObj, image: uploadedUrl };
          // const updateRef = Firestore.collection("templates").doc(id);
          // await updateRef.set({ ...finalObj });
          const updateRef = doc(Firestore, "templates", id);  // Get a reference to the document to update
          await setDoc(updateRef, { ...finalObj });  // Set the new document data
          return;
        }
      }
      finalObj = { ...finalObj, image: image.url };
      // const updateRef = Firestore.collection("templates").doc(id);
      // await updateRef.set({ ...finalObj });
      const updateRef = doc(Firestore, "templates", id);  // Get a reference to the document to update
      await setDoc(updateRef, { ...finalObj });  // Set the new document data
      return true;
    } catch (ex) {
      console.log("___ ex", ex);
    }
  };

  const handleChange = ({ target: { name, value, files } }) => {
    if (name === "accountType") {
      let tempAccountType = [...selectedAccountType];
      tempAccountType.splice(0, 1, value);
      setSelectedAccountType(tempAccountType);
      return;
    }

    if (name === "image") {
      if (!!files[0]) {
        setImage({
          ...image,
          file: files[0],
          url: URL.createObjectURL(files[0]).toString(),
        });
      }
      return;
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  // const handleUpload = async (image, storagePath) => {
  //   try {
  //     const downloadUrl = await FirebaseStorage.ref(
  //       `${storagePath}/${image.name}`
  //     ).put(image);
  //     console.log("Uploaded", downloadUrl.ref.getDownloadURL());
  //     return downloadUrl.ref.getDownloadURL();
  //   } catch (ex) {
  //     console.log("ex", ex);
  //   }
  // };
  const handleUpload = async (image, storagePath) => {
    try {
      const storageRef = ref(FirebaseStorage, `${storagePath}/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      await uploadTask;
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      return downloadUrl;
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  return (
    <>
      {isLoading ? (
        <Paper>
          <Box p={2}>
            <Loader text="Loading..." />
          </Box>
        </Paper>
      ) : (
        <DataEdit
          id={tempId}
          schema={formSchema}
          validation={validate}
          success={<Alert severity="success">Updated Successfully !</Alert>}
          handleEdit={handleEditSite}
        >
          <TextField
            label="Template Name"
            name="name"
            defaultValue={data.name}
            fullWidth
            onBlur={(e) => {
              if (
                e.target.value.trim().length < 1 ||
                e.target.value.trim().length > 100
              ) {
                setNameError(
                  "Template Name must be between 1 to 100 characters."
                );
              } else {
                setNameError(null);
              }
            }}
            error={nameError ? true : false}
            helperText={nameError}
          />
          <TextField
            label="Template Description"
            name="description"
            defaultValue={data.description}
            fullWidth
            onBlur={(e) => {
              if (
                e.target.value.trim().length < 1 ||
                e.target.value.trim().length > 100
              ) {
                setDescriptionError(
                  "Template Description must be between 1 to 100 characters."
                );
              } else {
                setDescriptionError(null);
              }
            }}
            error={descriptionError ? true : false}
            helperText={descriptionError}
          />
          <TextField
            label="Site ID"
            name="siteId"
            defaultValue={data.siteId}
            fullWidth
          />
          <TextField
            label="Template ID"
            name="templateId"
            defaultValue={data.templateId}
            fullWidth
            onBlur={(e) => {
              if (
                e.target.value.trim().length < 1 ||
                e.target.value.trim().length > 50
              ) {
                setTemplateIdError(
                  "Template ID must be between 1 to 50 characters."
                );
              } else {
                setTemplateIdError(null);
              }
            }}
            error={templateIdError ? true : false}
            helperText={templateIdError}
          />
          <div>
            <FormControl fullWidth>
              <InputLabel id="accountType">Account Type</InputLabel>
              <Select
                labelId="accountType"
                id="accountType"
                name="accountType"
                fullWidth
                value={selectedAccountType}
                onChange={handleChange}
                input={<OutlinedInput label="Account Type" />}
                MenuProps={MenuProps}
              >
                {accountTypesArr.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, selectedAccountType, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl fullWidth>
            <InputLabel id="siteType">Site Type</InputLabel>
            <Select
              labelId="siteType"
              name="siteType"
              onChange={handleChange}
              value={data.siteType}
              label="Site Type"
            >
              {siteTypesArr.map((siteType) => (
                <MenuItem key={siteType} value={siteType}>
                  {siteType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNoted}
                  onClick={() => setIsNoted((prevNoted) => !prevNoted)}
                  name="isNoted"
                />
              }
              label="Choose Payment of Template"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              src={!!data.image && image.file === null ? data.image : image.url}
              alt="Remy Sharp"
            />
            <Stack direction="row" alignItems="center">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  name="image"
                  type="file"
                  onChange={handleChange}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon />{" "}
                  <Box>
                    <Typography ml={1}>Upload Image</Typography>
                  </Box>
                </IconButton>
              </label>
            </Stack>
          </Box>
        </DataEdit>
      )}
    </>
  );
};

export default TemplateEdit;
