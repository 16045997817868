import React, { useEffect, useContext, useState, useCallback } from "react";
import { Alert, Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";

import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import SiteDetailsCard from "../../../../components/SiteDetailsCard";
import SubscriptionDetailsCard from "../../../../components/SubscriptionDetailsCard";
import Loader from "../../../../components/Loader";
import {
  CloudFunctions,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { SiteMarketPlace } from "./siteComponents/SiteMarketPlace";
import { SiteEnvironmentCards } from "./siteComponents/SiteEnvironmentCards";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { HelpCenter } from "./siteComponents/HelpCenter";
import { DiscoveredExtensions } from "./siteComponents/DiscoveredExtensions";
import ExternalSiteCard from "../../../../components/ExternalSiteCard";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Roboto",
    color: "#1E1E1E",
    fontSize: 22,
    fontWeight: 400,
  },
  headingSm: {
    fontFamily: "Roboto",
    color: "#1E1E1E",
    fontSize: 18,
    fontWeight: 400,
  },
  helpContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    padding: "16px",
    marginTop: "36px",
  },
}));

const SiteDetails = () => {
  const title = "Site Details";
  const params = useParams();
  const history = useNavigate();
  const classes = useStyles();

  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [siteData, setSiteData] = useState();
  const [planData, setPlanData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [progressAction, setProgressAction] = useState();

  const fetchSiteData = useCallback(async () => {
    if (params.siteId) {
      // const siteRef = await Firestore.collection(
      //   `/accounts/${params.accountId}/sites`
      // )
      //   .doc(params.siteId)
      //   .get();
      // const tempSiteData = siteRef?.data();
      const siteRef = doc(
        Firestore,
        `/accounts/${params.accountId}/sites`,
        params.siteId
      );
      const siteSnap = await getDoc(siteRef);
      const tempSiteData = siteSnap.data();
      setSiteData(tempSiteData);
      if (tempSiteData?.siteType !== "Manual") {
        if (!tempSiteData?.plan) {
          history(`/account/${params.accountId}/billing/plan/${params.siteId}`);
        } else {
          if(tempSiteData?.plan?.path){
            const planRef = doc(Firestore, tempSiteData?.plan?.path);
            const planSnapshot = await getDoc(planRef);
        
            if (planSnapshot.exists()) {
              setPlanData(planSnapshot.data());
              setIsTemplateSelected(tempSiteData?.isMyTemplate);
            } else {
              console.log("No plan data found!");
            }
          }else{
            console.error("Plan path is not defined in tempSiteData")
          }

          // const planRef = await Firestore.doc(tempSiteData?.plan?.path).get();
          // setPlanData(planRef.data());
          // setIsTemplateSelected(tempSiteData?.isMyTemplate);
        }
      }
      setIsLoading(false);
    }
  }, [params, history]);

  const handleManageEnvironment = useCallback(
    (envData) => {
      const { accountId, siteId } = params;
      history({
        pathname: `/account/${accountId}/manage-environment/${siteId}`,
        state: { envData },
      });
    },
    [history, params]
  );

  // const onCreateEnvironment = useCallback(
  //   async (envName) => {
  //     // Update Site status to pending-test/pending-production until it will be deployed to that env.
  //     await Firestore.collection(`/accounts/${params.accountId}/sites`)
  //       .doc(params.siteId)
  //       .update({ status: `pending-${envName.toLowerCase()}` });
  //     await fetchSiteData();
  //     let deployFunction;
  //     const data = {
  //       slug: siteData.slug,
  //       accountId: params.accountId,
  //       siteId: params.siteId,
  //     };

  //     if (envName === "Test") {
  //       deployFunction = httpsCallable(CloudFunctions,
  //         "kwallCloud-pantheonSiteTest"
  //       );
  //     } else {
  //       deployFunction = httpsCallable(CloudFunctions,
  //         "kwallCloud-pantheonSiteLive"
  //       );
  //     }

  //     await deployFunction(data);
  //   },
  //   [siteData, params, fetchSiteData]
  // );

  const onCreateEnvironment = useCallback(
    async (envName) => {
      // Update Site status to pending-test/pending-production until it will be deployed to that env.
      const siteRef = doc(
        Firestore,
        `/accounts/${params.accountId}/sites`,
        params.siteId
      );
      await updateDoc(siteRef, { status: `pending-${envName.toLowerCase()}` });
      await fetchSiteData();
      let deployFunction;
      const data = {
        slug: siteData.slug,
        accountId: params.accountId,
        siteId: params.siteId,
      };

      if (envName === "Test") {
        deployFunction = httpsCallable(
          CloudFunctions,
          "kwallCloud-pantheonSiteTest"
        );
      } else {
        deployFunction = httpsCallable(
          CloudFunctions,
          "kwallCloud-pantheonSiteLive"
        );
      }

      await deployFunction(data);
    },
    [siteData, params, fetchSiteData]
  );

  const handleClose = useCallback(() => {
    setProgressAction();
  }, []);

  useEffect(() => {
    fetchSiteData();
  }, [fetchSiteData]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id || params.accountId}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id || params.accountId}/sites`,
        text: "Sites",
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [
    setBreadcrumb,
    title,
    currentAccount.id,
    currentAccount.name,
    params.accountId,
  ]);

  return (
    <>
      {!!progressAction &&
        (progressAction.status === "error" ? (
          <Alert
            severity="error"
            onClose={handleClose}
            sx={{
              position: "absolute",
              right: "24px",
              top: "-60px",
              zIndex: 10000,
            }}
          >
            {progressAction.action}
          </Alert>
        ) : progressAction.status === "info" ? (
          <Alert
            severity="info"
            onClose={handleClose}
            sx={{
              position: "absolute",
              right: "24px",
              top: "-60px",
              zIndex: 10000,
            }}
          >
            {progressAction.action}
          </Alert>
        ) : (
          <Alert
            severity="success"
            onClose={handleClose}
            sx={{
              position: "absolute",
              right: "24px",
              top: "-60px",
              zIndex: 10000,
            }}
          >
            {progressAction.action}
          </Alert>
        ))}
      {isLoading ? (
        <Loader> Loading ...</Loader>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          {siteData.siteType === "Manual" ? (
            <Grid item xs={12}>
              <SiteDetailsCard
                siteData={siteData}
                setIsDeleting={setIsLoading}
              />
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <SiteDetailsCard
                  siteData={siteData}
                  setIsDeleting={setIsLoading}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SubscriptionDetailsCard planData={planData} />
              </Grid>
            </Grid>
          )}

          <DiscoveredExtensions siteData={siteData} />
          <HelpCenter />

          {siteData.siteType !== "Manual" ? (
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Box className={classes.heading} mt={3} mb={3}>
                    {!isTemplateSelected ? "Environments" : "Environment"}
                  </Box>
                </Grid>
              </Grid>
              <SiteEnvironmentCards
                status={siteData.status}
                slug={siteData.slug}
                pantheonSiteId={siteData.pantheonSiteId}
                onCreateEnvironment={onCreateEnvironment}
                handleManageEnvironment={handleManageEnvironment}
                setProgressAction={setProgressAction}
              />
            </>
          ) : (
            <ExternalSiteCard />
          )}
          <SiteMarketPlace />
        </Box>
      )}
    </>
  );
};

export default SiteDetails;
