import {
  Box,
  Button,
  Card,
  Divider,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useCallback, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FirebaseStorage } from "../../../../../components/FirebaseAuth/firebase";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "600px",
    padding: "20px",
    gap: "24px",
  },
  titleText: {
    fontSize: "22px",
    fontWeight: 400,
    flex: 1,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  extensionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
  },
  scrollContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 0",
    gap: "16px",
    height: "600px",
    overflow: "scroll",
  },
  uploadInput: {
    display: "none",
  },
}));

export const UpdateExtensionModal = memo(
  ({ prevExtensions, showModal, handleClose, handleUpdate }) => {
    const classes = useStyles();
    const [extensions, setExtensions] = useState(prevExtensions);

    const handleChange = useCallback(
      (id, event) => {
        const updatedExtensions = extensions.map((item, index) => {
          if (id === index) {
            return {
              ...item,
              [event.target.name]: event.target.value,
            };
          }
          return item;
        });
        setExtensions(updatedExtensions);
      },
      [extensions]
    );

    // const handleUpload = useCallback(async (image) => {
    //   try {
    //     const downloadUrl = await FirebaseStorage.ref(
    //       `sites/${image.name}`
    //     ).put(image);
    //     return downloadUrl.ref.getDownloadURL();
    //   } catch (ex) {
    //     console.log("ex", ex);
    //   }
    // }, []);
    const handleUpload = useCallback(
      async (image) => {
        try {
          const storageRef = ref(getStorage(), `sites/${image.name}`);
          await uploadBytes(storageRef, image);
          const downloadUrl = await getDownloadURL(storageRef);
          return downloadUrl;
        } catch (ex) {
          console.log("ex", ex);
        }
      },
      []
    );

    const handleChangeImage = useCallback(
      async (id, event) => {
        if (!!event.target.files[0]) {
          const uploadUrl = await handleUpload(event.target.files[0]);
          const updatedExtensions = extensions.map((item, index) => {
            if (id === index) {
              return {
                ...item,
                image: uploadUrl,
              };
            }
            return item;
          });
          setExtensions(updatedExtensions);
        }
      },
      [extensions, handleUpload]
    );

    const handleAddItem = useCallback(() => {
      const newItem = {
        title: "",
        url: "",
        image: "",
        createdAt: new Date().toString(),
      };
      setExtensions((prev) => [newItem, ...prev]);
    }, []);

    const handleClickCancel = useCallback(() => {
      setExtensions(prevExtensions);
      handleClose();
    }, [handleClose, prevExtensions]);

    const handleDeleteItem = useCallback((id) => {
      setExtensions((prev) => prev.filter((_, index) => index !== id));
    }, []);

    const handleClickSave = useCallback(() => {
      handleUpdate(extensions);
      handleClose();
    }, [extensions, handleClose, handleUpdate]);

    return (
      <Modal
        onClose={handleClose}
        open={showModal}
        className={classes.modalContainer}
      >
        <Card className={classes.cardContainer}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.titleText}>Extensions</Typography>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSave}
            >
              Save Changes
            </Button>
          </Box>
          <Divider />
          <Button fullWidth variant="outlined" onClick={handleAddItem}>
            Manually Add Extension
          </Button>
          <Box className={classes.scrollContainer}>
            {extensions.map((extension, index) => (
              <Box className={classes.extensionContainer}>
                <Box gap="12px" display="flex" flexDirection="column" flex={1}>
                  <TextField
                    label="Extension Name"
                    name="title"
                    fullWidth
                    value={extension.title || ""}
                    onChange={(event) => handleChange(index, event)}
                    className={classes.textField}
                  />
                  <TextField
                    label="Extension URL"
                    name="url"
                    fullWidth
                    value={extension.url}
                    onChange={(event) => handleChange(index, event)}
                    className={classes.textField}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ width: 100 }}
                    onClick={() => handleDeleteItem(index)}
                  >
                    Delete
                  </Button>
                </Box>
                <Box className={classes.imageContainer}>
                  {extension.image ? (
                    <Box
                      component="img"
                      alt={extension.image}
                      src={extension.image}
                      width={32}
                      height={32}
                    />
                  ) : (
                    <PermMediaIcon width={32} height={32} color="primary" />
                  )}

                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      name="image"
                      type="file"
                      onChange={(event) => handleChangeImage(index, event)}
                      className={classes.uploadInput}
                    />
                    <Box display="flex" alignItems="center">
                      <CloudUploadIcon fontSize="12px" color="primary" />
                      <Box>
                        <Typography ml={1} fontSize="12px" color="primary">
                          Update Image
                        </Typography>
                      </Box>
                    </Box>
                  </label>
                </Box>
              </Box>
            ))}
          </Box>
        </Card>
      </Modal>
    );
  }
);
