import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import {
  FirebaseAuth,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { OrganizationItem } from "../../../../components/Organizations/OrganizationItem";
import { doc, setDoc, collection, getDocs, query, where } from "firebase/firestore";
const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    padding: 12,
  },
}));

const OrganizationSwitch = () => {
  const history = useNavigate();
  const classes = useStyles();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = useCallback(() => {
    history("/organization_list/create");
  }, [history]);

  // const getOrganizationSwitch = useCallback(async () => {
  //   setIsLoading(true);
  //   let temp = [];
  //   const organizationSnapShots = await FirebaseAuth.firestore()
  //     .collection("organizations")
  //     .where("access", "array-contains", FirebaseAuth.currentUser.uid)
  //     .get();
  //   organizationSnapShots.forEach((doc) => {
  //     if (!doc.data().status || doc.data().status !== "deleted") {
  //       temp.push({ ...doc.data(), id: doc.id });
  //     }
  //   });
  //   setOrganizations(temp);
  //   setIsLoading(false);
  // }, []);


  const getOrganizationSwitch = useCallback(async () => {
    setIsLoading(true);
    let temp = [];
    try {
      const organizationQuery = query(
      collection(Firestore, "organizations"),
      where("access", "array-contains", FirebaseAuth.currentUser.uid)
    );
    const organizationSnapShots = await getDocs(organizationQuery);
    organizationSnapShots.forEach((doc) => {
      if (!doc.data().status || doc.data().status !== "deleted") {
        temp.push({ ...doc.data(), id: doc.id });
      }
    });
    setOrganizations(temp);
    } catch (ex) {
      console.error("Error fetching organizations:", ex);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // const handleDeleteOrganization = useCallback(
  //   async (id) => {
  //     try {
  //       await Firestore.collection("organizations")
  //         .doc(id)
  //         .set({ status: "deleted" }, { merge: true });
  //       getOrganizationSwitch();
  //       toast.success("Deleted Successfully");
  //     } catch (ex) {
  //       toast.error("Something went wrong");
  //     }
  //   },
  //   [getOrganizationSwitch]
  // );
  const handleDeleteOrganization = useCallback(
    async (id) => {
      try {
        const organizationRef = doc(Firestore, "organizations", id);
        await setDoc(organizationRef, { status: "deleted" }, { merge: true });
        await getOrganizationSwitch(); // Ensure this is awaited if it returns a promise
        toast.success("Deleted Successfully");
      } catch (ex) {
        toast.error("Something went wrong");
      }
    },
    [getOrganizationSwitch]
  );

  const handleDelete = useCallback(
    (id) => {
      confirmAlert({
        title: "Are you sure?",
        message: "Are you sure you want to delete this? This cannot be undone.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => handleDeleteOrganization(id),
          },
          {
            label: "No",
            onClick: () => alert("Click No"),
          },
        ],
      });
    },
    [handleDeleteOrganization]
  );

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: "Organizations",
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    getOrganizationSwitch();
  }, [getOrganizationSwitch]);

  return (
    <Container className={classes.container}>
      <Box className={classes.btnContainer}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          <Box>Create New Organization</Box>
        </Button>
      </Box>
      {!isLoading &&
        organizations.length > 0 &&
        organizations.map((organization) => {
          return (
            <OrganizationItem
              organization={organization}
              key={organization.id}
              handleDelete={() => handleDelete(organization.id)}
            />
          );
        })}
    </Container>
  );
};

export default OrganizationSwitch;
