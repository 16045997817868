import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, CardMedia, Button } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import {
  CloudFunctions,
  Firestore,
} from "../../components/FirebaseAuth/firebase";
import { defaultTemplateUrl } from "../../utils/index";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../utils/localStorage";
import { webCheckUrl } from "../../utils/constants";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 200,
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    paddingRight: "12px",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
    },
  },
  img: {
    height: 160,
    width: 160,
    margin: "24px 0 0 24px",
  },

  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "24px 12px",
  },

  heading: {
    fontFamily: "Roboto",
    color: "#1E1E1E",
    fontSize: 22,
    fontWeight: 400,
  },
  siteType: {
    color: "#6A6A6A",
  },
  description: {
    color: "#6A6A6A",
    flex: 1,
    maxHeight: 100,
    fontSize: "14px",
  },
  link: {
    color: "#0a79a6",
    textDecoration: "underline",
    cursor: "pointer",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
  },
  webCheckButton: {
    color: "#0a79a6",
    fontWeight: 700,
    border: "1px solid #0a79a6",
    padding: "2px 4px",
    fontSize: "10px",
    borderRadius: "4px",
    cursor: "pointer",
    boxShadow: "1px 2px 2px #0a79a6",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    gap: "12px",
  },
}));

const SiteDetailsCard = ({ siteData, setIsDeleting }) => {
  const { siteName, siteType, description, image, webCheckResult, slug } =
    siteData;

  const { accountId, siteId } = useParams();
  const history = useNavigate();
  const classes = useStyles();

  const [siteImage, setSiteImage] = useState("");

  const handleEdit = () => {
    const url = `/account/${accountId}/sites/edit/${siteId}`;
    history(url);
  };

  const handleClickLink = useCallback(() => {
    window.open(siteData.url, "_blank");
  }, [siteData]);

  // async function deleteSiteUrls() {
  //   try {
  //     const deploySitesSnapshot = await Firestore.collection("deploySites")
  //       .where("siteId", "==", siteId)
  //       .get();
  //     if (!deploySitesSnapshot?.empty) {
  //       for (let deploySite of deploySitesSnapshot) {
  //         await deploySite.ref.set({ status: "deleted" }, { merge: true });
  //       }
  //     }
  //     return;
  //   } catch (ex) {
  //     console.log("____ ex", ex);
  //   }
  // }
  async function deleteSiteUrls() {
    try {
      const deploySitesRef = collection(Firestore, "deploySites");
      const deploySitesQuery = query(
        deploySitesRef,
        where("siteId", "==", siteId)
      );
      const deploySitesSnapshot = await getDocs(deploySitesQuery);
      if (!deploySitesSnapshot.empty) {
        for (let deploySite of deploySitesSnapshot.docs) {
          await setDoc(deploySite.ref, { status: "deleted" }, { merge: true });
        }
      }
      return;
    } catch (ex) {
      console.log("____ ex", ex);
    }
  }

  // async function deleteFromOrganization() {
  //   try {
  //     const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  //     const refOrganization = Firestore.collection("organizations").doc(
  //       currentOrganization.id
  //     );

  //     const updatedTemplates = currentOrganization?.templates?.length
  //       ? currentOrganization.templates.filter((temp) => temp.siteId !== siteId)
  //       : [];

  //     await refOrganization.set({
  //       ...currentOrganization,
  //       templates: updatedTemplates,
  //     });
  //   } catch (ex) {}
  // }
  async function deleteFromOrganization() {
    try {
      const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
      const refOrganization = doc(
        Firestore,
        "organizations",
        currentOrganization.id
      );
      const updatedTemplates = currentOrganization?.templates?.length
        ? currentOrganization.templates.filter((temp) => temp.siteId !== siteId)
        : [];

      await setDoc(refOrganization, {
        ...currentOrganization,
        templates: updatedTemplates,
      });
    } catch (ex) {
      console.log("Error deleting from organization:", ex);
    }
  }

  // const handleDeleted = async (site) => {
  //   try {
  //     setIsDeleting(true);
  //     await deleteFromOrganization();
  //     await Firestore.collection(`/accounts/${accountId}/sites`)
  //       .doc(siteId)
  //       .set({ status: "deleted" }, { merge: true });

  //     const invoiceSnapshot = await Firestore.collection(
  //       `/accounts/${accountId}/sites/${siteId}/invoices`
  //     )
  //       .where("siteId", "==", siteId)
  //       .get();

  //     if (!!invoiceSnapshot && !invoiceSnapshot.empty) {
  //       for (let invoice of invoiceSnapshot) {
  //         await invoice.ref.delete();
  //       }
  //     }
  //     const updateSiteTag = httpsCallable(CloudFunctions,
  //       "kwallCloud-updateSiteTag"
  //     );
  //     updateSiteTag({
  //       command: "tag:add",
  //       tag: "DELETED",
  //       slug,
  //     });
  //     await deleteSiteUrls();
  //     setIsDeleting(false);
  //     history(`/account/${accountId}/sites`);
  //   } catch (ex) {
  //     setIsDeleting(false);
  //     console.log("___ ex", ex);
  //   }
  // };
  const handleDeleted = async (site) => {
    try {
      setIsDeleting(true);
      await deleteFromOrganization();
      // Mark site as deleted in the account's sites collection
      const siteRef = doc(Firestore, `/accounts/${accountId}/sites`, siteId);
      await setDoc(siteRef, { status: "deleted" }, { merge: true });

      // Delete invoices related to the site
      const invoicesRef = collection(
        Firestore,
        `/accounts/${accountId}/sites/${siteId}/invoices`
      );
      const invoicesQuery = query(invoicesRef, where("siteId", "==", siteId));
      const invoiceSnapshot = await getDocs(invoicesQuery);

      if (!invoiceSnapshot.empty) {
        for (let invoice of invoiceSnapshot.docs) {
          await deleteDoc(invoice.ref);
        }
      }
      // Call Cloud Function to update the site tag
      const updateSiteTag = httpsCallable(
        CloudFunctions,
        "kwallCloud-updateSiteTag"
      );
      await updateSiteTag({
        command: "tag:add",
        tag: "DELETED",
        slug,
      });
      // Delete associated site URLs
      await deleteSiteUrls();
      setIsDeleting(false);
      history(`/account/${accountId}/sites`);
    } catch (ex) {
      setIsDeleting(false);
      console.log("___ ex", ex);
    }
  };

  const handleDelete = async (site) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => handleDeleted(site),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleClickWebCheck = useCallback(() => {
    const url = siteData.url.split("://")?.[1];
    window.open(`${webCheckUrl}/${url}`, "_blank");
  }, [siteData.url]);

  useEffect(() => {
    if (image) {
      setSiteImage(image);
    } else {
      const thumbnails =
        webCheckResult?.audits?.["screenshot-thumbnails"]?.details?.items;
      if (thumbnails?.length) {
        setSiteImage(thumbnails[thumbnails.length - 1]?.data);
      } else {
        setSiteImage(defaultTemplateUrl);
      }
    }
  }, [image, webCheckResult?.audits]);

  return (
    <Card className={classes.container}>
      <CardMedia
        component="img"
        className={classes.img}
        image={siteImage}
        alt="Site Image Here"
      />
      <Box className={classes.contentContainer}>
        <Box className={classes.heading}>{siteName}</Box>
        <Box className={classes.siteType}>{siteType}</Box>
        {siteType === "Manual" && (
          <Box className={classes.linkContainer}>
            <Box className={classes.link} onClick={handleClickLink}>
              {siteData.url}
            </Box>
            <Box
              className={classes.webCheckButton}
              onClick={handleClickWebCheck}
            >
              WEB CHECK
            </Box>
          </Box>
        )}
        <Box className={classes.description}>{description}</Box>
      </Box>
      <Box className={classes.topContainer}>
        <Box className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleEdit}
            size="small"
          >
            Edit
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => handleDelete(siteData)}
            size="small"
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default SiteDetailsCard;
