import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// export const useDownLarge = () =>
//   useMediaQuery((theme) => theme.breakpoints.down("lg"));

// export const useDownMedium = () =>
//   useMediaQuery((theme) => theme.breakpoints.down("md"));

// export const useDownSmall = () =>
//   useMediaQuery((theme) => theme.breakpoints.down("sm"));

export const useDownLarge = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("lg"));
};

export const useDownMedium = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};

export const useDownSmall = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};
