import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import ApplyForm from "../../../../components/Plans/ApplyForm";
import FeatureForm from "../../../../components/Plans/FeatureForm";
import MainForm from "../../../../components/Plans/MainForm";
import { doc, setDoc } from "firebase/firestore";

const AddPlan = () => {
  const history = useNavigate();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [plan, setPlan] = useState({});
  const [errors, setErrors] = useState();

  const handleUpdateFeatures = useCallback(
    (features) => {
      setPlan({ ...plan, features: features });
    },
    [plan]
  );

  // const updatePlan = useCallback(async () => {
  //   if (errors && Object.values(errors).indexOf((error) => error.isError) > -1)
  //     return;
  //   try {
  //     const planRef = FirebaseAuth.firestore().collection("plans").doc();
  //     await planRef.set(plan);
  //     toast.success("Added Successfully");
  //     history(-1);
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // }, [errors, plan, history]);

  const updatePlan = useCallback(async () => {
    if (errors && Object.values(errors).some((error) => error.isError)) {
      return;
    }
  
    try {
      const planRef = doc(Firestore, "plans", plan.id || doc(Firestore).id); // Use existing ID or generate a new one
      await setDoc(planRef, plan); // Set the plan document
      toast.success("Added Successfully");
      history(-1); // Navigate back
    } catch (error) {
      console.error("Error updating plan:", error);
      toast.error("Something went wrong");
    }
  }, [errors, plan, history]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/planLists",
        text: "Plans",
        active: false,
      },
      {
        to: null,
        text: `Add Plan`,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6}>
          <MainForm
            plan={plan}
            setPlan={setPlan}
            errors={errors}
            setErrors={setErrors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ApplyForm plan={plan} setPlan={setPlan} />
          <FeatureForm
            features={plan.features}
            setFeatures={handleUpdateFeatures}
          />
        </Grid>
      </Grid>
      <Box width="60%" margin="auto" marginTop={6}>
        <Button variant="contained" fullWidth onClick={updatePlan}>
          Add Plan
        </Button>
      </Box>
    </Box>
  );
};

export default AddPlan;
