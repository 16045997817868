
export const listResponse =[
    {
        "name": "Name",
        "field": "name",
        "style": {
            "width": "20%"
        }
    },
    {
        "name": "Description",
        "field": "description",
        "style": {
            "width": "20%"
        }
    },
    {
        "name": "Site Type",
        "field": "siteType",
        "style": {
            "width": "45%"
        }
    },
    {
        "name": "Actions",
        "field": "action",
        "style": {
            "width": "15%"
        }
    }
]
export const formSchema =[
    {
        "name": "name",
        "prop": "value"
    },
    {
        "name": "description",
        "prop": "value"
    },
    {
        "name": "siteType",
        "prop": "value"
    },
    {
        "name": "accountType",
        "prop": "value"
    },
    {
        "name": "siteId",
        "prop": "value"
    },
    {
        "name": "templateId",
        "prop": "value"
    },
    {
        "name": "image",
        "prop": "value"
    }
]
export const editFormSchema =[
    {
        "name": "name",
        "prop": "value"
    },
    {
        "name": "description",
        "prop": "value"
    },
    {
        "name": "siteType",
        "prop": "value"
    },
    {
        "name": "adminAccountEmail",
        "prop": "value"
    },
    {
        "name": "createdAt",
        "prop": "value"
    },
    {
        "name": "slug",
        "prop": "value"
    }
]