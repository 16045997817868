// import firebase from "firebase/app";

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// import 'firebase/firestore';
// import "firebase/auth";
// import "firebase/functions";
// import "firebase/storage";
// import {config} from '../../inc/firebase.json';
// const config = {
//     "apiKey": "AIzaSyAr6SpQYWALWKidh0vvizsDZV-Khc1DrwY",
//     "authDomain": "auth.sites.accorbis.com",
//     "databaseURL": "https://kwall-customer-portal.firebaseio.com",
//     "projectId": "kwall-customer-portal",
//     "storageBucket": "kwall-customer-portal.appspot.com",
//     "messagingSenderId": "399403099674",
//     "appId": "1:399403099674:web:d8873827a4d7042ae4d3a3"
// }
const config = JSON.parse(
    (process.env.REACT_APP_FIREBASE_CONFIG) || '{}',
  );

const app = initializeApp(config);
// const Firestore = FirebaseAuth.firestore();
// const CloudFunctions = FirebaseAuth.functions();
// const FirebaseStorage = FirebaseAuth.storage();
export const CloudFunctions = getFunctions(app);
// connectFunctionsEmulator(CloudFunctions, "127.0.0.1", 5001);
export const FirebaseAuth = getAuth(app);
export const Firestore = getFirestore(app);
// connectFirestoreEmulator(Firestore, '127.0.0.1', 8080);
export const FirebaseStorage = getStorage(app);
// export default app;

// export {FirebaseAuth, Firestore, CloudFunctions, FirebaseStorage}