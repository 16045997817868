import React, { useEffect, useContext, useState } from "react";
import {
  Paper,
  Divider,
  Grid,
  Button,
  Alert,
  Hidden,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import DomainCard from "../../../../components/DomainCard";
import DomainCardMobile from "../../../../components/DomainCardMobile";
import AddDomainDialog from "../../../../components/AddDomainDialog";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import {
  Firestore,
  CloudFunctions,
} from "../../../../components/FirebaseAuth/firebase";
import { httpsCallable } from "firebase/functions";
import { query, collection, where, getDocs, doc, getDoc } from "firebase/firestore";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  width: "100%",
}));

const SiteDomainEdit = () => {
  const title = "Edit Site Domain";
  const initialAckState = {
    status: "",
    message: "",
  };

  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ackMessage, setAckMessage] = useState(initialAckState);
  const [siteData, setSiteData] = useState({ data: [], isLoading: true });
  const params = useParams();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [setDomainIndex] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [domainList, setDomainList] = useState({ data: [], isLoading: true });

  // function fetchDomainList() {
  //   const domainCollectionsRef = Firestore.collection("domains").where(
  //     "siteId",
  //     "==",
  //     params.siteId
  //   );

  //   domainCollectionsRef.onSnapshot((querySnapshot) => {
  //     const domainArr = [];
  //     querySnapshot.forEach((doc) => {
  //       domainArr.push({ id: doc.id, ...doc.data() });
  //     });
  //     setDomainList({
  //       isLoading: false,
  //       data:
  //         domainArr[0] && domainArr[0].response && domainArr[0].response
  //           ? domainArr[0].response
  //           : [],
  //     });
  //   });
  // }
  function fetchDomainList() {
    const domainCollectionsRef = collection(Firestore, "domains");
    const q = query(domainCollectionsRef, where("siteId", "==", params.siteId));
  
    getDocs(q).then((querySnapshot) => {
      const domainArr = [];
      querySnapshot.forEach((doc) => {
        domainArr.push({ id: doc.id, ...doc.data() });
      });
      setDomainList({
        isLoading: false,
        data: domainArr[0] && domainArr[0].response ? domainArr[0].response : [],
      });
    });
  }

  // async function fetchSiteData() {
  //   if (!!params.siteId) {
  //     const siteRef = await Firestore.collection(
  //       `/accounts/${params.accountId}/sites`
  //     )
  //       .doc(params.siteId)
  //       .get();
  //     const siteData = siteRef.data();

  //     setSiteData({
  //       data: siteData,
  //       isLoading: false,
  //     });
  //   }
  // }
  async function fetchSiteData() {
    if (!!params.siteId) {
      const siteRef = doc(Firestore, `/accounts/${params.accountId}/sites`, params.siteId);
      const siteDoc = await getDoc(siteRef);
      
      if (siteDoc.exists()) {
        const siteData = siteDoc.data();
        setSiteData({
          data: siteData,
          isLoading: false,
        });
      }
    }
  }

  useEffect(() => {
    fetchDomainList();
    fetchSiteData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/site-details/${params.siteId}`,
        text: "Site Details",
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, currentAccount.id, currentAccount.name, params]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  const handleAddDomain = () => {
    handleClickOpen();
    setDomainIndex("");
  };

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();

      setIsSubmitting(true);

      if (isEditing) {
        const editDomain = httpsCallable(
          CloudFunctions,
          "kwallCloud-editDomain"
        );
        editDomain({
          siteId: params.siteId,
          site_name_slug: siteData.data.slug,
          domain: data.id,
          isPrimary: isPrimary,
        })
          .then((response) => {
            console.log("___ response", response);
          })
          .catch((err) => {
            console.log("___ err", err);
          });

        handleClose();
        reset();
        setDomainIndex("");
        displayAckMessage(
          "Updated Successfully But It may takes time to sync with server",
          "update"
        );
        setIsSubmitting(false);
        setIsPrimary(false);
        setIsEditing(false);
        return;
      }

      const addDomain = httpsCallable(CloudFunctions,"kwallCloud-addDomain");
      addDomain({
        siteId: params.siteId,
        site_name_slug: siteData.data.slug,
        domain: data.id,
      })
        .then((response) => {
          console.log("___ response", response);
        })
        .catch((err) => {
          console.log("___ err", err);
        });

      handleClose();
      reset();
      setIsPrimary(false);
      displayAckMessage(
        "Added Successfully But It may takes time to sync with server",
        "add"
      );
      setIsSubmitting(false);
    } catch (ex) {
      console.log("____", ex);
    }
  };

  const displayAckMessage = (message, status) => {
    setAckMessage({
      message,
      status,
    });
    setTimeout(() => setAckMessage(initialAckState), 1000);
  };

  const handleUpdatedomain = (domainData, index) => {
    console.log("___ domainData", domainData);
    const { id, primary } = domainData;
    setValue("id", id);
    // setValue('type', type);
    // setValue('status', status);
    // setValue('statusMessage', statusMessage);
    // setValue('primary', primary);
    // setValue('deletable', deletable);
    // setDomainIndex(index);
    setIsEditing(true);
    setIsPrimary(primary);
    handleClickOpen();
  };

  function getStatusStyle(status) {
    switch (status) {
      case "delete":
        return "error";
      case "update":
        return "info";
      case "add":
        return "success";
      default:
        return "";
    }
  }

  return (
    <>
      {!!ackMessage.status && (
        <Alert severity={getStatusStyle(ackMessage.status)}>
          {ackMessage.message}
        </Alert>
      )}
      <AddDomainDialog
        open={open}
        isSubmitting={isSubmitting}
        control={control}
        getValues={getValues}
        setValue={setValue}
        onClose={handleClose}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={domainList.data.length === 3}
              onClick={handleAddDomain}
            >
              Add Domain
            </Button>
          </Grid>
        </Grid>
        <Item>
          {!domainList.isLoading &&
            domainList.data.map((data, index) => {
              return (
                <div key={data.id}>
                  <DomainCard
                    data={data}
                    index={index}
                    setAckMessage={setAckMessage}
                    onDisplayAckMessage={displayAckMessage}
                    onUpdateDomain={handleUpdatedomain}
                  />
                  <Divider style={{ margin: "20px 0px 20px 0px" }} flexItem />
                </div>
              );
            })}
        </Item>
      </Hidden>
      <Hidden smUp>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={domainList.data.length === 3}
              onClick={handleAddDomain}
            >
              <Box style={{ fontSize: 9 }}>Add Domain </Box>
            </Button>
          </Grid>
        </Grid>
        <Item>
          {!domainList.isLoading &&
            domainList.data.map((data, index) => {
              return (
                <div key={data.id}>
                  <DomainCardMobile
                    data={data}
                    index={index}
                    setAckMessage={setAckMessage}
                    onDisplayAckMessage={displayAckMessage}
                    onUpdateDomain={handleUpdatedomain}
                  />
                  <Divider style={{ margin: "20px 0px 20px 0px" }} flexItem />
                </div>
              );
            })}
        </Item>
      </Hidden>
      {!domainList.isLoading && domainList.data.length === 0 && (
        <Paper>
          <p style={{ textAlign: "center", padding: 50 }}>No Domain Yet.</p>
        </Paper>
      )}
    </>
  );
};

export default SiteDomainEdit;
