import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  Alert,
  Box,
  Button,
  Paper,
  Autocomplete,
  TextField,
  CardContent,
  Stack,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams, useLocation } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import Loader from "../../../../components/Loader";
import {
  FirebaseAuth,
  CloudFunctions,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { countries } from "../../../../constants/countries";
// import { countries } from "../../../../inc/country.json";
import { PlanCard } from "./PlanCard";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../../utils/localStorage";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
const useStyles = makeStyles((theme) => ({
  billingDetailHeading: {
    fontSize: 22,
    fontWeight: 400,
    color: "#1E1E1E",
    padding: 14,
  },
  fieldSet: {
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    position: "absolute",
    top: "-5px",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0",
    padding: "0 8px",
    overflow: "hidden",
    pointerEvents: "none",
  },
  cardElementContainer: {
    position: "relative",
    minHeight: "56px",
    padding: "10px",
    paddingTop: 16,
  },
}));

const Plans = () => {
  const title = "Select a Plan";

  const classes = useStyles();
  const { authUser } = useContext(AuthContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const stripe = useStripe();
  const elements = useElements();
  const { siteId } = useParams();
  const location = useLocation();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({ id: 0 });
  const [cardError, setCardError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(null);
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [stateError, setStateError] = useState(null);
  const [prevSelectedPlan, setPrevSelectedPlan] = useState(null);
  const [planExpired, setPlanExpired] = useState(false);
  const [siteData, setSiteData] = useState();

  // const getPlanData = useCallback(async () => {
  //   setLoading(true);
  //   // Get User Data
  //   const userRef = await Firestore.collection("users")
  //     .doc(authUser.user.uid)
  //     .get();

  //   // Get Site Data
  //   const siteRef = await Firestore.collection(
  //     `/accounts/${currentAccount.id}/sites`
  //   )
  //     .doc(siteId)
  //     .get();
  //   setSiteData({ id: siteId, ...siteRef.data() });

  //   // Get Previous Plan Data
  //   const prevPlan = await Firestore.collection(
  //     `accounts/${currentAccount.id}/sites/${siteId}/invoices`
  //   ).get();
  //   if (!prevPlan.empty) {
  //     if (
  //       !siteRef.data().plan ||
  //       (siteRef.data().plan && siteRef.data().paymentStatus === "expired")
  //     ) {
  //       setPrevSelectedPlan("Early Adopter - 30 Days Free");
  //       setPlanExpired(true);
  //     } else {
  //       const planData = await siteRef.data().plan.get();
  //       setPrevSelectedPlan(planData.data().name);
  //     }
  //   }

  //   // Get All Plan Lists
  //   const planSnapShots = await FirebaseAuth.firestore()
  //     .collection("plans")
  //     .orderBy("position", "asc")
  //     .get();
  //   let p = [];
  //   planSnapShots.forEach((doc) => {
  //     let isShown = true;
  //     if (
  //       doc.data()?.customerID?.length > 0 &&
  //       !doc.data().customerID.includes(currentAccount.id)
  //     ) {
  //       isShown = false;
  //     }
  //     if (
  //       doc.data()?.organizationID?.length > 0 &&
  //       !doc.data().organizationID.includes(currentOrganization.id)
  //     ) {
  //       isShown = false;
  //     }
  //     if (
  //       doc.data()?.excludeCustomerID?.length > 0 &&
  //       doc.data()?.excludeCustomerID.includes(currentAccount.id)
  //     ) {
  //       isShown = false;
  //     }
  //     if (
  //       doc.data()?.excludeOrganizationID?.length > 0 &&
  //       doc.data()?.excludeOrganizationID.includes(currentOrganization.id)
  //     ) {
  //       isShown = false;
  //     }
  //     if (doc.data()?.role?.length > 0) {
  //       let isRoleShown = false;
  //       doc.data()?.role.forEach((required) => {
  //         if (userRef.data().role && userRef.data().role.includes(required)) {
  //           isRoleShown = true;
  //         }
  //       });
  //       isShown = isRoleShown;
  //     }

  //     if (isShown) {
  //       p.push({
  //         id: doc.id,
  //         current: currentAccount.planId === doc.id ? true : false,
  //         ...doc.data(),
  //       });
  //     }
  //   });
  //   if (p.length > 0) {
  //     const ascendingOrderPlans = p.sort(
  //       (a, b) => parseFloat(a.order) - parseFloat(b.order)
  //     );
  //     setPlans(ascendingOrderPlans);
  //   }
  //   setLoading(false);
  // }, [
  //   currentAccount?.planId,
  //   currentAccount?.id,
  //   authUser,
  //   siteId,
  //   currentOrganization?.id,
  // ]);
  const getPlanData = useCallback(async () => {
    setLoading(true);
    try {
      // Get User Data
      const userRef = doc(Firestore, "users", authUser.user.uid);
      const userSnap = await getDoc(userRef);

      // Get Site Data
      const siteRef = doc(
        Firestore,
        `/accounts/${currentAccount.id}/sites`,
        siteId
      );
      const siteSnap = await getDoc(siteRef);
      setSiteData({ id: siteId, ...siteSnap.data() });

      // Get Previous Plan Data
      const prevPlanRef = collection(
        Firestore,
        `accounts/${currentAccount.id}/sites/${siteId}/invoices`
      );
      const prevPlanSnap = await getDocs(prevPlanRef);

      if (!prevPlanSnap.empty) {
        const siteData = siteSnap.data();
        if (
          !siteData.plan ||
          (siteData.plan && siteData.paymentStatus === "expired")
        ) {
          setPrevSelectedPlan("Early Adopter - 30 Days Free");
          setPlanExpired(true);
        } else {
          const planDoc = await getDoc(siteData.plan);
          setPrevSelectedPlan(planDoc.data().name);
        }
      }

      // Get All Plan Lists
      const plansRef = collection(Firestore, "plans");
      const plansQuery = query(plansRef, orderBy("position", "asc"));
      const planSnapShots = await getDocs(plansQuery);

      let p = [];
      planSnapShots.forEach((doc) => {
        let isShown = true;
        const planData = doc.data();

        if (
          planData?.customerID?.length > 0 &&
          !planData.customerID.includes(currentAccount.id)
        ) {
          isShown = false;
        }
        if (
          planData?.organizationID?.length > 0 &&
          !planData.organizationID.includes(currentOrganization.id)
        ) {
          isShown = false;
        }
        if (
          planData?.excludeCustomerID?.length > 0 &&
          planData.excludeCustomerID.includes(currentAccount.id)
        ) {
          isShown = false;
        }
        if (
          planData?.excludeOrganizationID?.length > 0 &&
          planData.excludeOrganizationID.includes(currentOrganization.id)
        ) {
          isShown = false;
        }
        if (planData?.role?.length > 0) {
          let isRoleShown = false;
          planData.role.forEach((required) => {
            if (
              userSnap.data().role &&
              userSnap.data().role.includes(required)
            ) {
              isRoleShown = true;
            }
          });
          isShown = isRoleShown;
        }

        if (isShown) {
          p.push({
            id: doc.id,
            current: currentAccount.planId === doc.id ? true : false,
            ...planData,
          });
        }
      });

      if (p.length > 0) {
        const ascendingOrderPlans = p.sort(
          (a, b) => parseFloat(a.order) - parseFloat(b.order)
        );
        setPlans(ascendingOrderPlans);
      }
    } catch (error) {
      console.error("Error fetching plan data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentAccount?.planId,
    currentAccount?.id,
    authUser,
    siteId,
    currentOrganization?.id,
  ]);

  const subscribe = useCallback(
    async (event) => {
      event.preventDefault();
      setProcessing(true);
      setErrorMessage(null);

      let hasError = false;
      let paymentMethodId = "";

      if (selectedPlan.price !== 0) {
        if (country === "") {
          setCountryError("Please select a country.");
          hasError = true;
        }

        if (state === "" && countries[country] && countries[country].states) {
          setStateError("Please select a state.");
          hasError = true;
        }

        setCardError(null);

        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setCardError(error.message);
          hasError = true;
        } else {
          paymentMethodId = paymentMethod.id;
        }
      }

      if (!hasError) {
        try {
          // const createSubscription = httpsCallable(CloudFunctions,
          //   "kwallCloud-createSubscription"
          // );
          const createSubscription = httpsCallable(
            CloudFunctions,
            "kwallCloud-createSubscription"
          );
          await createSubscription({
            siteId: siteId,
            planId: selectedPlan.id,
            accountId: currentAccount.id,
            paymentMethodId: paymentMethodId,
            billing: {
              country: country,
              state: state,
            },
          });
          // const createDeploySite = httpsCallable(CloudFunctions,
          //   "kwallCloud-createDeploySite"
          // );
          const createDeploySite = httpsCallable(
            CloudFunctions,
            "kwallCloud-createDeploySite"
          );
          await createDeploySite({
            adminAccountEmail: siteData.adminAccountEmail,
            siteType: siteData.siteType,
            status: siteData.status,
            siteName: siteData.siteName,
            slug: siteData.slug,
            accountId: currentAccount.id,
            id: siteId,
            templateType: siteData.templateType,
            siteTemplateId: siteData.siteTemplateId,
            templateId: siteData.templateId,
            planId: selectedPlan.id,
          });
          document.location = `/account/${currentAccount.id}/sites`;
        } catch (err) {
          setProcessing(false);
          setErrorMessage(err.message);
        }
      } else {
        setProcessing(false);
      }
    },
    [
      country,
      currentAccount.id,
      elements,
      selectedPlan,
      siteId,
      state,
      stripe,
      siteData,
    ]
  );

  useEffect(() => {
    getPlanData();
  }, [getPlanData]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: currentAccount.name,
        active: false,
      },
      {
        to:
          location && location.isEditing
            ? `/account/${currentAccount.id}/site-details/${siteId}`
            : `/account/${currentAccount.id}/billing/plan/${siteId}`,
        text: location && location.isEditing ? "Site Details" : "New Site",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [
    setBreadcrumb,
    title,
    currentAccount.name,
    currentAccount.id,
    location,
    siteId,
  ]);

  return loading ? (
    <Loader text="loading plans..." />
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={selectedPlan?.price > 0 ? 8 : 12}>
          <Paper style={{ padding: 20 }}>
            <Box
              style={{
                fontSize: 22,
                fontWeight: 700,
                fontFamily: "Roboto",
                padding: 14,
                textAlign: "center",
              }}
            >
              {title}
            </Box>
            <Box
              spacing={2}
              sx={{
                display: "flex",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {plans.length > 0 ? (
                plans.map((plan, id) => {
                  return (
                    <PlanCard
                      key={id}
                      plan={plan}
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      prevSelectedPlan={prevSelectedPlan}
                      isExpired={planExpired}
                    />
                  );
                })
              ) : (
                <Alert severity="warning">No plan is found.</Alert>
              )}
            </Box>
          </Paper>
        </Grid>
        {selectedPlan?.price > 0 && (
          <Grid item xs={12} md={4}>
            <Paper>
              {selectedPlan.id !== 0 && selectedPlan.price > 0 && (
                <div>
                  <Box className={classes.billingDetailHeading}>
                    Billing Details
                  </Box>
                  <Grid container>
                    <Grid container item xs={12}>
                      <Card style={{ width: "100%" }}>
                        <CardContent>
                          <Stack spacing={3}>
                            {countryError !== null && (
                              <Alert
                                severity="error"
                                onClose={() => setCountryError()}
                              >
                                {countryError}
                              </Alert>
                            )}
                            <Autocomplete
                              value={
                                country !== ""
                                  ? countries.find(
                                      (obj) => obj.code === country
                                    )
                                  : null
                              }
                              options={countries}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Country"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              )}
                              onChange={(event, newValue) => {
                                if (newValue?.code) {
                                  setCountry(newValue.code);
                                  setState("");
                                  if (newValue.states) {
                                    setStates(newValue.states);
                                  } else {
                                    setStates([]);
                                  }
                                  setCountryError(null);
                                }
                              }}
                            />
                            {states.length > 0 && (
                              <>
                                {stateError !== null && (
                                  <Alert
                                    severity="error"
                                    onClose={() => setStateError(null)}
                                  >
                                    {stateError}
                                  </Alert>
                                )}
                                <Autocomplete
                                  value={
                                    state !== ""
                                      ? states.find((obj) => obj.code === state)
                                      : null
                                  }
                                  options={states}
                                  autoHighlight
                                  getOptionLabel={(option) => option.label}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.label}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State"
                                      style={{ fontSize: 14 }}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",
                                      }}
                                    />
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue?.code) {
                                      setState(newValue.code);
                                      setStateError(null);
                                    }
                                  }}
                                />
                              </>
                            )}
                            {cardError !== null && (
                              <Alert
                                severity="error"
                                onClose={() => setCardError(null)}
                              >
                                {cardError}
                              </Alert>
                            )}
                            <div className={classes.cardElementContainer}>
                              <CardElement options={CARD_ELEMENT_OPTIONS} />
                              <fieldset className={classes.fieldSet}></fieldset>
                            </div>
                          </Stack>
                          <Grid container style={{ marginTop: 20 }}>
                            {errorMessage !== null && (
                              <Alert
                                severity="error"
                                onClose={() => setErrorMessage(null)}
                              >
                                {errorMessage}
                              </Alert>
                            )}
                            <Button
                              type="cancel"
                              variant="contained"
                              color="primary"
                              onClick={(e) => subscribe(e)}
                            >
                              Subscribe Now
                            </Button>
                            <Button
                              type="cancel"
                              variant="contained"
                              style={{
                                backgroundColor: "#F3F6F8",
                                color: "#BD1B00",
                                marginLeft: 20,
                              }}
                              onClick={() => setSelectedPlan({ id: 0 })}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
      {selectedPlan?.price === 0 && (
        <div style={{ marginTop: "50px" }}>
          <Container maxWidth="sm">
            <Stack spacing={3}>
              {errorMessage !== null && (
                <Alert severity="error" onClose={() => setErrorMessage(null)}>
                  {errorMessage}
                </Alert>
              )}
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                disabled={selectedPlan.id === 0 || processing ? true : false}
                onClick={(e) => {
                  subscribe(e);
                }}
              >
                {processing ? (
                  <>
                    <Loader /> Processing...
                  </>
                ) : (
                  <>Subscribe Now</>
                )}
              </Button>
            </Stack>
          </Container>
        </div>
      )}
    </>
  );
};

export default Plans;
