import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormResult, Input } from "../../../../components/Form";
import firebase from "firebase/app";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import UserPageLayout from "../../../../components/user/UserPageLayout";
import { log, UPDATE_PASSWORD } from "../../../../libs/log";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

const UpdatePassword = () => {
  const title = "Change Your Password";
  const backToUrl = "/user/profile";
  const history = useNavigate();
  const mountedRef = useRef(true);

  const [password, setPassword] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [newPassword, setNewPassword] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [confirmPassword, setConfirmPassword] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const { authUser } = useContext(AuthContext);

  const [result, setResult] = useState({
    status: null,
    message: "",
  });

  const [inSubmit, setInSubmit] = useState(false);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <UserPageLayout title={title}>
      {result.status === null && (
        <Form
          handleSubmit={async (e) => {
            e.preventDefault();
            setInSubmit(true);
            if (newPassword.value !== confirmPassword.value) {
              setConfirmPassword({
                hasError: true,
                error:
                  "The confirm password does not match with the new password.",
                value: password.value,
              });
              setInSubmit(false);
            } else {
              // check password
              // const credential = firebase.auth.EmailAuthProvider.credential(
              //   FirebaseAuth().currentUser.email,
              //   password.value
              // );
              const user = FirebaseAuth.currentUser;
              try {
                // Create the credential using the existing email and password
                const credential = EmailAuthProvider.credential(user.email, password.value);
            
                // Reauthenticate the user
                await reauthenticateWithCredential(user, credential);
            
                if (!mountedRef.current) return;
            
                // Update the user's password
                await updatePassword(user, newPassword.value);
                log(UPDATE_PASSWORD);
                if (!mountedRef.current) return;
                setResult({
                  status: true,
                  message: "Your password has been updated.",
                });
              } catch (error) {
                if (!mountedRef.current) return;
            
                if (error.code === "auth/wrong-password") {
                  setPassword({
                    hasError: true,
                    error: "Incorrect password, authentication failed.",
                    value: password,
                  });
                } else {
                  setResult({
                    status: false,
                    message: error.message,
                  });
                }
              } finally {
                setInSubmit(false);
              }
            }
          }}
          disabled={
            password.hasError ||
            newPassword.hasError ||
            confirmPassword.hasError ||
            password.value === null ||
            newPassword.value === null ||
            confirmPassword.value === null ||
            inSubmit
          }
          inSubmit={inSubmit}
          enableDefaultButtons={true}
          backToUrl="/user/profile"
        >
          <Input
            label="Current Password"
            type="password"
            name="password"
            hasError={password.hasError}
            error={password.error}
            required={true}
            changeHandler={setPassword}
            fullWidth
            variant="outlined"
          />
          <Input
            label="New Password"
            type="password"
            name="newPassword"
            hasError={newPassword.hasError}
            error={newPassword.error}
            required={true}
            minLen={6}
            maxLen={20}
            changeHandler={setNewPassword}
            fullWidth
            variant="outlined"
          />
          <Input
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            hasError={confirmPassword.hasError}
            error={confirmPassword.error}
            required={true}
            minLen={6}
            maxLen={20}
            changeHandler={setConfirmPassword}
            fullWidth
            variant="outlined"
          />
        </Form>
      )}
      {result.status === false && (
        <FormResult
          severity="error"
          resultMessage={result.message}
          primaryText="Try Again"
          primaryAction={() => {
            setResult({
              status: null,
              message: "",
            });
          }}
          secondaryText="View Profile"
          secondaryAction={() => {
            history(backToUrl);
          }}
        />
      )}
      {result.status === true && (
        <FormResult
          severity="success"
          resultMessage={result.message}
          primaryText="View Profile"
          primaryAction={() => {
            history(backToUrl);
          }}
        />
      )}
    </UserPageLayout>
  );
};

export default UpdatePassword;
