import React, { useEffect, useState, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { FirebaseAuth, Firestore } from "../FirebaseAuth/firebase";
import UserMenu from "../menus/UserMenu";
import AppMenu from "../menus/AppMenu";
import PublicTemplate from "./PublicTemplate";
import Loader from "../Loader";
import { AuthContext } from "../FirebaseAuth";
import AccountMenu from "../menus/AccountMenu";
import Layout from "../Layout";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  setLocalStorage,
} from "../../utils/localStorage";
import { doc, getDoc, collection } from "firebase/firestore"; // Import Firestore functions

const AccountTemplate = ({ role, allowInactive, children }) => {
  const { accountId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setUserData, authUser } = useContext(AuthContext);
  const [isActive, setIsActive] = useState(false);

  allowInactive = allowInactive || false;

  useEffect(() => {
    const fetchAccountData = async () => {
      setLoading(true);
      try {
        let account = {};
        
        // Get account document
        const accountRef = doc(Firestore, "accounts", accountId);
        const docSnap = await getDoc(accountRef);

        if (docSnap.exists()) {
          const accountData = docSnap.data();
          
          if (accountData.subscriptionStatus === "active") {
            setIsActive(true);
          }

          account = {
            id: docSnap.id,
            owner: accountData.owner,
            name: accountData.name,
            accountType: accountData.accountType,
            planId: accountData.plan?.id || null,
            price: accountData.price,
            currency: accountData.currency,
            paymentCycle: accountData.paymentCycle,
            subscriptionStatus: accountData.subscriptionStatus,
            subscriptionCurrentPeriodEnd: accountData.subscriptionCurrentPeriodEnd,
            role: accountData.admins?.includes(authUser.user.uid) ? "admin" : "user",
          };

          setLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT, account);

          // Check user permissions
          const userRef = doc(Firestore, "users", authUser.user.uid);
          const userSnap = await getDoc(userRef);

          if (
            account.role !== role &&
            role !== "*" &&
            !userSnap.data()?.role?.includes("kwallAdmin")
          ) {
            setError("Permission denied.");
          }
          
          setLoading(false);
        } else {
          setError("Invalid account.");
          setLoading(false);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAccountData();
  }, [accountId, setUserData, role, authUser]);

  return (
    <>
      {loading ? (
        <PublicTemplate>
          <Loader text="Loading..." />
        </PublicTemplate>
      ) : (
        <>
          {error === null ? (
            isActive || (!isActive && allowInactive) ? (
              <Layout
                drawerMenu={isActive ? <AccountMenu /> : <AppMenu />}
                toolBarMenu={<UserMenu />}
              >
                {children}
              </Layout>
            ) : (
              <Navigate to={"/account/" + accountId + "/sites"}></Navigate>
            )
          ) : (
            <PublicTemplate>{error}</PublicTemplate>
          )}
        </>
      )}
    </>
  );
};

export default AccountTemplate;
