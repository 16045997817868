import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useCallback, useEffect, useState } from "react";
import { CloudFunctions, Firestore } from "../FirebaseAuth/firebase";
import { useParams } from "react-router-dom";
import {
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
} from "../../utils/localStorage";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: 400,
    border: "2px solid #B8B8B8",
    boxSizing: "border-box",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyling: {
    color: "#0A79A6",
    fontSize: 22,
    fontWeight: 400,
  },
  primaryText: {
    color: "#1E1E1E",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: 400,
    textAlign: "center",
  },
  secondaryText: {
    color: "#6A6A6A",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
  },
  btnReDeploy: {
    marginTop: "12px",
  },
}));

const SiteSetupCard = ({ env }) => {
  const classes = useStyles();
  const params = useParams();
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const [siteData, setSiteData] = useState();
  const [loading, setLoading] = useState(false);

  // const fetchSiteData = useCallback(async () => {
  //   const siteRef = await Firestore.collection(
  //     `/accounts/${params.accountId}/sites`
  //   )
  //     .doc(params.siteId)
  //     .get();
  //   setSiteData({ id: params.siteId, ...siteRef.data() });
  // }, [params.accountId, params.siteId]);

  const fetchSiteData = useCallback(async () => {
    const siteRef = doc(
      Firestore,
      `accounts/${params.accountId}/sites`,
      params.siteId
    );
    const siteSnap = await getDoc(siteRef);

    if (siteSnap.exists()) {
      setSiteData({ id: params.siteId, ...siteSnap.data() });
    }
  }, [params.accountId, params.siteId]);

  // const handleClickReDeploy = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     if (env === "pending") {
  //       if (siteData.pantheonSiteId) {
  //         const pantheonSiteDeployed = httpsCallable(CloudFunctions,
  //           "kwallCloud-pantheonSiteDeployed"
  //         );
  //         await pantheonSiteDeployed({
  //           siteId: siteData.pantheonSiteId,
  //           slug: siteData.slug,
  //         });
  //       } else {
  //         const createDeploySite = httpsCallable(CloudFunctions,
  //           "kwallCloud-createDeploySite"
  //         );
  //         await createDeploySite({
  //           adminAccountEmail: siteData.adminAccountEmail,
  //           siteType: siteData.siteType,
  //           status: siteData.status,
  //           siteName: siteData.siteName,
  //           slug: siteData.slug,
  //           accountId: params.accountId,
  //           id: params.siteId,
  //           templateType: siteData.templateType,
  //           siteTemplateId: siteData.siteTemplateId,
  //           templateId: siteData.templateId,
  //           planId: siteData.plan.id,
  //         });
  //       }
  //     } else if (env === "pending-test") {
  //       const pantheonSiteTest = httpsCallable(CloudFunctions,
  //         "kwallCloud-pantheonSiteTest"
  //       );
  //       await pantheonSiteTest({
  //         siteId: params.siteId,
  //         accountId: params.accountId,
  //         slug: siteData.slug,
  //       });
  //     } else if (env === "pending-production") {
  //       const pantheonSiteLive = httpsCallable(CloudFunctions,
  //         "kwallCloud-pantheonSiteLive"
  //       );
  //       await pantheonSiteLive({
  //         siteId: params.siteId,
  //         accountId: params.accountId,
  //         slug: siteData.slug,
  //       });
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // }, [env, params.accountId, params.siteId, siteData]);

  const handleClickReDeploy = useCallback(async () => {
    setLoading(true);
    try {
      if (env === "pending") {
        if (siteData.pantheonSiteId) {
          const pantheonSiteDeployed = httpsCallable(
            CloudFunctions,
            "kwallCloud-pantheonSiteDeployed"
          );
          await pantheonSiteDeployed({
            siteId: siteData.pantheonSiteId,
            slug: siteData.slug,
          });
        } else {
          const createDeploySite = httpsCallable(
            CloudFunctions,
            "kwallCloud-createDeploySite"
          );
          await createDeploySite({
            adminAccountEmail: siteData.adminAccountEmail,
            siteType: siteData.siteType,
            status: siteData.status,
            siteName: siteData.siteName,
            slug: siteData.slug,
            accountId: params.accountId,
            id: params.siteId,
            templateType: siteData.templateType,
            siteTemplateId: siteData.siteTemplateId,
            templateId: siteData.templateId,
            planId: siteData.plan.id,
          });
        }
      } else if (env === "pending-test") {
        const pantheonSiteTest = httpsCallable(
          CloudFunctions,
          "kwallCloud-pantheonSiteTest"
        );
        await pantheonSiteTest({
          siteId: params.siteId,
          accountId: params.accountId,
          slug: siteData.slug,
        });
      } else if (env === "pending-production") {
        const pantheonSiteLive = httpsCallable(
          CloudFunctions,
          "kwallCloud-pantheonSiteLive"
        );
        await pantheonSiteLive({
          siteId: params.siteId,
          accountId: params.accountId,
          slug: siteData.slug,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Redeployment error:", err);
    }
  }, [env, params.accountId, params.siteId, siteData]);

  useEffect(() => {
    fetchSiteData();
  }, [fetchSiteData]);

  return (
    <Box className={classes.container}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Box>
            <HourglassEmptyIcon className={classes.iconStyling} />
          </Box>
        </Grid>
        <Grid item>
          <Box className={classes.primaryText}>Your site is being Set Up</Box>
        </Grid>
        <Grid item>
          <Box className={classes.secondaryText}>
            This usually takes 5-10 minutes, check back here
          </Box>
        </Grid>
        {currentUser?.role?.includes("kwallAdmin") && (
          <Grid item>
            <Button
              variant="contained"
              className={classes.btnReDeploy}
              onClick={handleClickReDeploy}
            >
              {loading ? (
                <CircularProgress
                  sx={{ color: "#FFF" }}
                  size={22}
                  variant="indeterminate"
                />
              ) : (
                "Re-Deploy"
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SiteSetupCard;
