import React, { useContext, useEffect } from "react";
import { Route, Navigate, withRouter } from "react-router-dom";
import { AuthContext } from "../../FirebaseAuth";
import PublicTemplate from "../../templates/PublicTemplate";
import Loader from "../../Loader";
import {
  LOCAL_STORAGE_INVITE_CODE,
  setLocalStorage,
} from "../../../utils/localStorage";

const InviteRouter = ({
  component: Component,
  template: Template,
  title: Title,
  children,
  ...rest
}) => {
  document.title = Title;

  const { authUser } = useContext(AuthContext);
  const currentUrl = window.location.href;

  useEffect(() => {
    const inviteCode = currentUrl.split("/invite/")?.[1];
    if (inviteCode) {
      setLocalStorage(LOCAL_STORAGE_INVITE_CODE, inviteCode);
    }
  }, [currentUrl]);

  return (
    <>
        {authUser.checked ? (
          !!authUser.user ? (
            <Template {...rest}>
              <Component/>
            </Template>
          ) : (
            <Navigate to={"/welcome"} />
          )
        ) : (
          <PublicTemplate>
            <Loader text="Loading, please wait..." />
          </PublicTemplate>
        )}
        </>
  );
};
export default InviteRouter;
