import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Container,
  Paper,
  Box,
  Alert,
  Grid,
  Avatar,
  Stack,
  IconButton,
  Typography,
  Input,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { makeStyles } from "@mui/styles";

import {
  FirebaseStorage,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { organizationFormErrors } from "../../../../utils/constants";
import "./index.css";
import { OrganizationForm } from "../OrganizationForm/OrganizationForm";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStorage";
import Loader from "../../../../components/Loader";
import { isKWALLAdmin } from "../../../../utils";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 130,
    height: 130,
    marginBottom: 24,
  },
  uploadInput: {
    display: "none",
  },
}));

const initialOrgData = {
  name: "",
  description: "",
  accountType: "",
  acceptTerms: false,
  access: [],
  accounts: [],
  admins: [],
  organizationCode: "",
  primaryDomain: "",
  autoEnroll: false,
  imageUrl: "",
  pantheonOrgID: "",
  machineToken: "",
  primaryFont: "",
  secondaryFont: "",
  tertiaryFont: "",
};

const UpdateOrganization = () => {
  const history = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [errors, setErrors] = useState();
  const [inSubmit, setInSubmit] = useState(false);
  const [organizationData, setOrganizationData] = useState(initialOrgData);
  const [isUploading, setIsUploading] = useState(false);

  // const fetchSingleAccount = useCallback(async () => {
  //   try {
  //     const organizationRef = await Firestore.collection("organizations")
  //       .doc(params.organizationId)
  //       .get();
  //     setOrganizationData(organizationRef.data());
  //   } catch (ex) {
  //     console.log("____ ex", ex);
  //   }
  // }, [params]);

  const fetchSingleAccount = useCallback(async () => {
    try {
      const organizationRef = doc(Firestore, "organizations", params.organizationId);
      const organizationSnapshot = await getDoc(organizationRef);
      if (organizationSnapshot.exists()) {
        setOrganizationData(organizationSnapshot.data());
      } else {
        console.log("No such organization!");
      }
    } catch (ex) {
      console.log("____ ex", ex);
    }
  }, [params]);

  const handleUpdate = useCallback(async () => {
    setInSubmit(true);
    if (!organizationData.name) {
      setErrors(organizationFormErrors.name.message);
      return;
    }
    if (!organizationData.description) {
      setErrors(organizationFormErrors.description.message);
      return;
    }
    if (!organizationData.accountType) {
      setErrors(organizationFormErrors.accountType.message);
      return;
    }
    if (!organizationData.organizationCode) {
      setErrors(organizationFormErrors.organizationCode.message);
      return;
    }
    // try {
    //   const organizationRef = await Firestore.collection("organizations").doc(
    //     params.organizationId
    //   );
    //   await organizationRef.set(organizationData);
    //   setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, {
    //     ...organizationData,
    //     id: params.organizationId,
    //   });
    //   setInSubmit(false);
    //   history("/");
    //   return;
    // } catch (ex) {
    //   console.log("____ ex", ex);
    // }
    try {
      const organizationRef = doc(Firestore, "organizations", params.organizationId);
      await setDoc(organizationRef, organizationData, { merge: true });
      setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, {
        ...organizationData,
        id: params.organizationId,
      });
      setInSubmit(false);
      history("/");
      return;
    } catch (ex) {
      console.log("____ ex", ex);
    }
  }, [organizationData, history, params]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    if (name === "name" && value.length > 100) return;
    if (name === "description" && value.length > 200) return;
    if (name === "organizationCode" && value.length > 4) return;
    setOrganizationData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  // const handleUpload = async (image) => {
  //   try {
  //     const downloadUrl = await FirebaseStorage.ref(
  //       `organizations/${image.name}`
  //     ).put(image);
  //     setIsUploading(false);
  //     return downloadUrl.ref.getDownloadURL();
  //   } catch (ex) {
  //     console.log("ex", ex);
  //   }
  // };

  const handleUpload = async (image) => {
    try {
      const storageRef = ref(FirebaseStorage, `organizations/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      await uploadTask;
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      return downloadUrl;
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleChangeImage = useCallback(async ({ target: { files } }) => {
    if (!!files[0]) {
      setIsUploading(true);
      const uploadUrl = await handleUpload(files[0]);
      setOrganizationData((prevOrg) => ({ ...prevOrg, imageUrl: uploadUrl }));
      setIsUploading(false);
    }
  }, []);

  useEffect(() => {
    const storageUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
    const path = isKWALLAdmin(storageUser?.role)
      ? "/organization_list"
      : "/organization/switch-organization";
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: path,
        text: "Organizations",
        active: false,
      },
      {
        to: null,
        text: "Update Organization",
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    if (!!params.organizationId) {
      fetchSingleAccount();
    }
  }, [params, fetchSingleAccount]);

  return (
    <Container>
      <Paper>
        <Grid container>
          <Grid item xs={12} md={3} className={classes.imageContainer}>
            <Avatar
              alt={organizationData.imageUrl}
              src={organizationData.imageUrl}
              className={classes.image}
            />
            <Stack>
              {isUploading ? (
                <Loader text="Uploading..." />
              ) : (
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    name="image"
                    type="file"
                    onChange={handleChangeImage}
                    className={classes.uploadInput}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudUploadIcon />
                    <Box>
                      <Typography ml={1}>Update Image</Typography>
                    </Box>
                  </IconButton>
                </label>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box p={2}>
              {errors && (
                <Alert
                  severity="error"
                  dismissible={true}
                  onDismiss={() => setErrors(null)}
                >
                  {errors}
                </Alert>
              )}
              <div className="card-body">
                <OrganizationForm
                  handleSubmit={handleUpdate}
                  organizationData={organizationData}
                  inSubmit={inSubmit || isUploading}
                  handleChange={handleChange}
                  userData={currentUser}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UpdateOrganization;
