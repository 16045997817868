import React from "react";
import { createRoot } from "react-dom/client";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider, CssBaseline } from '@mui/material';
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A79A6",
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#4884fa',
    //   dark: '#3b5988',
    //   contrastText: '#ffffff',
    // },
    // typography: {
    //   fontSize: '1.2rem',
    //   fontFamily: ['Montserrat', 'sans-serif'].join(','),
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
});

// Access root element in DOM
const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // New createRoot API

// Render App wrapped with ThemeProvider and CssBaseline
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
