import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import {
  FirebaseAuth,
  Firestore,
} from "../../../components/FirebaseAuth/firebase";
import { PlanItemCard } from "../../../components/Plans/PlanItemCard";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { getFirestore, collection, query, orderBy, getDocs, doc, deleteDoc } from "firebase/firestore";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
}));

const PlanLists = () => {
  const history = useNavigate();
  const classes = useStyles();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickAdd = useCallback(() => {
    history("/planLists/create");
  }, [history]);

  // const getPlanLists = useCallback(() => {
  //   const planQuery = FirebaseAuth.firestore()
  //     .collection("plans")
  //     .orderBy("position", "asc");
  //   let temp = [];
  //   setIsLoading(true);
  //   planQuery.get().then((planSnapShots) => {
  //     planSnapShots.forEach((doc) => {
  //       temp.push({
  //         id: doc.id,
  //         name: doc.data().name,
  //         htmlDescription: doc.data().htmlDescription,
  //         excludeCustomerID: doc.data().excludeCustomerID,
  //         order: doc.data().order,
  //         price: doc.data().price,
  //         description: doc.data().description,
  //         currency: doc.data().currency,
  //         paymentCycle: doc.data().paymentCycle,
  //         features: doc.data().features,
  //         stripePriceId: doc.data().stripePriceId,
  //       });
  //     });
  //     setPlans(temp);
  //     setIsLoading(false);
  //   });
  // }, []);
  const getPlanLists = useCallback(async () => {
    const planQuery = query(collection(Firestore, "plans"), orderBy("position", "asc"));
  
    setIsLoading(true);
    try {
      const planSnapShots = await getDocs(planQuery);
      const temp = planSnapShots.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        htmlDescription: doc.data().htmlDescription,
        excludeCustomerID: doc.data().excludeCustomerID,
        order: doc.data().order,
        price: doc.data().price,
        description: doc.data().description,
        currency: doc.data().currency,
        paymentCycle: doc.data().paymentCycle,
        features: doc.data().features,
        stripePriceId: doc.data().stripePriceId,
      }));
      setPlans(temp);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // const handleDeletePlan = useCallback(
  //   async (id) => {
  //     try {
  //       await Firestore.collection("plans").doc(id).delete();
  //       getPlanLists();
  //       toast.success("Deleted Successfully");
  //     } catch (ex) {
  //       toast.error("Something went wrong");
  //     }
  //   },
  //   [getPlanLists]
  // );

  const handleDeletePlan = useCallback(
    async (id) => {
      const firestore = getFirestore();
      try {
        await deleteDoc(doc(firestore, "plans", id)); // Delete the specific document by ID
        await getPlanLists(); // Refresh the plan list
        toast.success("Deleted Successfully");
      } catch (error) {
        console.error("Error deleting plan:", error);
        toast.error("Something went wrong");
      }
    },
    [getPlanLists]
  );

  const handleDelete = useCallback(
    (id) => {
      confirmAlert({
        title: "Are you sure?",
        message: "Are you sure you want to delete this? This cannot be undone.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => handleDeletePlan(id),
          },
          {
            label: "No",
            onClick: () => alert("Click No"),
          },
        ],
      });
    },
    [handleDeletePlan]
  );

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: "Plans",
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    getPlanLists();
  }, [getPlanLists]);

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="right"
        paddingBottom={2}
      >
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleClickAdd}
        >
          <Box>Create New Plan</Box>
        </Button>
      </Box>
      {!isLoading &&
        plans.length > 0 &&
        plans.map((plan) => {
          return (
            <PlanItemCard
              plan={plan}
              key={plan.id}
              handleDelete={() => handleDelete(plan.id)}
            />
          );
        })}
    </Container>
  );
};

export default PlanLists;
