// import { Box, Button, Typography } from "@mui/material";
// import React, { memo, useCallback, useState } from "react";
// // import firebase from "firebase/app";
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { GoogleAuthProvider } from "firebase/auth";

// import { Email } from "@mui/icons-material";
// import { Outlet, useNavigate } from "react-router-dom";
// import { userSignIn } from "../../../libs/user";
// import {
//   LOCAL_STORAGE_INVITE_CODE,
//   getLocalStorage,
// } from "../../../utils/localStorage";
// import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";

// const Welcome = memo(() => {
//   const history = useNavigate();
//   const [errors, setErrors] = useState();

//   const handleLoginWithGoogle = useCallback(
//     async (event) => {
//       event.preventDefault();
//       // const googleProvider = FirebaseAuth.GoogleAuthProvider();
//       const googleProvider = new GoogleAuthProvider();
//       googleProvider.setCustomParameters({ prompt: "select_account" });
//       try {
//         const result = await FirebaseAuth().signInWithPopup(googleProvider);
//         await FirebaseAuth().signInWithCredential(result.credential);
//         userSignIn((res) => {
//           if (res) {
//             const inviteCode = getLocalStorage(LOCAL_STORAGE_INVITE_CODE);
//             if (inviteCode) {
//               history(`/invite/${inviteCode}`);
//             } else {
//               history("/");
//             }
//           }
//         });
//       } catch (error) {
//         setErrors(error.message);
//       }
//     },
//     [history]
//   );
//   return (
//     <Box
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "16px",
//         alignItems: "center",
//         margin: "24px 0",
//       }}
//     >
//       <Typography variant="h6">
//         Please sign in to login or create your account.
//       </Typography>
//       <Button
//         variant="contained"
//         startIcon={<Email />}
//         color="error"
//         onClick={() => history("/signin")}
//         style={{
//           textTransform: "none",
//           width: "220px",
//           justifyContent: "flex-start",
//           gap: "16px",
//         }}
//       >
//         Sign in with email
//       </Button>
//       <Button
//         variant="contained"
//         startIcon={
//           <img
//             alt="Google"
//             src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
//             style={{ width: "18px" }}
//           />
//         }
//         style={{
//           textTransform: "none",
//           width: "220px",
//           backgroundColor: "white",
//           color: "#757575",
//           justifyContent: "flex-start",
//           gap: "16px",
//         }}
//         onClick={(event) => handleLoginWithGoogle(event)}
//       >
//         Sign in with Google
//       </Button>
//       {errors && (
//         <Typography variant="body2" style={{ color: "#d32f2f" }}>
//           {errors}
//         </Typography>
//       )}
//     </Box>
//   );
// });

// export default Welcome;
import { Box, Button, Typography } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { Email } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { userSignIn } from "../../../libs/user";
import {
  LOCAL_STORAGE_INVITE_CODE,
  getLocalStorage,
} from "../../../utils/localStorage";
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const Welcome = memo(() => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);

  const handleLoginWithGoogle = useCallback(
    async (event) => {
      event.preventDefault();
      const googleProvider = new GoogleAuthProvider();
      googleProvider.setCustomParameters({ prompt: "select_account" });
      try {
        const result = await signInWithPopup(FirebaseAuth, googleProvider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          userSignIn((res) => {
            if (res) {
              const inviteCode = getLocalStorage(LOCAL_STORAGE_INVITE_CODE);
              navigate(inviteCode ? `/invite/${inviteCode}` : "/");
            }
          });
        }
      } catch (error) {
        setErrors(error.message);
      }
    },
    [navigate]
  );
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center",
        margin: "24px 0",
      }}
    >
      <Typography variant="h6" align="center">
        Please sign in to login or create your account.
      </Typography>
      <Button
        variant="contained"
        startIcon={<Email />}
        color="error"
        onClick={() => navigate("/signin")}
        style={{
          textTransform: "none",
          width: "220px",
          justifyContent: "flex-start",
          gap: 2,
        }}
        aria-label="Sign in with email"
      >
        Sign in with email
      </Button>
      <Button
        variant="contained"
        startIcon={
          <img
            alt="Google"
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            style={{ width: "18px" }}
          />
        }
        style={{
          textTransform: "none",
          width: "220px",
          backgroundColor: "white",
          color: "#757575",
          justifyContent: "flex-start",
          gap: 2,
        }}
        onClick={handleLoginWithGoogle}
        aria-label="Sign in with Google"
      >
        Sign in with Google
      </Button>
      {errors && (
        <Typography variant="body2" style={{ color: "#d32f2f" }} color="error">
          {errors}
        </Typography>
      )}
    </Box>
  );
});

export default Welcome;
