import React, { useState, useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../FirebaseAuth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LanguageIcon from "@mui/icons-material/Language";
import WebIcon from "@mui/icons-material/Web";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import { Firestore } from "../../../components/FirebaseAuth/firebase";
import { isKWALLAdmin, isAdmin } from "../../../utils/index";
import { getDoc, doc } from "firebase/firestore";
import "./index.css";

const AccountMenu = () => {
  const selectedStyle = {
    color: " #1F9DC1",
  };

  const textDecorationStyle = {
    textDecoration: "none",
  };

  const initialUserState = {
    isLoading: true,
    data: null,
  };
  const [userData, setUserData] = useState(initialUserState);
  const { accountId } = useParams();
  const { pathname } = useLocation();
  const { authUser, isMyTemplate } = useContext(AuthContext);

  async function getUserData() {
    try {
      // const refUser = await Firestore.collection("users")
      //   .doc(authUser.user.uid)
      //   .get();
      // const userData = await refUser.data();

      const userDocRef = doc(Firestore, "users", authUser.user.uid);
      const refUser = await getDoc(userDocRef);
      const userData = await refUser.data();
      setUserData({
        data: userData,
        isLoading: false,
      });
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }

  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.querySelectorAll(".c-sidebar").forEach((element) => {
      window.coreui.Sidebar._sidebarInterface(element);
    });
  });

  if (userData.isLoading) return null;

  return (
    <List className={"mobile-header"}>
      <Link to={"/"} style={textDecorationStyle}>
        <ListItem button key="Users">
          <ListItemIcon>
            <DashboardIcon
              style={!pathname ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="textPrimary" fontSize={12}>
                Home
              </Typography>
            }
          />
        </ListItem>
      </Link>
      <Link to={`/account/${accountId}/sites`} style={textDecorationStyle}>
        <ListItem button key="Sites">
          <ListItemIcon>
            <LanguageIcon
              style={pathname.includes("sites") ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="textPrimary" fontSize={12}>
                Sites
              </Typography>
            }
          />
        </ListItem>
      </Link>
      {(isAdmin(userData.data.role) || isKWALLAdmin(userData.data.role)) && (
        <Link
          to={`/account/${accountId}/also-templates`}
          style={textDecorationStyle}
        >
          <ListItem button key="Sites">
            <ListItemIcon>
              <MenuBookIcon
                style={pathname.includes("also") ? selectedStyle : null}
                className="icon-size"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" fontSize={12}>
                  Templates
                </Typography>
              }
            />
          </ListItem>
        </Link>
      )}
      {(isAdmin(userData.data.role) || isKWALLAdmin(userData.data.role)) &&
        isMyTemplate.isMenuOpen &&
        isMyTemplate.status && (
          <Link
            to={`/account/${accountId}/templates`}
            style={textDecorationStyle}
          >
            <ListItem button key="Users">
              <ListItemIcon>
                <WebIcon
                  style={pathname.includes("templates") ? selectedStyle : null}
                  className="icon-size"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" fontSize={12}>
                    Templates
                  </Typography>
                }
              />
            </ListItem>
          </Link>
        )}
      <Link to={`/account/${accountId}/users`} style={textDecorationStyle}>
        <ListItem button key="Users">
          <ListItemIcon>
            <PeopleAltIcon
              style={pathname.includes("users") ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="textPrimary" fontSize={12}>
                Users
              </Typography>
            }
          />
        </ListItem>
      </Link>
    </List>
  );
};

export default AccountMenu;
