import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import {
  FirebaseAuth,
  FirebaseStorage,
  Firestore
} from "../../../../components/FirebaseAuth/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { serviceFormErrors, serviceTypes } from "../../../../utils/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { collection, doc, setDoc, getDoc, getDocs, query, where, orderBy } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    padding: 24,
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 130,
    height: 130,
    marginBottom: 24,
  },
  uploadInput: {
    display: "none",
  },
  textField: {
    margin: "12px 0",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 180,
    justifyContent: "space-between",
    marginTop: 24,
  },
}));

const initialService = {
  name: "",
  description: "",
  type: "",
  provider: "",
  link: "",
  showOrganizations: [],
  excludeOrganizations: [],
  showAccounts: [],
  excludeAccounts: [],
  estimatedTime: "",
  lowestPrice: 0,
  price: "",
};

const EditService = () => {
  const history = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [service, setService] = useState(initialService);
  const [organizations, setOrganizations] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [errors, setErrors] = useState();
  const [isExpert, setIsExpert] = useState(false);

  // const getServiceData = useCallback(async () => {
  //   const serviceRef = await FirebaseAuth.firestore()
  //     .collection("services")
  //     .doc(params.serviceId)
  //     .get();
  //   setService(serviceRef.data());
  //   setIsExpert(!!serviceRef.data().isExpert);
  // }, [params]);

  const getServiceData = useCallback(async () => {
    const serviceRef = doc(Firestore, "services", params.serviceId);
    const serviceSnapshot = await getDoc(serviceRef);
    
    if (serviceSnapshot.exists()) {
      setService(serviceSnapshot.data());
      setIsExpert(!!serviceSnapshot.data().isExpert);
    } else {
      console.error("No such service!");
    }
  }, [params]);

  // const getOrganizationData = useCallback(async () => {
  //   let temp = [];
  //   const organizationSnapShots = await FirebaseAuth.firestore()
  //     .collection("organizations")
  //     .orderBy("name", "asc")
  //     .get();
  //   organizationSnapShots.forEach((doc) => {
  //     temp.push({ ...doc.data(), id: doc.id });
  //   });
  //   setOrganizations(temp);
  // }, []);
  const getOrganizationData = useCallback(async () => {
    let temp = [];
    const organizationQuery = query(
      collection(Firestore, "organizations"),
      orderBy("name", "asc")
    );
    const organizationSnapShots = await getDocs(organizationQuery);
    organizationSnapShots.forEach((doc) => {
      temp.push({ ...doc.data(), id: doc.id });
    });
    setOrganizations(temp);
  }, []);

  // const getAccountsData = useCallback(async () => {
  //   let temp = [];
  //   const accountSnapShots = await FirebaseAuth.firestore()
  //     .collection("accounts")
  //     .orderBy("name", "asc")
  //     .get();
  //   accountSnapShots.forEach((doc) => {
  //     temp.push({ ...doc.data(), id: doc.id });
  //   });
  //   setAccounts(temp);
  // }, []);
  const getAccountsData = useCallback(async () => {
    let temp = [];
    const accountQuery = query(
      collection(Firestore, "accounts"),
      orderBy("name", "asc")
    );
    const accountSnapShots = await getDocs(accountQuery);
    accountSnapShots.forEach((doc) => {
      temp.push({ ...doc.data(), id: doc.id });
    });
    setAccounts(temp);
  }, []);

  // const handleUpload = async (image) => {
  //   try {
  //     const downloadUrl = await FirebaseStorage.ref(
  //       `services/${image.name}`
  //     ).put(image);
  //     return downloadUrl.ref.getDownloadURL();
  //   } catch (ex) {
  //     console.log("ex", ex);
  //   }
  // };

  const handleUpload = async (image) => {
    try {
      const storageRef = ref(FirebaseStorage, `services/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      await uploadTask;
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      return downloadUrl;
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleChangeImage = useCallback(async ({ target: { files } }) => {
    if (!!files[0]) {
      const uploadUrl = await handleUpload(files[0]);
      setService((prevService) => ({ ...prevService, imageUrl: uploadUrl }));
    }
  }, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      if (name === "name" && value.length > 100) return;
      if (name === "description" && value.length > 200) return;
      setService({ ...service, [name]: value });
    },
    [service]
  );

  const handleBlur = useCallback(
    ({ target: { name, value } }) => {
      let isError = false;
      let message = "";
      if (value.trim().length < 1) {
        isError = true;
        message = serviceFormErrors[name]?.message;
      } else {
        isError = false;
        message = "";
      }
      setErrors({ ...errors, [name]: { isError: isError, message: message } });
    },
    [errors, setErrors]
  );

  const handleEdit = useCallback(async () => {
    if (!service.name) {
      setErrors({
        ...errors,
        name: { isError: true, message: serviceFormErrors.name.message },
      });
      return;
    }

    if (!service.description) {
      setErrors({
        ...errors,
        description: {
          isError: true,
          message: serviceFormErrors.description.message,
        },
      });
      return;
    }

    if (!service.provider) {
      setErrors({
        ...errors,
        provider: {
          isError: true,
          message: serviceFormErrors.provider.message,
        },
      });
    }

    if (!service.type) {
      setErrors({
        ...errors,
        type: { isError: true, message: serviceFormErrors.type.message },
      });
      return;
    }

    // try {
    //   const serviceRef = FirebaseAuth.firestore()
    //     .collection("services")
    //     .doc(params.serviceId);
    //   await serviceRef.set({ ...service, isExpert });
    //   toast.success("Added Successfully");
    //   history.goBack();
    // } catch (error) {
    //   toast.error("Something went wrong");
    // }
    try {
      const serviceRef = doc(Firestore, "services", params.serviceId);
      await setDoc(serviceRef, { ...service, isExpert });
      toast.success("Updated Successfully");
      history(-1);
    } catch (error) {
      toast.error("Something went wrong");
    }
  }, [errors, service, history, params, isExpert]);

  const handleCancel = useCallback(() => {
    history(-1);
  }, [history]);

  useEffect(() => {
    getServiceData();
    getOrganizationData();
    getAccountsData();
  }, [params, getServiceData, getOrganizationData, getAccountsData]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/serviceLists",
        text: "Services",
        active: false,
      },
      {
        to: null,
        text: `Edit Service`,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  return (
    <Paper className={classes.container}>
      <Box className={classes.title}>Edit Service</Box>
      <Grid container>
        <Grid item xs={12} md={3} className={classes.imageContainer}>
          <Avatar
            alt={service.imageUrl}
            src={service.imageUrl}
            className={classes.image}
          />
          <Stack>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                name="image"
                type="file"
                onChange={handleChangeImage}
                className={classes.uploadInput}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <CloudUploadIcon />
                <Box>
                  <Typography ml={1}>Update Image</Typography>
                </Box>
              </IconButton>
            </label>
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box>
            <TextField
              label="Service Name*"
              name="name"
              value={service.name}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.name?.isError}
              helperText={errors?.name?.message}
              className={classes.textField}
            />
            <TextField
              label="Service Description*"
              name="description"
              value={service.description}
              fullWidth
              multiline
              minRows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.description?.isError}
              helperText={errors?.description?.message}
              className={classes.textField}
            />
            <TextField
              label="Service Provider*"
              name="provider"
              value={service.provider}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.provider?.isError}
              helperText={errors?.provider?.message}
              className={classes.textField}
            />
            <FormControl fullWidth className={classes.textField}>
              <InputLabel id="type">Service Type*</InputLabel>
              <Select
                labelId="type"
                name="type"
                onChange={handleChange}
                value={service.type}
                label="Service Type*"
              >
                {serviceTypes.map((item) => (
                  <MenuItem key={item.value} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.textField}>
              <InputLabel id="showOrganizations">Show Organizations</InputLabel>
              <Select
                labelId="showOrganizations"
                name="showOrganizations"
                onChange={handleChange}
                value={service.showOrganizations || []}
                label="Show Organizations"
                multiple
              >
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.textField}>
              <InputLabel id="excludeOrganizations">
                Exclude Organizations
              </InputLabel>
              <Select
                labelId="excludeOrganizations"
                name="excludeOrganizations"
                onChange={handleChange}
                value={service.excludeOrganizations || []}
                label="Exclude Organizations"
                multiple
              >
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.textField}>
              <InputLabel id="showAccounts">Show Accounts</InputLabel>
              <Select
                labelId="showAccounts"
                name="showAccounts"
                onChange={handleChange}
                value={service.showAccounts || []}
                label="Show Accounts"
                multiple
              >
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.textField}>
              <InputLabel id="excludeAccounts">Exclude Accounts</InputLabel>
              <Select
                labelId="excludeAccounts"
                name="excludeAccounts"
                onChange={handleChange}
                value={service.excludeAccounts || []}
                label="Exclude Accounts"
                multiple
              >
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Service Url"
              name="link"
              value={service.link}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              required={false}
              className={classes.textField}
            />
            <TextField
              label="Estimated Time"
              name="estimatedTime"
              value={service.estimatedTime}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              required={false}
              className={classes.textField}
            />
            <TextField
              label="Price"
              name="price"
              value={service.price}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              required={false}
              className={classes.textField}
            />
            <TextField
              label="Lowest Price"
              name="lowestPrice"
              type="number"
              value={service.lowestPrice || 0}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              required={false}
              className={classes.textField}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isExpert}
                  onChange={(_, checked) => setIsExpert(checked)}
                  sx={{ padding: "0 4px" }}
                />
              }
              label="This is expert service."
              labelPlacement="end"
              sx={{ marginTop: "2px" }}
            />
          </Box>
          <Box className={classes.btnContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              className={classes.button}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EditService;
