import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
// import firebase from "firebase/app";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import Loader from "../../../components/Loader";
import { userSignIn } from "../../../libs/user";
import {
  LOCAL_STORAGE_INVITE_CODE,
  getLocalStorage,
} from "../../../utils/localStorage";
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";

export const SignIn = memo(() => {
  const history = useNavigate();
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});
  const [sentEmail, setSentEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const schema = object().shape({
    email: string().required("Email is required").email("Invalid email"),
  });

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await schema.validate({ email }, { abortEarly: false });
        const actionCodeSettings = {
          url: "https://sites.accorbis.com/signin",
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(FirebaseAuth, email, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", email);
        setErrors({});
        setSentEmail(true);
      } catch (error) {
        if (error.inner) {
          const newErrors = {};
          error.inner?.forEach((fieldError) => {
            newErrors[fieldError.path] = fieldError.message;
          });
          setErrors(newErrors);
        } else {
          setErrors({ submit: error.message });
        }
      }
    },
    [email, schema]
  );

  // useEffect(() => {
  //   if (isSignInWithEmailLink(FirebaseAuth, window.location.href)) {
  //     const email = window.localStorage.getItem("emailForSignIn");
  //     signInWithEmailLink(FirebaseAuth, email, window.location.href)
  //       .then((result) => {
  //         if (!!result) {
  //           userSignIn((res) => {
  //             setIsLoading(false);
  //             if (res) {
  //               const inviteCode = getLocalStorage(LOCAL_STORAGE_INVITE_CODE);
  //               if (inviteCode) {
  //                 history(`/invite/${inviteCode}`);
  //               } else {
  //                 history("/");
  //               }
  //             }
  //           });
  //         } else {
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         setErrors({ submit: error });
  //       });
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [history]);


  useEffect(() => {
    const handleSignIn = async () => {
      try {
        if (isSignInWithEmailLink(FirebaseAuth, window.location.href)) {
          const email = window.localStorage.getItem("emailForSignIn");

          if (!email) {
            throw new Error("No email found for sign-in. Please try again.");
          }

          const result = await signInWithEmailLink(FirebaseAuth, email, window.location.href);

          if (result) {
            userSignIn((res) => {
              setIsLoading(false);
              if (res) {
                const inviteCode = getLocalStorage(LOCAL_STORAGE_INVITE_CODE);
                if (inviteCode) {
                  history(`/invite/${inviteCode}`);
                } else {
                  history("/");
                }
              }
            });
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors({ submit: error.message || "Sign-in failed. Please try again." });
      }
    };
    handleSignIn();
  }, [FirebaseAuth, history, userSignIn, setErrors, setIsLoading]);

  return (
    <Box>
      {isLoading ? (
        <Loader text="Authenticating, Please wait..." />
      ) : (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "24px",
            minWidth: "360px",
          }}
          onSubmit={handleSubmit}
        >
          <Typography variant="h6">Please enter your email.</Typography>
          <TextField
            id="email"
            label="Email"
            name="email"
            variant="outlined"
            InputLabelProps={{ style: { backgroundColor: "#EAEEF3" } }}
            onChange={(event) => setEmail(event.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <Button variant="contained" type="submit">
            Sign In
          </Button>
          <Snackbar
            open={sentEmail}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSentEmail(false)}
          >
            <Alert severity="success" onClose={() => setSentEmail(false)}>
              An email with a sign-in link has been sent to your email address.
              Please click on the link to sign in.
            </Alert>
          </Snackbar>
          <Snackbar
            open={errors.submit}
            autoHideDuration={6000}
            onClose={() => setErrors({ submit: null })}
          >
            <Alert severity="error" onClose={() => setErrors({ submit: null })}>
              {errors.submit}
            </Alert>
          </Snackbar>
        </form>
      )}
    </Box>
  );
});
