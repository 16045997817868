import { Box, Card, Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import EnvironmentCreateCard from "../../../../../components/EnvironmentCreateCard";
import EnvironmentDetailsCard from "../../../../../components/EnvironmentDetailsCard";
import SiteSetupCard from "../../../../../components/SiteSetupCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    gap: "0.5rem",
  },
}));

export const SiteEnvironmentCards = ({
  status,
  slug,
  pantheonSiteId,
  onCreateEnvironment,
  handleManageEnvironment,
  setProgressAction,
}) => {
  const classes = useStyles();
  const envData = [
    {
      id: 1,
      name: "Development",
      description:
        "This is your development environment. You can view, test, and review development updates to the codebase",
      domainUrl: `http://dev-${slug}.pantheonsite.io`,
      developerUrl: pantheonSiteId
        ? `https://dashboard.pantheon.io/${pantheonSiteId}#dev/code`
        : "",
      buttonText: "Manage",
      env: "dev",
    },
    {
      id: 2,
      name: "Test",
      description:
        "This is your test site. You can test out code and content here, and it's a great place to review iterative updates to the codebase and content.",
      domainUrl: `http://test-${slug}.pantheonsite.io`,
      developerUrl: "",
      buttonText: "Manage",
      env: "test",
    },
    {
      id: 3,
      name: "Production",
      description: "Manage and write your content here! This is your LIVE site",
      domainUrl: `http://live-${slug}.pantheonsite.io`,
      developerUrl: "",
      buttonText: "Manage",
      env: "live",
    },
  ];

  const [value, setValue] = useState(0);
  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`environment-tabpanel-${index}`}
        aria-labelledby={`environment-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const envTabProps = (index) => {
    return {
      id: `environment-tab-${index}`,
      "aria-controls": `environment-tabpanel-${index}`,
    };
  };

  if (status === "pending") {
    return (
      <Card className={classes.container}>
        <Tabs value={value} onChange={(_, value) => setValue(value)}>
          <Tab label="Development" {...envTabProps(0)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <SiteSetupCard env={status} slug={slug} siteId={pantheonSiteId} />
        </CustomTabPanel>
      </Card>
    );
  }

  if (status === "active") {
    return (
      <Card className={classes.container}>
        <Tabs value={value} onChange={(_, value) => setValue(value)}>
          <Tab label="Development" {...envTabProps(0)} />
          <Tab label="Test" {...envTabProps(1)} />
        </Tabs>
        <Divider />
        <CustomTabPanel value={value} index={0}>
          <EnvironmentDetailsCard
            envObj={envData[0]}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EnvironmentCreateCard
            envName="Test"
            onCreateEnvironment={() => onCreateEnvironment("Test")}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
      </Card>
    );
  }

  if (status === "pending-test") {
    return (
      <Card className={classes.container}>
        <Tabs value={value} onChange={(_, value) => setValue(value)}>
          <Tab label="Development" {...envTabProps(0)} />
          <Tab label="Test" {...envTabProps(1)} />
        </Tabs>
        <Divider />
        <CustomTabPanel value={value} index={0}>
          <EnvironmentDetailsCard
            envObj={envData[0]}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SiteSetupCard env={status} slug={slug} siteId={pantheonSiteId} />
        </CustomTabPanel>
      </Card>
    );
  }

  if (status === "test") {
    return (
      <Card className={classes.container}>
        <Tabs value={value} onChange={(_, value) => setValue(value)}>
          <Tab label="Development" {...envTabProps(0)} />
          <Tab label="Test" {...envTabProps(1)} />
          <Tab label="Production" {...envTabProps(2)} />
        </Tabs>
        <Divider />
        <CustomTabPanel value={value} index={0}>
          <EnvironmentDetailsCard
            envObj={envData[0]}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EnvironmentDetailsCard
            envObj={envData[1]}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <EnvironmentCreateCard
            envName="Production"
            onCreateEnvironment={() => onCreateEnvironment("Production")}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
      </Card>
    );
  }

  if (status === "pending-production") {
    return (
      <Card className={classes.container}>
        <Tabs value={value} onChange={(_, value) => setValue(value)}>
          <Tab label="Development" {...envTabProps(0)} />
          <Tab label="Test" {...envTabProps(1)} />
          <Tab label="Production" {...envTabProps(2)} />
        </Tabs>
        <Divider />
        <CustomTabPanel value={value} index={0}>
          <EnvironmentDetailsCard
            envObj={envData[0]}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EnvironmentDetailsCard
            envObj={envData[1]}
            setProgressAction={setProgressAction}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SiteSetupCard env={status} slug={slug} siteId={pantheonSiteId} />
        </CustomTabPanel>
      </Card>
    );
  }

  if (status === "production") {
    return (
      <Card className={classes.container}>
        <Tabs value={value} onChange={(_, value) => setValue(value)}>
          <Tab label="Development" {...envTabProps(0)} />
          <Tab label="Test" {...envTabProps(1)} />
          <Tab label="Production" {...envTabProps(2)} />
        </Tabs>
        <Divider />
        {envData.map((env, index) => {
          return (
            <CustomTabPanel value={value} index={index} key={env.name}>
              <EnvironmentDetailsCard
                envObj={env}
                handleManageEnvironment={handleManageEnvironment}
                setProgressAction={setProgressAction}
              />
            </CustomTabPanel>
          );
        })}
      </Card>
    );
  }
};
