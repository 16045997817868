import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  IconButton,
  Hidden,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { BreadcrumbContext } from "../../../components/Breadcrumb";

import {
  FirebaseAuth,
  Firestore,
} from "../../../components/FirebaseAuth/firebase";
import Loader from "../../../components/Loader";
import AccountCard from "../../../components/AccountCard";
import { isKWALLAdmin } from "../../../utils/index";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
} from "../../../utils/localStorage";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";

const Home = () => {
  const title = "My Collections";
  const history = useNavigate();

  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const userData = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const getAccounts = useCallback(async () => {
    setLoading(true);
    let records = [];
    // Reference to "organizations" collection with the query
    const organizationRef = query(
      collection(Firestore, "organizations"),
      where("access", "array-contains", FirebaseAuth.currentUser.uid)
    );
  
    // Reference to "accounts" collection with the query
    const accountRef = query(
      collection(Firestore, "accounts"),
      where("accountActivation", "==", true),
      where("access", "array-contains", FirebaseAuth.currentUser.uid)
    );
  
    // Fetch the data for both organization and account references
    const organizationData = await getDocs(organizationRef);
    const accountData = await getDocs(accountRef);
  
    if (accountData.empty && organizationData.empty) {
      history("/new-organization");
      return;
    }

    if (accountData.empty) {
      history("/new-account");
      return;
    }

    // For Admin or orgAdmin
    if (currentOrganization?.admins?.includes(FirebaseAuth.currentUser.uid)) {
      for (let accountId of currentOrganization.accounts) {
        if (accountId) {
          const accountDocRef = doc(Firestore, "accounts", accountId);
          const accountDataSnap = await getDoc(accountDocRef);
          if (accountDataSnap.exists()) {
            const accountData = accountDataSnap.data();
            records.push({
              id: accountId,
              name: accountData.name,
              accountType: accountData.accountType,
              subscriptionStatus: accountData.subscriptionStatus,
            });
          }
        }
      }
    } else {
      accountData.forEach((account) => {
        if (
          (!currentOrganization ||
            currentOrganization?.accounts?.includes(account.id)) &&
          (!account.data().status || account.data().status !== "deleted")
        ) {
          records.push({
            id: account.id,
            name: account.data().name,
            accountType: account.data().accountType,
            subscriptionStatus: account.data().subscriptionStatus,
          });
        }
      });
    }
    setAccounts(records);
    setLoading(false);
  }, [currentOrganization, history]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    getAccounts();
    // eslint-disable-next-line
  }, [setBreadcrumb]);

  return (
    <>
      {loading ? (
        <Loader text="loading collections..."></Loader>
      ) : (
        <>
          {(isKWALLAdmin(userData?.role) ||
            currentOrganization?.admins?.includes(
              FirebaseAuth.currentUser.uid
            )) && (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Hidden smDown>
                <Grid item>
                  <Button
                    fullWidth
                    onClick={() => history("/new-account")}
                    color="primary"
                    variant="contained"
                  >
                    <i className="fa fa-plus" style={{ marginRight: 7 }}></i>{" "}
                    CREATE NEW COLLECTION
                  </Button>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item style={{ marginBottom: 10 }}>
                  <Tooltip title="Delete">
                    <IconButton>
                      <Button
                        size="small"
                        fullWidth
                        onClick={() => history("/new-account")}
                        color="primary"
                        variant="contained"
                      >
                        CREATE NEW COLLECTION
                      </Button>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Hidden>
            </Grid>
          )}
          <Grid container spacing={3} style={{ marginTop: 4 }}>
            {!!accounts.length ? (
              accounts.map((account, i) => (
                <AccountCard i={i} account={account} key={account?.id} />
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "10rem",
                }}
              >
                <Typography variant="h6">
                  There's no collections created yet. Create one to setup your
                  websites.
                </Typography>
              </Box>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Home;
