import React, { useState, useEffect, useContext, useRef } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { FirebaseAuth, Firestore } from "../../../../components/FirebaseAuth/firebase";
import Loader from '../../../../components/Loader';
import UserPageLayout from '../../../../components/user/UserPageLayout';
import DataTable from "../../../../components/DataTable";
import { Alert } from "@mui/material";
import { doc, getDoc, collection, getDocs, orderBy, limit, startAfter, endBefore, query } from 'firebase/firestore';

const ViewLogs = () => {
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [qs, setQs] = useState(null);
    const mountedRef = useRef(true);
    const [error, setError] = useState(null);

      const getLogs = async (pz, direction, docRef) => {
        setLoading(true);
        let records = [];
        const userUid = FirebaseAuth.currentUser.uid; // Get the current user UID
        const userDocRef = await doc(Firestore, 'users', userUid);
        try {
            // Fetch total activity count
            const userDoc = await getDoc(userDocRef);
            const activityCount = userDoc.exists() ? userDoc.data().activityCount : 0;
            setTotal(activityCount);
    
            const activitiesRef = collection(userDocRef, 'activities');
            let fetchQuery = await query(activitiesRef, orderBy('time', 'desc'));
            
            // Adjust the query based on the pagination direction
            if (direction === 'next' && userDocRef) {
              fetchQuery = query(fetchQuery, startAfter(userDocRef));
            } else if (direction === 'previous' && userDocRef) {
              fetchQuery = query(fetchQuery, endBefore(userDocRef));
            }
            // Limit the number of results
            fetchQuery = await query(fetchQuery, limit(pz));
            const documentSnapshots = await getDocs(fetchQuery);
            documentSnapshots.forEach(doc => {
                records.push({
                    timestamp: doc.id,
                    time: doc.data().time.toDate().toLocaleString(),
                    action: doc.data().action
                });
            });
    
            if (records.length > 0) {
                setRows(records);
                setQs(documentSnapshots);
                setLoading(false);
            }
        } catch (e) {
            setError(e.message);
            setLoading(false);
        } finally {
            if (mountedRef.current) {
                setLoading(false);
            }
        }
    };
    const title = 'View Activity Logs'
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/user/profile",
                text: "User",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    },[setBreadcrumb]);

    useEffect(() => {
        return () => { 
            mountedRef.current = false
        }
    },[]);

    useEffect(() => {
        getLogs(pageSize);
    },[pageSize]);

    return (
        <UserPageLayout>
            {loading?(
                <Loader text="Loading logs..."></Loader>
            ):(
                <>
                {error?(
                    <Alert severity="error">{error}</Alert>
                ):(
                    <>
                        {total > 0 ? (
                            <DataTable columns={[
                                {name: "Activity", field: "action", style: {width: '50%'}},
                                {name: "Time", field: "time", style: {width: '50%'}}
                            ]}
                            rows={rows}
                            totalRows={total}
                            pageSize={pageSize}
                            page={page}
                            handlePageChane={(e, p) => {
                                if(p>page){
                                    getLogs(pageSize, 'next', qs.docs[qs.docs.length-1]);
                                }
                                if(p<page){
                                    getLogs(pageSize, 'previous', qs.docs[0]);
                                }
                                setPage(p);
                            }}
                            handlePageSizeChange={(e) => {
                                setPageSize(e.target.value);
                                setPage(0);
                            }}
                            ></DataTable>
                        ):(
                            <div>No activity is found</div>
                        )}
                    </>
                )}
                </>
            )}
        </UserPageLayout>)
}

export default ViewLogs;