import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
// import { useParams } from "react-router-dom/cjs/react-router-dom";
import Loader from "../../../../components/Loader";
import { useDownMedium } from "../../../../utils/useScreenSize";
import { isKWALLAdmin } from "../../../../utils";
import {
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { doc, getDoc, collection, getDocs, query, where } from "firebase/firestore"; 

export const OrganizationSiteList = () => {
  const history = useNavigate();
  const params = useParams();

  const isMobileView = useDownMedium();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationName, setOrganizationName] = useState("");
  const [accounts, setAccounts] = useState([]);

  const handleManage = useCallback(
    (accountId) => {
      history(`/account/${accountId}/sites`);
    },
    [history]
  );

  // const fetchOrganizationData = useCallback(async () => {
  //   const organizationRef = await Firestore.collection("organizations")
  //     .doc(params.organizationId)
  //     .get();

  //   const organizationData = organizationRef.data();
  //   setOrganizationName(organizationData.name);
  //   if (organizationData.accounts.length > 0) {
  //     const accountList = [];
  //     await Promise.all(
  //       organizationData.accounts.map(async (accountId) => {
  //         const accountSnapshot = await Firestore.collection("accounts")
  //           .doc(accountId)
  //           .get();
  //         const sitesSnapshot = await Firestore.collection(
  //           `/accounts/${accountId}/sites`
  //         )
  //           .where("isMyTemplate", "==", false)
  //           .get();
  //         const sitesArr = [];
  //         if (!!sitesSnapshot) {
  //           sitesSnapshot.forEach((doc) => {
  //             if (!doc.data().status || doc.data().status !== "deleted") {
  //               sitesArr.push({ ...doc.data(), id: doc.id });
  //             }
  //           });
  //         }
  //         accountList.push({
  //           ...accountSnapshot.data(),
  //           id: accountId,
  //           sites: sitesArr,
  //         });
  //       })
  //     );
  //     setIsLoading(false);
  //     setAccounts(accountList);
  //   }
  // }, [params]);
  const fetchOrganizationData = useCallback(async () => {
    try {
      // Fetch organization data
      const organizationRef = doc(Firestore, "organizations", params.organizationId);
      const organizationSnapshot = await getDoc(organizationRef);
  
      if (organizationSnapshot.exists()) {
        const organizationData = organizationSnapshot.data();
        setOrganizationName(organizationData.name);
  
        if (organizationData.accounts.length > 0) {
          const accountList = [];
          
          // Iterate through each account and fetch its details
          await Promise.all(
            organizationData.accounts.map(async (accountId) => {
              const accountRef = doc(Firestore, "accounts", accountId);
              const accountSnapshot = await getDoc(accountRef);
              
              // Fetch sites for each account
              const sitesRef = collection(Firestore, `/accounts/${accountId}/sites`);
              const sitesQuery = query(sitesRef, where("isMyTemplate", "==", false));
              const sitesSnapshot = await getDocs(sitesQuery);
              
              const sitesArr = [];
              sitesSnapshot.forEach((siteDoc) => {
                if (!siteDoc.data().status || siteDoc.data().status !== "deleted") {
                  sitesArr.push({ ...siteDoc.data(), id: siteDoc.id });
                }
              });
  
              accountList.push({
                ...accountSnapshot.data(),
                id: accountId,
                sites: sitesArr,
              });
            })
          );
          setIsLoading(false);
          setAccounts(accountList);
        }
      }
    } catch (error) {
      console.error("Error fetching organization data: ", error);
      setIsLoading(false);
    }
  }, [params]);  

  useEffect(() => {
    fetchOrganizationData();
  }, [fetchOrganizationData]);

  useEffect(() => {
    const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
    const path = isKWALLAdmin(currentUser?.role)
      ? "/organization_list"
      : "/organization/switch-organization";
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: path,
        text: "Organizations",
        active: false,
      },
      {
        to: null,
        text: `${organizationName} Accounts`,
        active: true,
      },
    ]);
  }, [setBreadcrumb, history, organizationName]);

  return (
    <Stack spacing={3}>
      {isLoading ? (
        <Paper>
          <Box p={2}>
            <Loader text="Loading..." />
          </Box>
        </Paper>
      ) : (
        <Box>
          {accounts.length > 0 ? (
            accounts.map((account) => (
              <Paper
                key={account.id}
                sx={{ padding: "12px 24px", marginTop: "12px" }}
              >
                <Box
                  sx={
                    isMobileView
                      ? {
                          margin: "12px 0",
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }
                      : {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "12px 0",
                          display: "flex",
                          gap: "12px",
                        }
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Typography variant="h6">{`Account Name: ${account.name}`}</Typography>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ height: "40px" }}
                    onClick={() => handleManage(account.id)}
                    size={isMobileView ? "small" : "medium"}
                  >
                    Manage Sites created by this Account
                  </Button>
                </Box>
                {account.sites.length > 0 ? (
                  account.sites.map((site) => (
                    <Box key={site.id}>
                      <Typography variant="subtitle2">{`• ${site.siteName}: ${site.siteType}`}</Typography>
                    </Box>
                  ))
                ) : (
                  <Typography variant="subtitle2">
                    No site created by this account yet
                  </Typography>
                )}
              </Paper>
            ))
          ) : (
            <Paper>
              <Typography style={{ textAlign: "center" }}>
                No Data Found
              </Typography>
            </Paper>
          )}
        </Box>
      )}
    </Stack>
  );
};
