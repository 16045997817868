import React, { useEffect, useContext, useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { collection, query, orderBy, onSnapshot, doc, deleteDoc, setDoc } from "firebase/firestore"; // Import necessary functions

import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";

import Card from "../../../../components/SiteCard";
import Loader from "../../../../components/Loader";
import PlansDialog from "../../../../components/PlansDialog";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const Sites = () => {
  const title = "View Sites List";
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);

  const history = useNavigate();
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [sites, setSites] = useState([]);
  const [siteWithPlan, setSiteWithPlan] = useState({});

  // function fetchSitesList() {
  //   const sitesCollectionsRef = Firestore.collection("sites").orderBy(
  //     "createdAt",
  //     "desc"
  //   );

  //   sitesCollectionsRef.onSnapshot((querySnapshot) => {
  //     const sitesArr = [];
  //     querySnapshot.forEach((doc) => {
  //       sitesArr.push({ id: doc.id, ...doc.data() });
  //     });
  //     setSites(sitesArr);
  //   });
  // }
  function fetchSitesList() {
    const sitesCollectionsRef = query(
      collection(Firestore, "sites"),
      orderBy("createdAt", "desc")
    );

    onSnapshot(sitesCollectionsRef, (querySnapshot) => {
      const sitesArr = [];
      querySnapshot.forEach((doc) => {
        sitesArr.push({ id: doc.id, ...doc.data() });
      });
      setSites(sitesArr);
    });
  }

  useEffect(() => {
    fetchSitesList();
    setLoader(false);
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + currentAccount.id,
        text: currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title, currentAccount.id, currentAccount.name]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlanId("");
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => handleDeleteSite(id),
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  // const handleDeleteSite = async (id) => {
  //   try {
  //     await Firestore.collection("sites").doc(id).delete();
  //     toast.error("Deleted Successfully");
  //   } catch (ex) {
  //     toast.error("Something went wrong");
  //   }
  // };

  const handleDeleteSite = async (id) => {
    try {
      const siteRef = doc(Firestore, "sites", id);
      await deleteDoc(siteRef);
      toast.error("Deleted Successfully");
    } catch (ex) {
      toast.error("Something went wrong");
    }
  };

  const handleSelectEdit = (id) => {
    history(`/account/${currentAccount.id}/sites/add-new/${id}`);
  };

  const handleSelectPlan = (selectedSite) => {
    setSiteWithPlan(selectedSite);
    setSelectedPlanId(selectedSite.planId);
    handleClickOpen();
  };

  // const handleSavePan = async () => {
  //   try {
  //     if (!!Object.keys(siteWithPlan).length) {
  //       const { id: siteId } = siteWithPlan;
  //       delete siteWithPlan["id"];
  //       const finalObj = { ...siteWithPlan, planId: selectedPlanId };

  //       const updateRef = Firestore.collection("sites").doc(siteId);
  //       await updateRef.set({ ...finalObj });
  //       setOpen(false);
  //     }
  //   } catch (ex) {}
  // };
  const handleSavePan = async () => {
    try {
      if (!!Object.keys(siteWithPlan).length) {
        const { id: siteId } = siteWithPlan;
        delete siteWithPlan["id"];
        const finalObj = { ...siteWithPlan, planId: selectedPlanId };

        const siteRef = doc(Firestore, "sites", siteId);
        await setDoc(siteRef, { ...finalObj });
        setOpen(false);
      }
    } catch (ex) {}
  };

  if (loader) {
    return <Loader text="Loading sites..."></Loader>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <PlansDialog
        open={open}
        selectedPlanId={selectedPlanId}
        setSelectedPlanId={setSelectedPlanId}
        onSaved={handleSavePan}
        onClose={handleClose}
      />
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                style={{ marginLeft: 35 }}
              >
                Sites List
              </Typography>
            </Grid>
            <Grid item style={{ marginRight: 60 }}>
              <Button
                variant="contained"
                size="large"
                onClick={() =>
                  history(`/account/${currentAccount.id}/sites/add-new`)
                }
              >
                Add Site
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {sites.map((site) => (
          <Grid item xs={4} key={site}>
            <Card
              key={site.id}
              site={site}
              onSelectPlans={handleSelectPlan}
              onSelectEdit={handleSelectEdit}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sites;
