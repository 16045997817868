import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField, } from '@mui/material';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { httpsCallable } from 'firebase/functions';
import { Firestore, CloudFunctions } from '../../components/FirebaseAuth/firebase';

const DomainCardMobile = ({ data, index, onDisplayAckMessage, onUpdateDomain }) => {
    const params = useParams();
    const [siteData, setSiteData] = useState({ data: [], isLoading: true });
    const { id } = data;

    async function fetchSiteData() {
        if (!!params.siteId) {
            const siteRef = await Firestore
                .collection(`/accounts/${params.accountId}/sites`)
                .doc(params.siteId)
                .get();
            const siteData = siteRef.data();

            setSiteData({
                data: siteData,
                isLoading: false
            });
        }
    }

    useEffect(() => {
        fetchSiteData();
        //eslint-disable-next-line
    }, []);

    const handleDelete = (currentIndex) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Are you sure you want to delete this? This cannot be undone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            console.log('___ SITE DATA', siteData.data, params.siteId);

                            const { siteId } = params;

                            const deleteDomain = httpsCallable(CloudFunctions,'kwallCloud-deleteDomain');
                            deleteDomain({
                                site_name_slug: siteData.data.slug,
                                currentIndex: currentIndex,
                                siteId: siteId
                            }).then(response => {

                                console.log('____ DELETE RESPONSE', response);
                            }).catch(ex => {
                                console.log('___ Ex', ex)
                            });

                            onDisplayAckMessage("Deleted Successfully ! It may take time to clean up from server", 'delete');
                        } catch (ex) {

                        }

                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    return (
        <>
            <Box sx={{
                flexGrow: 1,
                padding: '18px 16px 18px 16px',
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box style={{ fontWeight: 400, fontSize: 22, fontStyle: 'Roboto', color: '#1E1E1E' }}>
                                    Development
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                <TextField
                                    id="outlined-basic"
                                    name="id"
                                    label=""
                                    value={id}
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: 20 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={() => onUpdateDomain(data, index)}>
                                    <Box style={{ fontSize: 9 }}>
                                        Update Domain
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid xs={3} style={{ marginTop: 20, marginLeft: 5 }}>
                                <Button
                                    variant='contained'
                                    color='error'
                                    onClick={() => handleDelete(index)}>
                                    <Box style={{ fontSize: 9 }}>
                                        Delete
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default DomainCardMobile;