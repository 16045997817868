import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Paper,
  TextField,
  FormControl,
  Checkbox,
  FormHelperText,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Grid,
  Box,
  Avatar,
  Stack,
  Input,
  IconButton,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router";
import { doc, getDoc, collection, getDocs, query, where, setDoc, deleteDoc } from "firebase/firestore";
import Loader from "../../../../components/Loader";
import ThemeCard from "../../../../components/ThemeCard";
import {
  FirebaseStorage,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { isAdmin, isKWALLAdmin } from "../../../../utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { makeStyles } from "@mui/styles";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 160,
    height: 160,
    marginBottom: 24,
  },
  uploadInput: {
    display: "none",
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
    margin: 12,
  },
  textField: {
    margin: "12px 0",
  },
}));

const SiteEdit = () => {
  const initialSiteState = {
    siteName: "",
    slug: "",
    description: "",
    templateId: null,
    image: "",
    url: "",
  };
  const initialUserState = {
    isLoading: true,
    data: null,
  };

  const history = useNavigate();
  const { siteId } = useParams();
  const location = useLocation();
  const classes = useStyles();
  const { authUser, setIsMyTemplate: setIsMyTemplateGlobal } =
    useContext(AuthContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);

  const [nameError, setNameError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [siteData, setSiteData] = useState(initialSiteState);
  const [isMyTemplate, setIsMyTemplate] = useState(false);
  const [userData, setUserData] = useState(initialUserState);
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accounts, setAccounts] = useState();
  const [targetAccount, setTargetAccount] = useState(currentAccount);

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  // const handleUpload = useCallback(async (image) => {
  //   try {
  //     const downloadUrl = await FirebaseStorage.ref(`sites/${image.name}`).put(
  //       image
  //     );
  //     return downloadUrl.ref.getDownloadURL();
  //   } catch (ex) {
  //     console.log("ex", ex);
  //   }
  // }, []);

    const handleUpload = useCallback(
      async (image) => {
        try {
          const storageRef = ref(getStorage(), `sites/${image.name}`);
          await uploadBytes(storageRef, image);
          const downloadUrl = await getDownloadURL(storageRef);
          return downloadUrl;
        } catch (ex) {
          console.log("ex", ex);
        }
      },
      []
    );

  const handleChangeImage = useCallback(
    async ({ target: { files } }) => {
      if (!!files[0]) {
        const uploadUrl = await handleUpload(files[0]);
        setSiteData((prevService) => ({ ...prevService, image: uploadUrl }));
      }
    },
    [handleUpload]
  );

  const fetchTemplateSite = useCallback(async (templateId) => {
    try {
      if (!!templateId.length) {
        // const templateRef = await Firestore.collection("templates")
        //   .doc(templateId)
        //   .get();
        // const data = templateRef.data();
        const templateRef = doc(Firestore, "templates", templateId);
        const templateDoc = await getDoc(templateRef);
        const data = templateDoc.data();
        setTemplate(data);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }, []);

  const fetchSingleSite = useCallback(async () => {
    // const siteRef = await Firestore.collection(
    //   `/accounts/${currentAccount.id}/sites`
    // )
    //   .doc(siteId)
    //   .get();
    // const prevSiteData = siteRef.data();
    const siteRef = doc(Firestore, `/accounts/${currentAccount.id}/sites`, siteId);
    const siteDoc = await getDoc(siteRef);
    const prevSiteData = siteDoc.data();
    setIsMyTemplate(prevSiteData.isMyTemplate);
    setIsMyTemplateGlobal({
      isMenuOpen: true,
      status: prevSiteData.isMyTemplate,
    });

    if (prevSiteData.siteType === "Manual") {
      setSiteData((prev) => ({ ...prev, ...prevSiteData }));
      setIsLoading(false);
    } else {
      const planRef = await getDoc(prevSiteData.plan); 
      // await prevSiteData.plan.get();
      const planData = planRef.data();
      setSiteData((prev) => ({ ...prev, ...prevSiteData, ...planData }));
      await fetchTemplateSite(prevSiteData.templateId);
    }
  }, [currentAccount.id, siteId, setIsMyTemplateGlobal, fetchTemplateSite]);

  const getUserData = useCallback(async () => {
    // const userRef = await Firestore.collection("users")
    //   .doc(authUser.user.uid)
    //   .get();
    // const userInfo = userRef.data();

    const userRef = doc(Firestore, "users", authUser.user.uid);
    const userDoc = await getDoc(userRef);
    const userInfo = userDoc.data();
    setUserData({
      data: userInfo,
      isLoading: false,
    });
    if (
      userInfo.role.includes("kwallAdmin") ||
      userInfo.role.includes("orgAdmin")
    ) {
      const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
      let accountsArr = [];
      // const accountsSnapshots = await Firestore.collection("accounts").get();
      // accountsSnapshots.forEach((doc) => {
      //   if (currentOrganization.accounts?.includes(doc.id)) {
      //     accountsArr.push({ ...doc.data(), id: doc.id });
      //   }
      // });
      const accountsSnapshots = await getDocs(collection(Firestore, "accounts"));
      accountsSnapshots.forEach((doc) => {
        if (currentOrganization.accounts?.includes(doc.id)) {
          accountsArr.push({ ...doc.data(), id: doc.id });
        }
      });
      setAccounts(accountsArr);
    }
  }, [authUser]);

  // const handleEditSite = useCallback(async () => {
  //   if (nameError || descriptionError) return;
  //   if (siteData.siteType === "Manual" && urlError) return;
  //   setIsSubmitting(true);
  //   try {
  //     const {
  //       user: { email: authUserEmail },
  //     } = authUser;

  //     let finalObj = {
  //       ...siteData,
  //       isMyTemplate,
  //       createdAt: Date.now(),
  //       adminAccountEmail: authUserEmail,
  //     };

  //     const originRef = Firestore.collection(
  //       `/accounts/${currentAccount.id}/sites`
  //     ).doc(siteId);

  //     const newRef = Firestore.collection(
  //       `/accounts/${targetAccount.id}/sites`
  //     ).doc(siteId);

  //     if (
  //       !isMyTemplate &&
  //       siteData.name === "Template" &&
  //       siteData.siteType !== "Manual"
  //     ) {
  //       const querySnapshot = await Firestore.collection("plans")
  //         .where("name", "==", "Free")
  //         .get();
  //       querySnapshot.forEach((doc) => {
  //         const ref = Firestore.collection("plans").doc(doc.id);
  //         finalObj = {
  //           ...finalObj,
  //           plan: ref,
  //           name: doc.data().name,
  //           order: doc.data().order,
  //           position: doc.data().position,
  //         };
  //       });
  //     }
  //     if (currentAccount.id !== targetAccount.id) {
  //       const deploySiteSnapshot = await Firestore.collection("deploySites")
  //         .where("siteId", "==", siteId)
  //         .get();
  //       deploySiteSnapshot.forEach((doc) => {
  //         const ref = Firestore.collection("deploySites").doc(doc.id);
  //         ref.set({ accountId: targetAccount.id }, { merge: true });
  //       });
  //       await originRef.delete();
  //     }
  //     await newRef.set({ ...finalObj });
  //     setIsSubmitting(false);
  //     history(`/account/${currentAccount.id}`);
  //   } catch (ex) {
  //     console.log("___ex", ex);
  //   }
  // }, [
  //   nameError,
  //   descriptionError,
  //   siteData,
  //   urlError,
  //   authUser,
  //   isMyTemplate,
  //   currentAccount.id,
  //   siteId,
  //   targetAccount.id,
  //   history,
  // ]);
  const handleEditSite = useCallback(async () => {
    if (nameError || descriptionError) return;
    if (siteData.siteType === "Manual" && urlError) return;
    setIsSubmitting(true);
    try {
      const { user: { email: authUserEmail } } = authUser;
  
      let finalObj = {
        ...siteData,
        isMyTemplate,
        createdAt: Date.now(),
        adminAccountEmail: authUserEmail,
      };
  
      const originRef = doc(Firestore, `/accounts/${currentAccount.id}/sites`, siteId);
      const newRef = doc(Firestore, `/accounts/${targetAccount.id}/sites`, siteId);
  
      if (!isMyTemplate && siteData.name === "Template" && siteData.siteType !== "Manual") {
        const querySnapshot = await getDocs(query(collection(Firestore, "plans"), where("name", "==", "Free")));
        querySnapshot.forEach((doc) => {
          const ref = doc(Firestore, "plans", doc.id);
          finalObj = {
            ...finalObj,
            plan: ref,
            name: doc.data().name,
            order: doc.data().order,
            position: doc.data().position,
          };
        });
      }
  
      if (currentAccount.id !== targetAccount.id) {
        const deploySiteSnapshot = await getDocs(query(collection(Firestore, "deploySites"), where("siteId", "==", siteId)));
        deploySiteSnapshot.forEach((doc) => {
          const ref = doc(Firestore, "deploySites", doc.id);
          setDoc(ref, { accountId: targetAccount.id }, { merge: true });
        });
        await deleteDoc(originRef);
      }
      await setDoc(newRef, { ...finalObj });
      setIsSubmitting(false);
      history(`/account/${currentAccount.id}`);
    } catch (ex) {
      console.log("___ex", ex);
    }
  }, [
    nameError,
    descriptionError,
    siteData,
    urlError,
    authUser,
    isMyTemplate,
    currentAccount.id,
    siteId,
    targetAccount.id,
    history,
  ]);  

  const handleChange = useCallback(({ target: { name, value } }) => {
    setSiteData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSelectAccount = useCallback(
    (accountId) => {
      const selectedAccount = accounts.find((item) => item.id === accountId);
      setTargetAccount(selectedAccount);
    },
    [accounts]
  );

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: "Sites",
        active: false,
      },
      {
        to:
          location.state && location.state.isSiteWithTemplate
            ? `/account/${currentAccount.id}/also-templates`
            : `/account/${currentAccount.id}/site-details/${siteId}`,
        text:
          location.state && location.state.isSiteWithTemplate
            ? "Sites with Templates"
            : "Site Details",
        active: false,
      },
      {
        to: null,
        text: "Edit Site",
        active: true,
      },
    ]);
  }, [
    setBreadcrumb,
    currentAccount.id,
    currentAccount.name,
    siteId,
    location.state,
  ]);

  useEffect(() => {
    fetchSingleSite();
    getUserData();
  }, [getUserData, fetchSingleSite]);

  if (userData.isLoading) return null;

  return (
    <Paper>
      {isLoading ? (
        <Box p={2}>
          <Loader text="Loading..." />
        </Box>
      ) : (
        <Box p={2}>
          <Box className={classes.title}>Site Details</Box>
          <Grid container>
            <Grid item xs={12} md={3} className={classes.imageContainer}>
              <Avatar
                alt={siteData.image}
                src={siteData.image}
                className={classes.image}
              />
              <Stack>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    name="image"
                    type="file"
                    onChange={handleChangeImage}
                    className={classes.uploadInput}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudUploadIcon />
                    <Box>
                      <Typography ml={1}>Update Image</Typography>
                    </Box>
                  </IconButton>
                </label>
              </Stack>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                label="Site Name*"
                name="siteName"
                value={siteData.siteName}
                fullWidth
                onChange={handleChange}
                onBlur={(e) => {
                  if (
                    e.target.value.trim().length < 1 ||
                    e.target.value.trim().length > 100
                  ) {
                    setNameError(
                      "Site Name must be between 1 to 100 characters."
                    );
                  } else {
                    setNameError(null);
                  }
                }}
                error={nameError ? true : false}
                helperText={nameError}
                className={classes.textField}
              />
              {siteData.siteType === "Manual" ? (
                <TextField
                  label="Site URL*"
                  name="url"
                  value={siteData.url}
                  fullWidth
                  onChange={handleChange}
                  onBlur={(e) => {
                    if (e.target.value.trim().length < 1) {
                      setUrlError("Site URL is required.");
                    } else {
                      setUrlError(null);
                    }
                  }}
                  error={urlError ? true : false}
                  helperText={urlError}
                  className={classes.textField}
                />
              ) : (
                <TextField
                  label="Site Slug*"
                  name="slug"
                  disabled
                  value={siteData.slug}
                  className={classes.textField}
                />
              )}
              <TextField
                label="Site Description"
                name="description"
                multiline
                minRows={3}
                defaultValue={siteData.description}
                fullWidth
                onBlur={(e) => {
                  if (
                    e.target.value.trim().length < 1 ||
                    e.target.value.trim().length > 100
                  ) {
                    setDescriptionError(
                      "Site Description must be between 1 to 100 characters."
                    );
                  } else {
                    setDescriptionError(null);
                  }
                }}
                error={descriptionError ? true : false}
                helperText={descriptionError}
                className={classes.textField}
              />
              {accounts?.length > 0 && (
                <Box mt={3}>
                  <FormControl fullWidth>
                    <InputLabel id="account-select-label">
                      Move site to another account
                    </InputLabel>
                    <Select
                      labelId="account-select-label"
                      id="account-select"
                      value={targetAccount.id}
                      label="Move site to another account"
                      onChange={(event) =>
                        handleSelectAccount(event.target.value)
                      }
                    >
                      {accounts.map((account) => (
                        <MenuItem key={account.id} value={account.id}>
                          {account.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {(isKWALLAdmin(userData.data.role) ||
                isAdmin(userData.data.role)) &&
                template &&
                siteData.siteType !== "Manual" && (
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      <Box style={{ color: "#000000" }}>Site Template</Box>
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isMyTemplate}
                            onChange={() => setIsMyTemplate(!isMyTemplate)}
                            name="templateId"
                          />
                        }
                        label="This site is a template"
                      />
                    </FormGroup>
                    <FormHelperText>
                      <Box
                        style={{
                          color: "#6A6A6A",
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Warning:{" "}
                        <span
                          style={{
                            color: "#6A6A6A",
                            fontStyle: "Roboto",
                            fontWeight: 400,
                            fontSize: "10px",
                          }}
                        >
                          templates sites cannot go live, use them as starting
                          point for your projects
                        </span>
                      </Box>
                    </FormHelperText>
                  </FormControl>
                )}
              {siteData.siteType !== "Manual" && (
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <ThemeCard isEdit theme={template} />
                  </Grid>
                </Grid>
              )}
              <Box mt={3}>
                <Button
                  variant="contained"
                  onClick={handleEditSite}
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Loader />}
                  Save
                </Button>
                <Button
                  type="cancel"
                  variant="contained"
                  onClick={() => history(-1)}
                  style={{
                    backgroundColor: "#F3F6F8",
                    color: "#BD1B00",
                    marginLeft: 12,
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default SiteEdit;
