// import React, { useContext } from "react";
// import { Route,Routes, Navigate, useNavigate } from "react-router-dom";
// // import { Navigate } from 'react-router-dom';
// import { AuthContext } from "../../FirebaseAuth";
// import PublicTemplate from "../../templates/PublicTemplate";
// import Loader from "../../Loader";

// const AuthRouter = ({
//   component: Component,
//   template: Template,
//   title: Title,
//   ...rest
// }) => {
//   document.title = Title;

//   const { authUser } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const handleRedirect = (path) => {
//     navigate(path);
//   };

//   return (
//     // <Routes>
//     <Route
//       {...rest}
//       render={(matchProps) =>
//         authUser.checked ? (
//           !!authUser.user ? (
//             <Template {...rest}>
//               <Component {...matchProps} />
//             </Template>
//           ) : (
//             <Navigate to={"/welcome"} />
//           )
//         ) : (
//           <PublicTemplate>
//             <Loader text="Loading, please wait..." />
//           </PublicTemplate>
//         )
//       }
//     />
//     // </Routes>
//   );
// };
// export default AuthRouter;

import React, { useContext } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../FirebaseAuth";
import PublicTemplate from "../../templates/PublicTemplate";
import Loader from "../../Loader";

const AuthRouter = ({
  component: Component,
  template: Template,
  title: Title,
  children,
  ...rest
}) => {
  document.title = Title;

  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    navigate(path);
  };

  return (
    <>
    { authUser.checked ? (
            !!authUser.user ? (
              <Template {...rest}>
                <Component/>
              </Template>
            ) : (
              <>
              <Navigate to={"/welcome"} />
              </>
            )
          ) : (
            <>
            <PublicTemplate>
              <Loader text="Loading, please wait..." />
            </PublicTemplate>
            </>
          )}
    </>
  );
};

export default AuthRouter;
