import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import ApplyForm from "../../../../components/Plans/ApplyForm";
import FeatureForm from "../../../../components/Plans/FeatureForm";
import MainForm from "../../../../components/Plans/MainForm";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";

const EditPlan = () => {
  const params = useParams();
  const history = useNavigate();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [id, setId] = useState();
  const [plan, setPlan] = useState();
  const [errors, setErrors] = useState();

  const handleUpdateFeatures = useCallback(
    (features) => {
      setPlan({ ...plan, features: features });
    },
    [plan]
  );

  // const getPlanDetail = useCallback(async () => {
  //   const planSnapShot = await FirebaseAuth.firestore()
  //     .collection("plans")
  //     .doc(id)
  //     .get();
  //   const planData = planSnapShot.data();
  //   setPlan(planData);
  // }, [id]);


const getPlanDetail = useCallback(async () => {
  try {
    const planRef = doc(Firestore, "plans", id); // Reference the specific document by ID
    const planSnapShot = await getDoc(planRef); // Fetch the document
    if (planSnapShot.exists()) {
      const planData = planSnapShot.data();
      setPlan(planData);
    } else {
      console.error("Plan not found");
    }
  } catch (error) {
    console.error("Error fetching plan details:", error);
  }
}, [id]);

  // const updatePlan = useCallback(async () => {
  //   if (errors && Object.values(errors).indexOf((error) => error.isError) > -1)
  //     return;
  //   try {
  //     const planRef = FirebaseAuth.firestore().collection("plans").doc(id);
  //     await planRef.set(plan);
  //     toast.success("Updated Successfully");
  //     history(-1);
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // }, [errors, id, plan, history]);


const updatePlan = useCallback(async () => {
  if (errors && Object.values(errors).some((error) => error.isError)) {
    return;
  }

  try {
    const firestore = getFirestore();
    const planRef = doc(firestore, "plans", id); // Reference the specific document by ID
    await setDoc(planRef, plan); // Update the plan document
    toast.success("Updated Successfully");
    history(-1); // Navigate back
  } catch (error) {
    console.error("Error updating plan:", error);
    toast.error("Something went wrong");
  }
}, [errors, id, plan, history]);

  useEffect(() => {
    const { planId } = params;
    setId(planId);
    getPlanDetail();
  }, [params, getPlanDetail]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/planLists",
        text: "Plans",
        active: false,
      },
      {
        to: null,
        text: `Edit Plan`,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  return (
    <Box>
      {plan && (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <MainForm
              plan={plan}
              setPlan={setPlan}
              errors={errors}
              setErrors={setErrors}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ApplyForm plan={plan} setPlan={setPlan} />
            <FeatureForm
              features={plan.features}
              setFeatures={handleUpdateFeatures}
            />
          </Grid>
        </Grid>
      )}
      <Box width="60%" margin="auto" marginTop={6}>
        <Button variant="contained" fullWidth onClick={updatePlan}>
          Update Plan
        </Button>
      </Box>
    </Box>
  );
};

export default EditPlan;
