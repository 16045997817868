import {
  CloudFunctions,
  FirebaseAuth,
  Firestore,
} from "../../components/FirebaseAuth/firebase";
import { log, SIGN_IN, SIGN_OUT, UPDATE_USERNAME } from "../log";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

export const userSignIn = async (callback) => {
  const dt = new Date();
  const currentUser = FirebaseAuth.currentUser;

  // Reference to the "organizations" collection
  const organizationsRef = collection(Firestore, "organizations");
  const organizationsQuery = query(organizationsRef, orderBy("name", "asc"));
  const organizationSnapShots = await getDocs(organizationsQuery);

  let relatedOrganization;
  organizationSnapShots.forEach((doc) => {
    if (
      doc.data().primaryDomain &&
      currentUser.email.includes(doc.data().primaryDomain)
    ) {
      relatedOrganization = { ...doc.data(), id: doc.id };
    }
  });

  if (relatedOrganization) {
    const organizationRef = doc(
      Firestore,
      "organizations",
      relatedOrganization.id
    );

    if (relatedOrganization.autoEnroll) {
      await updateDoc(organizationRef, {
        access: [...relatedOrganization.access, currentUser.uid],
      });
    } else {
      const pendingArray = relatedOrganization.pending || [];
      await updateDoc(organizationRef, {
        pending: [...pendingArray, currentUser.uid],
      });

      const sendPendingUserAllowEmail = httpsCallable(
        CloudFunctions,
        "organizations-sendPendingUserAllowEmail"
      );
      await sendPendingUserAllowEmail({
        organizationId: relatedOrganization.id,
        username: currentUser.displayName,
        email: currentUser.email,
      });
    }
  }

  const userDocRef = doc(Firestore, "users", currentUser.uid);
  const userDocSnap = await getDoc(userDocRef);

  if (!userDocSnap.exists()) {
    const sendSlackNotification = httpsCallable(
      CloudFunctions,
      "kwallCloud-sendSlackEmailForNewRegister"
    );
    await sendSlackNotification({
      displayName: currentUser.displayName,
      photoURL: currentUser.photoURL,
      emailAddress: currentUser.email,
      creationTime: dt,
      lastLoginTime: dt,
    });
  }

  const userData = userDocSnap.data();

  await setDoc(
    userDocRef,
    {
      displayName: currentUser.displayName || "Accorbis User",
      photoURL: currentUser.photoURL,
      emailAddress: currentUser.email,
      creationTime: dt,
      lastLoginTime: dt,
    },
    { merge: !!userDocSnap.exists() }
  )
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
  log(SIGN_IN);
};

// export const userSignIn = async (callback) => {
//   var dt = new Date();

//   // const Firestore = Firestore();
//   const currentUser = FirebaseAuth.currentUser;
//   const organizationsRef = await collection(Firestore, "organizations"); // Reference to the "organizations" collection
//   const organizationsQuery = query(organizationsRef, orderBy("name", "asc")); // Create query
//   const organizationSnapShots = await getDocs(organizationsQuery); // Fetch documents

//   // const organizationSnapShots = await Firestore.collection("organizations")
//   //   .orderBy("name", "asc")
//   //   .get();
//   let relatedOrganization;
//   organizationSnapShots.forEach((doc) => {
//     if (
//       doc.data().primaryDomain &&
//       currentUser.email.includes(doc.data().primaryDomain)
//     ) {
//       relatedOrganization = { ...doc.data(), id: doc.id };
//     }
//   });

//   if (relatedOrganization) {
//     // const organizationRef = Firestore.collection("organizations").doc(
//     //   relatedOrganization.id
//     // );
//     const organizationRef = await doc(Firestore, "organizations", relatedOrganization.id);
//     if (relatedOrganization.autoEnroll) {
//       await organizationRef.update({
//         access: [...relatedOrganization.access, currentUser.uid],
//       });
//     } else {
//       if (relatedOrganization.pending) {
//         await organizationRef.update({
//           pending: [...relatedOrganization.pending, currentUser.uid],
//         });
//       } else {
//         await organizationRef.update({
//           pending: [currentUser.uid],
//         });
//       }
//       const sendPendingUserAllowEmail = httpsCallable(CloudFunctions,
//         "organizations-sendPendingUserAllowEmail"
//       );
//       await sendPendingUserAllowEmail({
//         organizationId: relatedOrganization.id,
//         username: currentUser.displayName,
//         email: currentUser.email,
//       });
//     }
//   }
//   // const userDocRef = collection(Firestore, "users").doc(currentUser.uid);
//   // Use the `doc` function separately on the Firestore instance
//   const userDocRef = doc(collection(Firestore, "users"), currentUser.uid);
//   const userDocSnap = await getDoc(userDocRef);

//   // if (userDocSnap.exists()) {
//   //     const userData = userDocSnap.data();
//   //     console.log("User Data:", userData);
//   // } else {
//   //     console.log("No such document!");
//   // }
//   if (!userDocSnap.exists()) {
//     const sendSlackNotification = httpsCallable(CloudFunctions,
//       "kwallCloud-sendSlackEmailForNewRegister"
//     );
//     await sendSlackNotification({
//       displayName: currentUser.displayName,
//       photoURL: currentUser.photoURL,
//       emailAddress: currentUser.email,
//       creationTime: dt,
//       lastLoginTime: dt,
//     });
//   }
//   const userData = userDocSnap.data();
//   userDocRef
//     .set(
//       {
//         displayName: currentUser.displayName || "Accorbis User",
//         photoURL: currentUser.photoURL,
//         emailAddress: currentUser.email,
//         creationTime: dt,
//         lastLoginTime: dt,
//       },
//       { merge: !!userData.exists }
//     )
//     .then(() => {
//       callback(true);
//     })
//     .catch((err) => {
//       console.log(err);
//       callback(false);
//     });
//   log(SIGN_IN);
// };

export const userSignOut = () => {
  log(SIGN_OUT, (result) => {
    // wait for log is successfully written before signing out
    if (result) {
      FirebaseAuth.signOut();
    }
  });
};

export const userUpdateName = async () => {
  const currentUser = FirebaseAuth.currentUser;
  const userDocRef = await doc(Firestore, "users", currentUser.uid);
  await setDoc(
    userDocRef,
    {
      displayName: currentUser.displayName || "Accorbis User",
    },
    { merge: true }
  );
  log(UPDATE_USERNAME);
};
