import { FirebaseAuth,Firestore } from "../../components/FirebaseAuth/firebase";
import { collection, doc, setDoc } from "firebase/firestore";

// export const log = (action, callback) => {
//     // const Firestore = FirebaseAuth.firestore();
//     const currentUser = FirebaseAuth.currentUser;

//     const dt = new Date();
//     const data ={
//         'action': action,
//         'time': dt
//     }
//     const userDocRef = Firestore.collection('users').doc(currentUser.uid);
//     userDocRef.collection('activities').doc(''+dt.getTime()).set(data).then(() => {
//         if(callback){
//             callback(true);
//         }
//     }).catch(err => {
//         if(callback){
//             callback(false);
//         }
//     });
// }

export const log =  async (action, callback) => {
    const currentUser = FirebaseAuth.currentUser; // Get the current user
    const dt = new Date();
    const data = {
        action: action,
        time: dt,
    };

    // Reference to the user's document in the "users" collection
    const userDocRef = doc(Firestore, "users", currentUser.uid);
    
    // Create a reference to the activities sub-collection
    const activitiesRef = collection(userDocRef, "activities");
    
    // Create a new document in the activities sub-collection using the current timestamp
    const activityDocRef = doc(activitiesRef, `${dt.getTime()}`);

    try {
        await setDoc(activityDocRef, data); // Set the document with data
        if (callback) {
            callback(true); // Call the callback with true on success
        }
    } catch (err) {
        console.error(err); // Log the error for debugging
        if (callback) {
            callback(false); // Call the callback with false on error
        }
    }
};
export const SIGN_IN = 'signed in';
export const UPDATE_PASSWORD = 'changed password';
export const UPDATE_USERNAME = 'changed user name';
export const UPDATE_PHONE = 'changed phone number';
export const UPDATE_EMAIL = 'changed email address';
export const SIGN_OUT = 'signed out';