import React, { useState, useEffect, useContext, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import {
  Firestore,
  CloudFunctions,
} from "../../../../components/FirebaseAuth/firebase";
import { isKWALLAdmin } from "../../../../utils/index";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import DomainsTable from "../../../../components/DomainsTable";
import SnackBar from "../../../../components/SnackBar";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { httpsCallable } from "firebase/functions";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 40,
  textAlign: "center",
  fontWeight: 700,
  fontSize: 22,
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  urlsStyle: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#6A6A6A",
    marginTop: 13,
  },
  hrefStyle: {
    color: "#6A6A6A",
    textDecoration: "underline",
    marginLeft: 5,
  },
  heading: {
    fontFamily: "Roboto",
    color: "#1E1E1E",
    fontSize: 22,
    fontWeight: 400,
  },
}));

const ManageEnvironment = () => {
  const initialUserState = {
    isLoading: true,
    data: null,
  };
  const location = useLocation();
  const classes = useStyles();
  const {
    state: {
      envData: { name, description, domainUrl, developerUrl },
    },
  } = location;
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { authUser } = useContext(AuthContext);
  const [openSnakBar, setOpenSnackBar] = React.useState(false);
  const [siteData, setSiteData] = useState({ data: [], isLoading: true });
  const [domainList, setDomainList] = useState({ data: [], isLoading: true });
  const [userData, setUserData] = useState(initialUserState);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const title = "Site Environment";
  const params = useParams();
  const history = useNavigate();

  const handleSnackBarOpen = () => {
    setOpenSnackBar(true);
  };

  const handleSnakBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/site-details/${params.siteId}`,
        text: "Site Details",
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, currentAccount.id, currentAccount.name, params]);

  const handleDomainEdit = () => {
    const { accountId, siteId } = params;
    history({
      pathname: `/account/${accountId}/site/${siteId}/edit-domain`,
      state: { siteData: JSON.stringify(siteData.data) },
    });
  };

  // async function getUserData() {
  //   try {
  //     const refUser = await Firestore.collection("users")
  //       .doc(authUser.user.uid)
  //       .get();
  //     const userData = await refUser.data();
  //     setUserData({
  //       data: userData,
  //       isLoading: false,
  //     });
  //   } catch (ex) {
  //     console.log("___ ex", ex);
  //   }
  // }
  async function getUserData() {
    try {
      const refUser = doc(Firestore, "users", authUser.user.uid);
      const userDataSnapshot = await getDoc(refUser);
      const userData = userDataSnapshot.data();

      setUserData({
        data: userData,
        isLoading: false,
      });
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }
  // async function fetchSiteData() {
  //   if (!!params.siteId) {
  //     const siteRef = await Firestore.collection(
  //       `/accounts/${params.accountId}/sites`
  //     )
  //       .doc(params.siteId)
  //       .get();
  //     const siteData = siteRef.data();

  //     setSiteData({
  //       data: siteData,
  //       isLoading: false,
  //     });
  //   }
  // }
  async function fetchSiteData() {
    if (!!params.siteId) {
      const siteRef = doc(
        Firestore,
        "accounts",
        params.accountId,
        "sites",
        params.siteId
      );
      const siteDataSnapshot = await getDoc(siteRef);
      const siteData = siteDataSnapshot.data();

      setSiteData({
        data: siteData,
        isLoading: false,
      });
    }
  }

  // function fetchDomainList() {
  //   const domainCollectionsRef = Firestore.collection("domains").where(
  //     "siteId",
  //     "==",
  //     params.siteId
  //   );

  //   domainCollectionsRef.onSnapshot((querySnapshot) => {
  //     const domainArr = [];
  //     querySnapshot.forEach((doc) => {
  //       domainArr.push({ id: doc.id, ...doc.data() });
  //     });

  //     setDomainList({
  //       isLoadingDomain: false,
  //       data:
  //         domainArr[0] && domainArr[0].response ? domainArr[0].response : [],
  //     });
  //   });
  // }
  function fetchDomainList() {
    const domainQuery = query(
      collection(Firestore, "domains"),
      where("siteId", "==", params.siteId)
    );

    onSnapshot(domainQuery, (querySnapshot) => {
      const domainArr = [];
      querySnapshot.forEach((doc) => {
        domainArr.push({ id: doc.id, ...doc.data() });
      });

      setDomainList({
        isLoadingDomain: false,
        data: domainArr[0]?.response || [],
      });
    });
  }

  useEffect(() => {
    getUserData();
    fetchSiteData();
    fetchDomainList();
    //eslint-disable-next-line
  }, []);

  const handleUpdateDomain = () => {
    handleSnackBarOpen();
    // const updateDns = httpsCallable(CloudFunctions,"kwallCloud-updateDnsList");
    const updateDns = httpsCallable(CloudFunctions, "kwallCloud-updateDnsList");
    updateDns({
      siteId: params.siteId,
      site_name_slug: siteData.data.slug,
    })
      .then((response) => {
        console.log("___ response", response);
      })
      .catch((err) => {
        console.log("___ err", err);
      });
  };

  const handleUpdatePrimaryDomain = useCallback(() => {
    window.open(
      `https://dashboard.pantheon.io/sites/${siteData.data.pantheonSiteId}#live/DomainsHTTPS/list`,
      "_blank"
    );
  }, [siteData]);

  return (
    <Box sx={{ overflowY: "scroll" }}>
      <SnackBar open={openSnakBar} onClose={handleSnakBarClose} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "70vh" }}
      >
        <Grid item>
          <Item>
            {`Environment ${name}`}
            <Box mt={3}>
              <Typography align="center">{description}</Typography>
            </Box>
            <Box className={classes.urlsStyle}>
              <>
                {" "}
                Access Site:
                <a
                  className={classes.hrefStyle}
                  href={domainUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {domainUrl}
                </a>
                <br />
              </>
            </Box>
            {!!developerUrl && (
              <Box className={classes.urlsStyle}>
                <>
                  Developer access:
                  <a
                    className={classes.hrefStyle}
                    href={developerUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {developerUrl}
                  </a>
                  <br />
                </>
              </Box>
            )}
          </Item>
        </Grid>
        <Grid item xs={12}>
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Box className={classes.heading} mt={3} mb={3}>
                  Domain
                </Box>
              </Grid>
              {!userData.isLoading && isKWALLAdmin(userData.data.role) && (
                <Grid item>
                  <Grid container>
                    <Grid item style={{ marginRight: 20 }} mb={2}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleUpdateDomain}
                      >
                        Update DNS
                      </Button>
                    </Grid>
                    <Grid item style={{ marginRight: 20 }} mb={2}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleDomainEdit}
                      >
                        Manage Domains
                      </Button>
                    </Grid>
                    <Grid item mb={2}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleUpdatePrimaryDomain}
                      >
                        Primary Domain
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <DomainsTable domainList={domainList.data} />
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageEnvironment;
