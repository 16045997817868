import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Button, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import {
  FirebaseAuth,
  Firestore,
} from "../../../components/FirebaseAuth/firebase";
import { collection, getDocs, doc, updateDoc, query, orderBy, where } from "firebase/firestore";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { OrganizationItem } from "../../../components/Organizations/OrganizationItem";
import {
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
} from "../../../utils/localStorage";
import { isKWALLAdmin } from "../../../utils";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    padding: 12,
  },
}));

const OrganizationList = () => {
  const history = useNavigate();
  const classes = useStyles();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);

  const handleAdd = useCallback(() => {
    history("/organization_list/create");
  }, [history]);

  // const getOrganizationList = useCallback(async () => {
  //   setIsLoading(true);
  //   let temp = [];
  //   const organizationSnapShots = await FirebaseAuth.firestore()
  //     .collection("organizations")
  //     .orderBy("name", "asc")
  //     .get();
  //   organizationSnapShots.forEach((doc) => {
  //     if (!doc.data().status || doc.data().status !== "deleted") {
  //       temp.push({ ...doc.data(), id: doc.id });
  //     }
  //   });
  //   setOrganizations(temp);
  //   setIsLoading(false);
  // }, []);

  // const handleDeleteOrganization = useCallback(
  //   async (id) => {
  //     try {
  //       await Firestore.collection("organizations")
  //         .doc(id)
  //         .set({ status: "deleted" }, { merge: true });
  //       getOrganizationList();
  //       toast.success("Deleted Successfully");
  //     } catch (ex) {
  //       toast.error("Something went wrong");
  //     }
  //   },
  //   [getOrganizationList]
  // );

  const getOrganizationList = useCallback(async () => {
    setIsLoading(true);
    let temp = [];
    
    // Create a query to get organizations ordered by name
    const organizationsRef = collection(Firestore, "organizations");
    const q = query(organizationsRef, orderBy("name", "asc"));
    const organizationSnapShots = await getDocs(q);
  
    organizationSnapShots.forEach((doc) => {
      if (!doc.data().status || doc.data().status !== "deleted") {
        temp.push({ ...doc.data(), id: doc.id });
      }
    });
    setOrganizations(temp);
    setIsLoading(false);
  }, []);

  const handleDeleteOrganization = useCallback(
    async (id) => {
      try {
        // Update status to "deleted"
        const organizationRef = doc(Firestore, "organizations", id);
        await updateDoc(organizationRef, { status: "deleted" });
        getOrganizationList();
        toast.success("Deleted Successfully");
      } catch (ex) {
        toast.error("Something went wrong");
      }
    },
    [getOrganizationList]
  );

  const handleDelete = useCallback(
    (id) => {
      confirmAlert({
        title: "Are you sure?",
        message: "Are you sure you want to delete this? This cannot be undone.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => handleDeleteOrganization(id),
          },
          {
            label: "No",
            onClick: () => alert("Click No"),
          },
        ],
      });
    },
    [handleDeleteOrganization]
  );

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: "Organizations",
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    getOrganizationList();
  }, [getOrganizationList]);

  if (!isKWALLAdmin(currentUser?.role)) {
    return <Navigate to="/organization/switch-organization" />;
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.btnContainer}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          <Box>Create New Organization</Box>
        </Button>
      </Box>
      {!isLoading &&
        organizations.length > 0 &&
        organizations.map((organization) => {
          return (
            <OrganizationItem
              organization={organization}
              key={organization.id}
              handleDelete={() => handleDelete(organization.id)}
            />
          );
        })}
    </Container>
  );
};

export default OrganizationList;
