import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import DataTable from "../../../../components/DataTable";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Button, Paper, Box, Alert, Avatar } from "@mui/material";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../../utils/localStorage";
import AddIcon from "@mui/icons-material/Add";
import { useCallback } from "react";

const OrganizationUserList = () => {
  const title = "Users";
  const history = useNavigate();
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [users, setUsers] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getUsers = useCallback(async () => {
    setError(null);
    const getOrganizationUsers = httpsCallable(
      CloudFunctions,
      "organizations-getOrganizationUsers"
    );
    try {
      const organizationUsers = await getOrganizationUsers({
        organizationId: currentOrganization?.id,
      });
      let totalCounter = 0;
      organizationUsers.data.forEach((record) => {
        record.nameCol = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Avatar alt={record.displayName} src={record.photoURL} />
            <strong style={{ marginLeft: "15px" }}>{record.displayName}</strong>
          </div>
        );
        record.roleCol =
          record.role.charAt(0).toUpperCase() + record.role.slice(1);
        record.lastLoginTimeCol = new Date(
          record.lastLoginTime
        ).toLocaleString();
        if (record.roleCol !== "Admin") {
          record.actionCol = (
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                history(
                  "/organization/" +
                    currentOrganization.id +
                    "/users/change/" +
                    record.id
                )
              }
            >
              Manage User
            </Button>
          );
        }
        totalCounter++;
      });
      setTotal(totalCounter);
      setData(organizationUsers.data);
    } catch (err) {
      setError(err.message);
    }
  }, [currentOrganization.id, history]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/organization_list",
        text: "Organizations",
        active: false,
      },
      {
        to: "/user/organization",
        text: currentOrganization.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [currentOrganization.name, setBreadcrumb]);

  useEffect(() => {
    const startIndex = page * pageSize;
    let records = [];
    for (let i = startIndex; i < data.length; i++) {
      if (i >= startIndex + pageSize) {
        break;
      }
      records.push(data[i]);
    }
    if (records.length > 0) {
      setUsers(records);
    }
    window.scrollTo(0, 0);
  }, [page, pageSize, data]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Box textAlign={"right"} paddingY={"20px"}>
        <Button
          onClick={() =>
            history("/organization/" + currentOrganization.id + "/users/add")
          }
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add User
        </Button>
      </Box>
      <Paper width={1}>
        <Box p={2}>
          {error !== null && <Alert severity="error">{error}</Alert>}
          {users === null ? (
            <Loader text="Loading users" />
          ) : (
            <DataTable
              columns={[
                { name: "Name", field: "nameCol", style: { width: "40%" } },
                { name: "Role", field: "roleCol", style: { width: "20%" } },
                {
                  name: "Last Login",
                  field: "lastLoginTimeCol",
                  style: { width: "30%" },
                },
                { name: "Action", field: "actionCol", style: { width: "10%" } },
              ]}
              rows={users}
              totalRows={total}
              pageSize={pageSize}
              page={page}
              handlePageChane={(e, p) => {
                setPage(p);
              }}
              handlePageSizeChange={(e) => {
                setPage(0);
                setPageSize(e.target.value);
              }}
            ></DataTable>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default OrganizationUserList;
