import React, { useState, useEffect, useRef } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import { Paper, Box, Stack, Alert, Typography, Button } from "@mui/material";
import {
  LOCAL_STORAGE_INVITE_CODE,
  setLocalStorage,
} from "../../../../utils/localStorage";
import { httpsCallable } from "firebase/functions";

const Invite = () => {
  const { code } = useParams();

  const title = "View Invite";
  const history = useNavigate();
  const mountedRef = useRef(true);

  const [invite, setInvite] = useState(null);
  const [error, setError] = useState(null);
  const [inSubmit, setInSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (code) {
      let isSubscribed = true;
      setError(null);
      const getInvite = httpsCallable(CloudFunctions, "getInvite");
      getInvite({
        inviteId: code,
      })
        .then((res) => {
          if (!mountedRef.current) return null;
          if (isSubscribed) {
            setInvite(res.data);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          if (isSubscribed) {
            setError(err.message);
          }
        });
      return () => (isSubscribed = false);
    }
  }, [code, title]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      {success ? (
        <Navigate to={"/account/" + invite.accountId + "/"}></Navigate>
      ) : (
        <Paper>
          <Box p={2}>
            <Stack spacing={3}>
              {error !== null && <Alert severity="error">{error}</Alert>}
              {invite === null ? (
                <Loader text="Loading the invite..."></Loader>
              ) : (
                <>
                  <Typography>
                    This invite will grant you access to{" "}
                    <strong>{invite.accountName}</strong>. Do you want to accept
                    it?
                  </Typography>
                  <Stack direction="row" spacing={1} mt={2}>
                    <Button
                      disabled={inSubmit}
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        setInSubmit(true);
                        const acceptInvite = httpsCallable(
                          CloudFunctions,
                          "acceptInvite"
                        );
                        acceptInvite({
                          inviteId: code,
                        })
                          .then((res) => {
                            setLocalStorage(LOCAL_STORAGE_INVITE_CODE, "");
                            if (!mountedRef.current) return null;
                            setSuccess(true);
                          })
                          .catch((err) => {
                            setLocalStorage(LOCAL_STORAGE_INVITE_CODE, "");
                            if (!mountedRef.current) return null;
                            setError(err.message);
                          });
                      }}
                    >
                      {inSubmit && <Loader />}
                      Yes, accept the invite
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={inSubmit}
                      onClick={() => {
                        setLocalStorage(LOCAL_STORAGE_INVITE_CODE, "");
                        history("/");
                      }}
                    >
                      Ignore
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default Invite;
