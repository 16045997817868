import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Stack, Grid, Box } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import AccountDetailsTable from "../../../../components/AccountDetailsTable";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";

const AccountOverView = () => {
  const title = "Account Overview";
  const params = useParams();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [accountData, setAccountData] = useState({
    data: null,
    isLoaded: false,
  });

  async function fetchSitesList() {
    if (!!params.accountId) {
      // const accountRef = await Firestore.collection("accounts")
      //   .doc(params.accountId)
      //   .get();
      const accountRef = doc(Firestore, "accounts", params.accountId);
      const accountSnap = await getDoc(accountRef);
      
      const accountData = accountSnap.data();
      setAccountData({
        data: { ...accountData, id: accountRef.id },
        isLoaded: true,
      });
    }
  }

  useEffect(() => {
    fetchSitesList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${accountData.data && accountData.data.id}`,
        text: accountData.data && accountData.data.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, accountData.data]);

  if (!accountData.isLoaded) return null;

  return (
    <Stack spacing={3}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ height: "67vh" }}
      >
        <Grid item></Grid>
        <Grid item>
          <Box alignContent="center" alignItems="center" mt={3}>
            {accountData.isLoaded && (
              <AccountDetailsTable accountData={accountData.data} />
            )}
          </Box>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Stack>
  );
};

export default AccountOverView;
