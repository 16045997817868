import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SplitButton from "../SplitButton/SplitButton";
import { useParams } from "react-router-dom";
import { Firestore, CloudFunctions } from "../FirebaseAuth/firebase";
import { useCallback } from "react";
import { webCheckUrl } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { confirmAlert } from "react-confirm-alert";
import { SitePerformanceChart } from "../SiteDetailsCard/SitePerformanceChart";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const useStyles = makeStyles((theme) => ({
  descriptionSpan: {
    fontSize: 16,
    fontWeight: 400,
    color: "#6A6A6A",
    marginBottom: "1rem",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "3rem",
    padding: "1rem",
    overflow: "visible",
    minHeight: "320px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    position: "relative",
    gap: "2rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "1rem",
    },
  },
  webCheckContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxWidth: "800px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  deepScanContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "40%",
    minWidth: "300px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  button: {
    backgroundColor: "#F3F6F8",
    padding: "8px 12px",
    flex: 1,
  },
  composerContainer: {
    display: "flex",
    marginTop: "12px",
    width: "100%",
    gap: "16px",
    alignItems: "baseline",
  },
  composerInput: {
    height: "40px",
    flex: 1,
  },
  imageButton: {
    display: "flex",
    alignItems: "center",
    color: "#FFF",
    cursor: "pointer",
    gap: "2px",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "12px",
  },
  statusLabel: {
    fontSize: "12px",
    color: "#6A6A6A",
  },
  treatContainer: {
    display: "flex",
    gap: "16px",
    marginTop: 4,
  },
  treatLabel: {
    fontSize: "14px",
    color: "#6A6A6A",
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  resultLabel: {
    fontSize: "14px",
    color: "#6A6A6A",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
}));

const EnvironmentDetailsCard = ({
  envObj,
  handleManageEnvironment,
  setProgressAction,
}) => {
  const classes = useStyles();
  const { name, description, domainUrl, developerUrl, buttonText, env } =
    envObj;
  const [loading, setLoading] = useState(false);
  const [siteData, setSiteData] = useState({ data: {}, isLoading: true });
  const [composerModule, setComposerModule] = useState();
  const [checkResult, setCheckResult] = useState({});
  const params = useParams();

  const optionsArray = [
    { name: "Actions", command: "" },
    { name: "Unfreeze", command: "env:wake" },
    { name: "Flush Cache", command: "env:clear-cache" },
    { name: "Backup Site", command: "backup:create" },
    { name: "Web Check", command: webCheckUrl },
  ];

  const handleRecheck = useCallback(async () => {
    setLoading(true);
    const updateWebCheckResult = httpsCallable(
      CloudFunctions,
      "kwallCloud-updateWebCheckResult"
    );
    const newResult = await updateWebCheckResult({
      accountId: params.accountId,
      siteId: params.siteId,
      env,
    });
    if (newResult?.data) {
      setCheckResult(newResult?.data);
    }
    setLoading(false);
  }, [env, params.accountId, params.siteId]);

  const fetchSiteData = useCallback(async () => {
    if (!!params.siteId) {
      //   const siteRef = await Firestore.collection(
      //     `/accounts/${params.accountId}/sites`
      //   )
      //     .doc(params.siteId)
      //     .get();
      //   const siteData = siteRef.data();
      const siteRef = doc(
        Firestore,
        `accounts/${params.accountId}/sites`,
        params.siteId
      );

      // Fetch the document data
      const siteDocSnap = await getDoc(siteRef);
      const siteData = siteDocSnap.data();

      setSiteData({
        data: siteData,
        isLoading: false,
      });
      setCheckResult(siteData[`web_check_result_${env}`]);
    }
  }, [env, params.accountId, params.siteId]);

  useEffect(() => {
    fetchSiteData();
  }, [fetchSiteData]);

  const disableScan = useMemo(() => {
    return (
      siteData.data?.[`deep_scan_time_${env}`] &&
      (siteData.data?.[`deep_scan_time_${env}`]?.seconds || 0 + 86400) * 1000 <
        Date.now()
    );
  }, [env, siteData.data]);

  const handleClick = useCallback(
    (option) => {
      if (option.name === "Web Check") {
        const url = domainUrl.split("://")?.[1];
        window.open(`${option.command}/${url}`, "_blank");
        return;
      }
      const siteActionTrigger = httpsCallable(
        CloudFunctions,
        "kwallCloud-triggerSiteAction"
      );
      setProgressAction({
        action: `${option.name} action is in progress.`,
        status: "info",
      });
      siteActionTrigger({
        site_name_slug: siteData.data.slug,
        command: option.command,
        env,
      })
        .then((response) => {
          setProgressAction({
            action: `${option.name} action was completed successfully.`,
            status: "success",
          });
        })
        .catch((err) => {
          console.log("___ err trigger action", err);
          setProgressAction({
            action: `${option.name} action was failed, please try again.`,
            status: "error",
          });
        });
    },
    [siteData.data.slug, env, domainUrl, setProgressAction]
  );

  const handleRequireComposer = useCallback(() => {
    if (!composerModule) return;
    setProgressAction({
      action: `Action is in progress.`,
      status: "info",
    });
    const composerRequire = httpsCallable(
      CloudFunctions,
      "kwallCloud-composerRequire"
    );
    composerRequire({
      site_name_slug: siteData.data.slug,
      module: composerModule,
    })
      .then((response) => {
        console.log("___ response trigger action", response);
        setProgressAction({
          action: `Composer was added successfully.`,
          status: "success",
        });
      })
      .catch((err) => {
        console.log("___ err trigger action", err);
        setProgressAction({
          action: `Composer install was failed, please try again.`,
          status: "error",
        });
      });
  }, [composerModule, setProgressAction, siteData.data.slug]);

  const handleRequestScan = useCallback(async () => {
    const requestDeepScan = httpsCallable(
      CloudFunctions,
      "kwallCloud-requestDeepScan"
    );

    await requestDeepScan({
      siteId: params.siteId,
      domain: domainUrl,
      env,
      site_name_slug: siteData.data.slug,
      accountId: params.accountId,
    });
  }, [domainUrl, env, params.accountId, params.siteId, siteData.data.slug]);

  const handleClickDeepScan = useCallback(async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "24px",
              maxWidth: "360px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={24} fontWeight={700}>
                Alert
              </Typography>
              <CancelIcon
                onClick={onClose}
                sx={{ cursor: "pointer", color: "#666", marginTop: "-12px" }}
              />
            </Box>
            <Typography fontSize={20} color="#666">
              This scan will take several hours to complete
            </Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Button
                onClick={() => {
                  handleRequestScan();
                  onClose();
                }}
                variant="contained"
              >
                Run Scan
              </Button>
              <Button onClick={onClose} variant="outlined" color="error">
                Cancel
              </Button>
            </Box>
          </Card>
        );
      },
    });
  }, [handleRequestScan]);

  const handleClickViewScan = useCallback(() => {
    const path = `https://reporting.accorbis.com${
      siteData.data?.[`deep_scan_result_${env}`]
    }/index.html`;
    window.open(path, "_blank");
  }, [env, siteData.data]);

  return (
    <Card className={classes.cardContainer}>
      <Box className={classes.contentContainer}>
        <Box className={classes.webCheckContainer}>
          <Typography className={classes.descriptionSpan}>
            {description}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={classes.resultLabel}>
              WEB CHECK RESULT
            </Typography>
            <Box className={classes.statusContainer}>
              {loading ? (
                <CircularProgress
                  variant="indeterminate"
                  sx={{ color: "#9F9F9F" }}
                  size={14}
                />
              ) : (
                checkResult?.lastUpdated && (
                  <Typography className={classes.statusLabel}>
                    {`Last checked at ${new Date(
                      checkResult?.lastUpdated
                    ).toLocaleString()}`}
                  </Typography>
                )
              )}
              <Button
                size="small"
                onClick={handleRecheck}
                disabled={loading}
                loading={loading}
              >
                {checkResult ? "RECHECK" : "WEB CHECK"}
              </Button>
            </Box>
          </Box>
          {checkResult?.threats && (
            <Box className={classes.treatContainer}>
              {checkResult.threats.phishTank?.url0?.in_database === "false" ? (
                <Typography className={classes.treatLabel}>
                  <CheckCircleIcon color="success" sx={{ fontSize: "16px" }} />{" "}
                  No Phishing Found
                </Typography>
              ) : (
                <Typography className={classes.treatLabel}>
                  <CancelIcon color="error" sx={{ fontSize: "16px" }} />{" "}
                  Phishing Identified
                </Typography>
              )}
              {checkResult.threats.urlHaus.query_status === "no_results" ? (
                <Typography className={classes.treatLabel}>
                  <CheckCircleIcon color="success" sx={{ fontSize: "16px" }} />{" "}
                  No Malware Found
                </Typography>
              ) : (
                <Typography className={classes.treatLabel}>
                  <CancelIcon color="error" sx={{ fontSize: "16px" }} /> Malware
                  Found
                </Typography>
              )}
            </Box>
          )}
          <SitePerformanceChart webCheckResult={checkResult} />
        </Box>
        <Box className={classes.deepScanContainer}>
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.imageButton}
              variant="contained"
              onClick={handleClickViewScan}
              disabled={!siteData.data?.[`deep_scan_result_${env}`]}
            >
              <Typography sx={{ fontSize: 14 }}>View Last Scan</Typography>
              <InsertDriveFileIcon fontSize="12px" />
            </Button>
            <Button
              className={classes.imageButton}
              onClick={handleClickDeepScan}
              variant="contained"
              disabled={disableScan}
            >
              <Typography sx={{ fontSize: 14 }}>Deep Scan</Typography>
              <AutorenewIcon fontSize="12px" />
            </Button>
            <Button
              size="small"
              className={classes.button}
              onClick={() => window.open(domainUrl, "_blank")}
            >
              Access Site
            </Button>{" "}
            {developerUrl && (
              <Button
                size="small"
                className={classes.button}
                onClick={() => window.open(developerUrl, "_blank")}
              >
                Developer Access
              </Button>
            )}
            <SplitButton optionsArray={optionsArray} onClick={handleClick} />
            {name === "Production" && (
              <Button
                size="small"
                className={classes.button}
                color="primary"
                onClick={() =>
                  handleManageEnvironment({
                    name,
                    description,
                    domainUrl,
                    developerUrl,
                  })
                }
              >
                {buttonText}
              </Button>
            )}
          </Box>
          {developerUrl && siteData?.data?.siteType === "Drupal" && (
            <Box className={classes.composerContainer}>
              <TextField
                className={classes.composerInput}
                variant="standard"
                sx={{ height: "40px" }}
                placeholder="Ex: drupal/smart_content:^3.1"
                onChange={(event) => setComposerModule(event.target.value)}
              />
              <Button
                variant="contained"
                size="small"
                onClick={handleRequireComposer}
              >
                Add Module
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default EnvironmentDetailsCard;
