import React, { useEffect, useCallback, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  CloudFunctions,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { makeStyles } from "@mui/styles";
import { Grid, Paper, Typography, Box, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, collection } from "firebase/firestore";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "80px",
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 24,
  },
  detailContent: {
    width: "100%",
  },
  subTitleContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #c4c4c4",
    padding: 16,
  },
  subTitleKey: {
    fontWeight: 600,
    width: 240,
  },
  subTitleValue: {
    color: "#6a6a6a",
    flex: 1,
  },
  descContainer: {
    padding: 16,
    margin: "16px 0",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 16,
  },
  feedbackContainer: {
    width: "100%",
    padding: 16,
  },
  btnBack: {
    marginTop: 24,
  },
}));

export const ServiceRequest = () => {
  const params = useParams();
  const history = useNavigate();
  const classes = useStyles();
  const { authUser } = useContext(AuthContext);
  const [accountName, setAccountName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [requestDesc, setRequestDesc] = useState("");
  const [siteData, setSiteData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const getData = useCallback(async () => {
  //   if (params.accountId) {
  //     const accountRef = await Firestore.doc(
  //       `accounts/${params.accountId}`
  //     ).get();
  //     setAccountName(accountRef.data().name);
  //     const siteRef = await Firestore.collection(
  //       `/accounts/${params.accountId}/sites`
  //     )
  //       .doc(history.location.state?.siteId)
  //       .get();
  //     setSiteData({ ...siteRef.data(), id: history.location.state?.siteId });
  //   }
  //   const serviceRef = await Firestore.collection("services")
  //     .doc(params.serviceId)
  //     .get();
  //   setServiceName(serviceRef.data().name);
  // }, [params, history]);
  const getData = useCallback(async () => {
    if (params.accountId) {
      try {
        const accountRef = doc(Firestore, `accounts/${params.accountId}`);
        const accountSnap = await getDoc(accountRef);
        setAccountName(accountSnap.data()?.name);
  
        const siteRef = doc(Firestore, `/accounts/${params.accountId}/sites`, history.location.state?.siteId);
        const siteSnap = await getDoc(siteRef);
        setSiteData({ ...siteSnap.data(), id: history.location.state?.siteId });
      } catch (error) {
        console.error("Error fetching account or site data:", error);
      }
    }
    try {
      const serviceRef = doc(Firestore, "services", params.serviceId);
      const serviceSnap = await getDoc(serviceRef);
      setServiceName(serviceSnap.data()?.name);
    } catch (error) {
      console.error("Error fetching service data:", error);
    }
  }, [params, history]);

  const handleRequest = useCallback(() => {
    setIsLoading(true);
    const requestData = {
      serviceId: params.serviceId,
      serviceName: serviceName,
      siteLink: siteData
        ? siteData.status === "production"
          ? `http://live-${siteData.slug}.pantheonsite.io`
          : `https://dashboard.pantheon.io/${siteData.pantheonSiteId}#dev/code`
        : "",
      userName: authUser.user.displayName,
      userEmail: authUser.user.email,
      siteId: siteData?.id || "",
      requestInfo: requestDesc,
    };
    const requestFunction = httpsCallable(CloudFunctions,
      "kwallCloud-requestService"
    );
    requestFunction(requestData)
      .then((resp) => {
        setIsSubmitted(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [params, requestDesc, serviceName, authUser, siteData]);

  const handleCancel = useCallback(() => {
    history(-1);
  }, [history]);

  useEffect(() => {
    getData();
  }, [params, getData]);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10} md={8} lg={6} className={classes.content}>
        <Typography className={classes.title}>Request Services</Typography>
        {isSubmitted ? (
          <Paper className={classes.feedbackContainer}>
            <Typography className={classes.title}>
              Thank you for the request!
            </Typography>
            <Typography className={classes.subTitleValue}>
              We will be in touch to start a quote on the email attached to this
              account.
            </Typography>
            <Button
              variant="contained"
              onClick={handleCancel}
              className={classes.btnBack}
            >
              Back to site
            </Button>
          </Paper>
        ) : (
          <Paper className={classes.detailContent}>
            {accountName && (
              <Box className={classes.subTitleContainer}>
                <Typography className={classes.subTitleKey}>
                  SERVICE FOR
                </Typography>
                <Typography className={classes.subTitleValue}>
                  {`${accountName} / ${siteData.siteName}`}
                </Typography>
              </Box>
            )}
            <Box className={classes.subTitleContainer}>
              <Typography className={classes.subTitleKey}>
                SERVICE REQUESTED
              </Typography>
              <Typography className={classes.subTitleValue}>
                {serviceName}
              </Typography>
            </Box>
            <Box className={classes.descContainer}>
              <TextField
                label="Describe Request"
                name="requestDesc"
                fullWidth
                multiline
                minRows={5}
                value={requestDesc}
                onChange={(e) => setRequestDesc(e.target.value)}
              />
            </Box>
            <Box className={classes.btnContainer}>
              <Button
                variant="contained"
                onClick={handleRequest}
                disabled={isLoading}
              >
                Request
              </Button>
              <Button
                variant="contained"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#F3F6F8",
                  color: "#BD1B00",
                  marginLeft: 16,
                }}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
