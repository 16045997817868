// import useMediaQuery from "@mui/material/useMediaQuery";

// export const useIsMobileView = () =>
//   useMediaQuery((theme) => theme?.breakpoints?.down("md"));
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useIsMobileView = () => {
  const theme = useTheme(); // Access the theme
  return useMediaQuery(theme.breakpoints.down("md")); // Pass the query directly using the theme
};