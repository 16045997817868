import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Stack, Grid, Box, Typography, Paper } from "@mui/material";
import { collection, onSnapshot } from "firebase/firestore";
import DetailsTable from "../../../../components/InvoiceDetailsTable";

import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const InvoiceDetails = () => {
  const title = "Invoice Details";
  const params = useParams();
  const { userData } = useContext(AuthContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [inVoiceData, setInvoiceData] = useState({
    data: null,
    isLoaded: false,
  });

  // async function fetchSitesList() {
  //   if (!!params.siteId) {
  //     const sitesCollectionsRef = Firestore.collection(
  //       `/accounts/${params.accountId}/sites/${params.siteId}/invoices`
  //     );
  //     sitesCollectionsRef.onSnapshot((querySnapshot) => {
  //       let invoiceData = {};
  //       if (!!querySnapshot) {
  //         querySnapshot.forEach((doc) => {
  //           invoiceData = { ...doc.data() };
  //         });

  //         setInvoiceData({
  //           data: invoiceData,
  //           isLoaded: true,
  //         });
  //       }
  //     });
  //   }
  // }
  async function fetchSitesList() {
    if (!!params.siteId) {
      const sitesCollectionsRef = collection(
        Firestore,
        "accounts",
        params.accountId,
        "sites",
        params.siteId,
        "invoices"
      );
      onSnapshot(sitesCollectionsRef, (querySnapshot) => {
        let invoiceData = {};
        if (querySnapshot) {
          querySnapshot.forEach((doc) => {
            invoiceData = { ...doc.data() };
          });

          setInvoiceData({
            data: invoiceData,
            isLoaded: true,
          });
        }
      });
    }
  }

  useEffect(() => {
    fetchSitesList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + currentAccount.id,
        text: currentAccount.name,
        active: false,
      },
      {
        to: "/account/" + currentAccount.id,
        text: "Sites",
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, userData, currentAccount.id, currentAccount.name]);

  if (!inVoiceData.isLoaded) return null;

  return (
    <Paper>
      <Stack spacing={3}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ height: "80vh" }}
        >
          <Grid item></Grid>
          <Grid item>
            <Box>
              <Typography variant="h4" align="center">
                Invoice Details
              </Typography>
            </Box>
            <Box alignContent="center" alignItems="center" mt={3}>
              <DetailsTable inVoiceData={inVoiceData.data} />
            </Box>
            <Box alignContent="center" alignItems="center" mt={4}>
              <a
                style={{ textDecorationColor: "#0A79A6", color: "#0A79A6" }}
                href={inVoiceData.data.hostedInvoiceUrl}
                target="_blank"
                rel="noreferrer"
              >
                Download Invoice
              </a>
            </Box>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default InvoiceDetails;
