export const listResponse = [
    {
        "name": "InvoiceID",
        "field": "id",
        "style": {
            "width": "20%"
        }
    },
    {
        "name": "Amount Due",
        "field": "amountDue",
        "style": {
            "width": "20%"
        }
    },
    {
        "name": "Amount Paid",
        "field": "amountPaid",
        "style": {
            "width": "20%"
        }
    },
    {
        "name": "Total",
        "field": "total",
        "style": {
            "width": "20%"
        }
    },
    {
        "name": "Actions",
        "field": "action",
        "style": {
            "width": "15%"
        }
    }
]