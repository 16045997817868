import { memo } from "react";
import {
  Card,
  Tooltip,
  CardHeader,
  Box,
  Divider,
  CardActions,
  Button,
  Link,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
// import SanitizedHTML from "react-sanitized-html";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: 460,
    width: 320,
    fontFamily: "Roboto",
    color: "#6A6A6A",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 400,
    color: "#1E1E1E",
    textAlign: "center",
    marginTop: 20,
  },
  cardSubTitle: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  divider: {
    marginLeft: 20,
    marginRight: 20,
  },
  cardContent: {
    margin: "20px 0",
  },
  checkIcon: {
    color: "#459C96",
    fontSize: 16,
    margin: "4px 12px 0 0",
  },
  feature: {
    display: "flex",
    flexDirection: "row",
    fontSize: 16,
    fontWeight: 400,
    marginTop: 8,
  },
  planNote: {
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
  },
  tooltip: {
    position: "absolute",
    right: 12,
    top: 12,
  },
  action: {
    position: "absolute",
    bottom: 16,
  },
}));

export const PlanCard = memo((props) => {
  const { plan, prevSelectedPlan, selectedPlan, setSelectedPlan, isExpired } =
    props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {plan.name !== "Enter Pricing Code" && (
        <Tooltip
          title={plan.description}
          placement="top"
          className={classes.tooltip}
        >
          <InfoIcon />
        </Tooltip>
      )}
      <CardHeader
        title={<Box className={classes.cardTitle}>{plan.name}</Box>}
        subheader={
          <Box className={classes.cardSubTitle}>
            {`$ ${plan.price}/${plan.paymentCycle === "mo" && "Month"}`}
          </Box>
        }
      />
      {plan.name !== "Template" && (
        <Divider variant="middle" flexItem className={classes.divider} />
      )}
      <Box className={classes.cardContent}>
        {plan.htmlDescription && (
          <Box className={classes.planNote}>
            {" "}
            {/* {plan.description} */}
            <div dangerouslySetInnerHTML={{ __html: plan.htmlDescription }} />
            {/* <SanitizedHTML html={plan.htmlDescription} /> */}
          </Box>
        )}
        {plan.features?.map((feature, i) => (
          <Box key={i} className={classes.feature}>
            <i className={`${classes.checkIcon} far fa-check-circle`} />
            <span>{feature}</span>
          </Box>
        ))}
        {plan.URL && (
          <Link
            href={plan.URL}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ paddingLeft: "28px " }}
          >
            learn more
          </Link>
        )}
      </Box>

      <CardActions className={classes.action}>
        {plan.name === prevSelectedPlan ? (
          <Button color="success" variant="contained" disabled={true}>
            {isExpired ? "Trial Expired" : "Current Plan"}
          </Button>
        ) : (
          <Button
            style={
              plan !== selectedPlan
                ? { color: "#459C96" }
                : {
                    backgroundColor: "#459C96",
                    color: "white",
                  }
            }
            variant={plan !== selectedPlan ? "outlined" : "contained"}
            onClick={() => {
              setSelectedPlan(plan);
            }}
          >
            {plan === selectedPlan && (
              <i className="fa fa-check" style={{ marginRight: 5 }} />
            )}
            {plan !== selectedPlan ? "Select" : "Selected"}
          </Button>
        )}
      </CardActions>
    </Card>
  );
});
